<!-- 充值酬宾 -->
<template>
  <ion-page>
    <!-- 顶部导航栏 -->
    <NavigationBar :title="activityName" />
    <ion-content>
      <!-- 顶部图片/活动时间 -->
      <div class="top-content w-full">
        <div class="date-content">
          <!-- 酬宾时间 -->
          <div class="reward-time mb-[1.4375rem] min-h-[4.5rem] flex items-center">{{ $t('viewsActivity.rechargePromotion09') }}</div>
          <!-- 倒计时 -->
          <div class="date-count-down flex mb-[0.625rem]">
            <div 
              class="count-down-item mr-[0.625rem] relative" 
              :class="{ 'over-count-down': index == 2  }"
              v-for="(item,index) in overCountDownList" 
              :key="index"
            >
              <div class="count-down-item-content w-full h-full py-[0.25rem] flex flex-col items-center justify-around">
                <div class="count-down-value">{{ item.value }}</div>
                <div class="count-down-name">{{ $t(`${item.name}`) }}</div>
              </div>
              <!-- 底部线条 -->
              <div class="count-down-footer-line absolute bottom-0 left-[50%]"></div>
            </div>
          </div>
          <!-- 活动倒计时提示 -->
          <div class="flex items-center count-down-tips mb-[1.5625rem]">
            <ion-icon class="text-[0.625rem] mr-[0.25rem]" src="/svg/activity/mineral/mineral_style1_dot.svg" />
            Hora de início da recompensa
          </div>
          <!-- 酬宾开始时间 -->
          <div class="date-week w-[11.3125rem] h-[4.5rem] text-center py-[0.75rem]">
            <div class="week-text">{{ currentWeekName }}</div>
            <div class="week-tips">{{ $t('viewsActivity.rechargePromotion09') }}</div>
          </div>
        </div>
      </div>
      <!-- 充值/活动规则 -->
      <div class="recharge-promotion-content">
        <div class="recharge-rule-content w-full">
          <!-- 充值列表 -->
          <div class="recharge-item" v-for="(item,index) in rechargeList" :key="index">
            <div class="recharge-item-top flex justify-between items-center p-[0.625rem]">
              <div class="recharge-info flex">
                <div class="recharge-info-left">
                  <div class="mb-[0.625rem]">{{ $t('viewsActivity.rechargePromotion12') }} {{ merchantCy }}{{ formatMoneyToShow(item.rechargeAmount) }}</div>
                  <div>{{ $t('viewsAssets.bonus') }} {{ merchantCy }}{{ formatMoneyToShow(item.rewardAmount) }}</div>
                </div>
                <div class="recharge-info-right ml-[1.5rem] text-center">
                  <div class="mb-[0.25rem] total-amount">{{ merchantCy }}{{ formatMoneyToShow(item.totalAmount) }}</div>
                  <div>{{ $t('viewsActivity.rechargePromotion13') }}</div>
                </div>
              </div>
              <div class="recharge-btn py-[0.5625rem] flex-center" @click="rechargeBtnClick(item)">{{ $t('viewsActivity.rechargePromotion12') }}</div>
            </div>
            <div class="recharge-item-footer py-[0.5rem] text-center">
              {{ $t('viewsActivity.rechargePromotion14') }}:{{ formatMoneyToShow(item.needBetAmount)}}
            </div>
          </div>
          <!-- 活动规则 -->
          <div class="mt-[1.25rem]">
            <!-- 规则标题 -->
            <div class="w-full flex-center mb-[0.9375rem]">
              <ion-icon class="mineral-rule-icon" src="/svg/activity/mineral/mineral_style1_rule_icon.svg" />
              <div class="mx-[0.375rem]">{{ $t('activity.appreciation13') }}</div>
              <ion-icon class="mineral-rule-icon" src="/svg/activity/mineral/mineral_style1_rule_icon_right.svg" />
            </div>
            <!-- 规则内容 -->
            <div class="recharge-promotion-rule w-full">
              <div class="recharge-promotion-rule-border w-full h-[0.0625rem] mb-[0.9375rem]"></div>
              <p class="text-[0.75rem] leading-[1.6875rem] recharge-promotion-rule-contents keep-space">
                {{ activityRule }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { formatMoneyToShow } from '@/utils/custom'
import { IonPage,IonContent, IonIcon } from '@ionic/vue';
import NavigationBar from '@/components/NavigationBar/index.vue'
import { useRechargeLogic } from '@/views/activity/rechargePromotion/hooks/useLogic'

const {
  activityName,
  activityRule,
  overCountDownList,
  rechargeList,
  merchantCy,
  currentWeekName,
  rechargeBtnClick
} = useRechargeLogic();

</script>

<style scoped lang="less">
ion-content {
  font-family: 'Prompt' !important;
}

.top-content {
  height: 23.75rem;
  background: url('/images/activity/rechargePromotion/topBg.png') no-repeat;
  background-size: 100% 100%;
  .date-content {
    padding: 1rem 0 0 1.0625rem;
    .reward-time {
      font-size: 2.25rem;
      line-height: 2.25rem;
      font-weight: 900;
      background: linear-gradient(90deg, #d49135 10%, #9E2326 22.35%, #1659E6 75%);
      -webkit-text-stroke-width: .1313rem;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-style: normal;
      -webkit-text-stroke-color: #F6D7AA;
    }
  }
}

.count-down-item {
  background: linear-gradient(180deg, #34ED7E 0%, #26507C 100%);
  width: 2.25rem;
  height: 2.75rem;
  border-radius: .3125rem;
  .count-down-item-content {
    color: #FFF;
    .count-down-value {
      font-size: .875rem;
      line-height: .875rem;   
      font-weight: 700;
    }
    .count-down-name {
      font-size: .5rem;
    }
  }

  .count-down-footer-line {
     width: .375rem;
    height: .125rem;
    background: #FAAC47;
    transform: translateX(-50%);
  }
}

.over-count-down {
  background: linear-gradient(180deg, #261616 0%, #1C2F43 100%);
  .count-down-item-content {
    color: rgba(255,255,255,0.5);
  }

  .count-down-footer-line {
    background: #4F4232;
  }
}

.count-down-tips {
  font-size: .75rem;
  line-height: .75rem;
  color: #23DB8C;
  font-weight: 400;
}

.date-week {
  border-radius: .875rem;
  border: .125rem solid #BBBDFF;
  background: linear-gradient(180deg, #5582C4 0%, #274B80 100%);
  box-shadow: 0px 0px 12.9px 0px #4A9FFF, 0px 5px 11.5px 0px rgba(255, 255, 255, 0.38) inset;
  .week-text {
    font-size: 1.25rem;
    font-weight: 600;
    color: #F9D045;
  }

  .week-tips {
    font-size: .75rem;
    color: #fff;
  }
}

.recharge-promotion-content {
  padding: 0 .9375rem 1.125rem;
  margin-top: -4.75rem;
  .recharge-rule-content {
    padding: .9375rem .75rem 1.25rem;
    border-radius: .875rem;
    background: linear-gradient(180.38deg, #202F51 0.33%, #111542 25.46%);
    .recharge-item {
      border-radius: .375rem;
      overflow: hidden;
      margin-bottom: .625rem;
      .recharge-item-top {
        background: #253154;
        .recharge-info {
          .recharge-info-left,
          .recharge-info-right {
            font-size: .75rem;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
            .total-amount {
              font-size: 1rem;
              color: #1ACD4C;
            }
          }
        }
        .recharge-btn {
          min-width: 6.5rem;
          border: 1px solid #2A337A;
          color: #1ACD4C;
          background: #14173C80;
          font-size: .75rem;
          font-weight: 400;
          border-radius: .375rem;
        }
      }

      .recharge-item-footer {
        font-size: .75rem;
        font-weight: 500;
        background: rgba(255, 255, 255, 0.1);
        color: rgba(166, 176, 214, 0.5);
      }
    }

    .mineral-rule-icon {
      width: .8547rem;
      height: .5516rem;
    }

    .recharge-promotion-rule {
      padding: .9375rem .6875rem 0 .9375rem;
      .recharge-promotion-rule-border {
        background: linear-gradient(270deg, #111542 0.65%, #3768B9 49.64%, #111542 100%);
      }
      .recharge-promotion-rule-contents {
        color: #95A9D3;
      }
    }

  }
}

</style>
