<template>

    <ion-content>
      <div class="ios-first">
        <div class="wrap">
          <div class="top-content">
            <!-- 顶部 -->
            <div class="header">
              <div class="content-top-wrap relative pb-[0.0625rem]">
                <div class="gradient-overlay"></div>
                <div class="flex items-center iconHeader">
                  <!-- 应用图标 -->
                  <ion-img class="w-[4.5rem] h-[4.5rem] mr-6 rounded-[20%] overflow-hidden ion-logo" slot="start"
                    :src="template?.appIconUrl" />
                  <div class="flex-1">
                    <p class="main-text text-2xl leading-none  ellipsis w-[15.375rem]">{{ template?.appName }}</p>
                    <p class="text-xl leading-none text-[#0070c9]"> {{ template?.appDomain }} </p>
                    <p class="text-[.75rem]  sub-text">{{ $t('viewsSystem.downloadIndex0') }}</p>
                  </div>
                </div>
                <div class="flex items-center text-sm my-6">
                  <div class="flex-1 flex flex-col items-center">
                    <p class="main-text font-semibold"> 4.9 ★★★★★</p>
                    <p class="text-xs sub-text">{{ $t('viewsSystem.downloadIndex1') }}</p>
                  </div>
                  <i class="h-6 w-[1px] line-bg" />
                  <div class="flex-1 flex flex-col items-center">
                    <p class="main-text font-semibold">500M +</p>
                    <p class="text-xs sub-text">{{ $t('viewsSystem.downloads') }}</p>
                  </div>
                  <i class="h-6 w-[1px] bg-[#E8EAED]" />
                  <div class="flex-1 flex flex-col items-center">
                    <p class="main-text font-semibold">18+</p>
                    <p class="text-xs sub-text">{{ $t('viewsSystem.downloadIndex2') }}</p>
                  </div>
                </div>
                <!-- 安装模块 -->
                <div class="btn  shiny flex flex-col items-center text-xs" @click="checkHandle">
                  <div class="flex items-center">
                    <span class="text-sm text-[#ffffff]">{{ $t('viewsSystem.downloadIndex01') }}</span>
                    <i class="download"></i>
                  </div>
                  <!-- {{ $t('viewsSystem.downloadIndex05') }} -->
                </div>
              </div>
            </div>
          </div>
          <!-- 宣传海报 -->
          <div class="flex overflow-x-auto">
            <div class='publicUrl' v-for="item of (template?.appPublicUrl?.filter((it: any) => it.includes('http')))"
              :key="item">
              <img :src="item">
            </div>
          </div>
          <!-- 关于游戏 -->
          <div class="mt-6">
            <div class="text-[#202124] text-lg font-bold flex justify-between mb-3">
              {{ $t('viewsSystem.downloadIndex5') }}
              <ion-img class="w-[.9375rem]"
                src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/icon-right-arrow.png" />
            </div>
            <p v-for="it in introductlist" :key="it">{{ it }}</p>
          </div>
          <!-- 更新时间 -->
          <div class="mt-6">
            <div class="text-[#202124] font-bold mb-2">{{ $t('viewsSystem.downloadIndex9') }}</div>
            <div class="description__content" id="update-time">{{ $t('viewsSystem.downloadIndex10') }}</div>

            <div class="flex flex-wrap mt-7">
              <div class="border border-[#dadce0] p-2 px-3 rounded-[.9375rem] mt-2.5 mr-4">{{
                $t('viewsSystem.downloadIndex11') }}
              </div>
              <div class="border border-[#dadce0] p-2 px-3 rounded-[.9375rem] mt-2.5 mr-4">{{
                $t('viewsSystem.downloadIndex12') }}
              </div>
              <div class="border border-[#dadce0] p-2 px-3 rounded-[.9375rem] mt-2.5 mr-4">{{
                $t('viewsSystem.downloadIndex13') }}
              </div>
              <div class="border border-[#dadce0] p-2 px-3 rounded-[.9375rem] mt-2.5 mr-4">{{
                $t('viewsSystem.downloadIndex14') }}
              </div>
              <div class="border border-[#dadce0] p-2 px-3 rounded-[.9375rem] mt-2.5 mr-4">{{
                $t('viewsSystem.downloadIndex15') }}
              </div>
              <div class="border border-[#dadce0] p-2 px-3 rounded-[.9375rem] mt-2.5 mr-4">{{
                $t('viewsSystem.downloadIndex16') }}
              </div>
            </div>
          </div>
          <!-- 相似游戏 -->
          <div class="mt-6">
            <div class="text-[#202124] font-bold text-lg flex justify-between mb-3">
              {{ $t('viewsSystem.downloadIndex17') }}
              <ion-img class="w-[.9375rem]"
                src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/icon-right-arrow.png" />
            </div>
            <div class="description__content" data-t="data_safety.content">
              {{ $t('viewsSystem.downloadIndex18') }}
            </div>
            <div class="mt-6 border p-6 rounded-lg">
              <div class="flex items-start">
                <div>
                  <ion-img class="w-4 mr-4"
                    src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/ic_share.png" />
                </div>
                <div>
                  <p>{{ $t('viewsSystem.downloadIndex19') }}</p>
                  <p>{{ $t('viewsSystem.downloadIndex20') }}</p>
                </div>
              </div>
              <div class="flex items-start mt-4">
                <div>
                  <ion-img class="w-4 mr-4"
                    src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/ic_cloud_upload.png" />
                </div>
                <div>
                  <p>{{ $t('viewsSystem.downloadIndex21') }}</p>
                </div>
              </div>
              <div class="flex items-start mt-4">
                <ion-img class="w-4 mr-4"
                  src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/ic_lock.png" />
                <div>
                  <p>{{ $t('viewsSystem.downloadIndex22') }}</p>
                </div>
              </div>
              <div class="flex items-start mt-4">
                <ion-img class="w-4 mr-4"
                  src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/ic_delete.png" />
                <div>
                  <p>{{ $t('viewsSystem.downloadIndex23') }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 评级评分 -->
          <div class="mt-6 rating">
            <div class="text-[1.1875rem] font-bold mb-5 text-[#202124] ">{{ $t('viewsSystem.downloadIndex24') }}</div>
            <div class="comments__tips">
              {{ $t('viewsSystem.downloadIndex25') }}
            </div>
            <div class="mt-6 flex flex-wrap">
              <div
                class="p-2 flex border border-[#dadce0] bg-[#e6f3ef] px-3 rounded-[.9375rem] mt-2.5 mr-4 text-[#028760]">
                <ion-img class="w-[.8125rem] mr-1"
                  src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/icon-phone.png" />
                {{ $t('viewsSystem.downloadIndex26') }}
              </div>
              <div class="p-2 flex border border-[#dadce0] px-3 rounded-[.9375rem] mt-2.5 mr-4">
                <ion-img class="w-[.8125rem] mr-1"
                  src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/icon-tablet.png" />
                {{ $t('viewsSystem.downloadIndex27') }}
              </div>
            </div>
            <div class="mt-6 flex items-center justify-between">
              <div>
                <div class="text-[#202124] text-[3.625rem] leading-tight">{{ appScore ? appScore : currentScore }}</div>
                <div class="flex">
                  <p class="start">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0070c9;">
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path
                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                      </path>
                    </svg>
                  </p>
                  <p class="start">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0070c9;">
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path
                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                      </path>
                    </svg>
                  </p>
                  <p class="start">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0070c9;">
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path
                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                      </path>
                    </svg>
                  </p>
                  <p class="start">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0070c9;">
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path
                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                      </path>
                    </svg>
                  </p>
                  <p class="start">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0070c9;">
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path
                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                      </path>
                    </svg>
                  </p>
                </div>
                <div class="leading-loose my-1.5 text-xs">50000</div>
              </div>
              <div>
                <div class="h-5 text-[.6875rem] flex items-center justify-between">
                  5
                  <div class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md">
                    <p class="h-full bg-[rgb(0,112,201)] rounded-md"></p>
                  </div>
                </div>
                <div class="h-5 text-[.6875rem] flex items-center justify-between">
                  4
                  <div class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md">
                    <p class="h-full w-12 bg-[rgb(0,112,201)] rounded-md"></p>
                  </div>
                </div>
                <div class="h-5 text-[.6875rem] flex items-center justify-between">
                  3
                  <div class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md">
                    <p class="h-full w-4 bg-[rgb(0,112,201)] rounded-md"></p>
                  </div>
                </div>
                <div class="h-5 text-[.6875rem] flex items-center justify-between">
                  2
                  <div class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md"></div>
                </div>
                <div class="h-5 text-[.6875rem] flex items-center justify-between">
                  1
                  <div class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md"></div>
                </div>
              </div>
            </div>
            <div>
              <div class="mt-6" v-for="item in currentCommentList" :key="item.userName">
                <div class="flex justify-between">
                  <div class="flex items-center">
                    <ion-img class="w-8 h-8 rounded-full object-fill overflow-hidden" :src="item.userImg" />
                    <div class="ml-3">{{ item.userName }}</div>
                  </div>
                  <ion-img class="w-1"
                    src="https://pwa-install-20240320.s3.sa-east-1.amazonaws.com/assets/icons/icon-more.png" />
                </div>
                <div class="flex items-center mt-4">
                  <p class="start_small" v-for="it in item.score" :key="it">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0070c9;">
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path d="M0 0h24v24H0z" fill="none"> </path>
                      <path
                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                      </path>
                    </svg>
                  </p>
                  <span class="text-xs ml-2">{{ item.date }}</span>
                </div>
                <div class="mt-3">{{ item.comment }}</div>
              </div>
            </div>
            <div class="   mt-10" />
            <!-- 安装模块 -->
            <div class="btn  shiny flex flex-col items-center text-xs" @click="checkHandle">
              <div class="flex items-center">
                <span class="text-sm text-[#ffffff]">{{ $t('viewsSystem.downloadIndex01') }}</span>
                <i class="download"></i>
              </div>
              <!-- {{ $t('viewsSystem.downloadIndex05') }} -->
            </div>
          </div>
          <div class="  mt-20" />
        </div>
      </div>
    </ion-content>
</template>
<script setup lang="ts">
import { IonContent, IonImg, } from '@ionic/vue';
import useTemplateLogic from '@/download/useTemplateLogic';
const {
  template,
  introductlist,
  currentScore,
  currentCommentList,
  appScore
} = useTemplateLogic();
const emit = defineEmits(["checkHandle"])
const checkHandle = () => {
  emit("checkHandle")
}

</script>
<style scoped lang="less">
ion-content {
  min-height: 52.75rem;

  .ios-first {
    height: 100vh;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background-color: #fff;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1;
    color: rgba(0, 0, 0, .87);
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    text-size-adjust: 100%;
    overflow-y: scroll;

    .wrap {
      min-height: 100%;
      position: relative;
      padding: 1.5rem;

      .top-content {
        backface-visibility: hidden;
        min-height: 100%;
        height: auto;
        margin-bottom: 1.5rem;

        .header {
          color: rgb(32, 33, 36);
          fill: rgb(32, 33, 36);
          stop-color: rgb(32, 33, 36);

          .ion-logo {
            border-width: 0;
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
            border-radius: 20%;
          }
        }
      }

      .publicUrl {
        width: 7.875rem;
        height: 14rem;
        flex-shrink: 0;
        margin-right: .5rem;
        background-size: 100% 100%;
        border-radius: 8px;
        overflow: hidden;

        img {
          vertical-align: top;
          width: 100%;
          height: 100%;
        }
      }

      .shiny {
        position: relative;
        overflow: hidden;

        .download {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAaUlEQVR4nGNgGDHgzacvR99++vofhN98/HKY6ha8hRoOw6MWYIC3o0E0/ILo7acv5eiG4sJvPn2pp5klb8g1nBhL3lBqOD5LqGY4NkuobjgMvP34tQGE4QKjgKqA2Ez1lkhMfwsYhioAAPmkvKD8qPnDAAAAAElFTkSuQmCC);
        }
      }

      .btn {
        width: 100%;
        padding: 0.625rem 0.5rem;
        border-radius: 0.5rem;
        background: #0070c9;
        color: #fff;
        cursor: pointer;
      }

      .rating {
        color: rgb(95, 99, 104);
      }

      .description__content,
      .comments__tips,
      .items-start {
        color: rgb(95, 99, 104);
        line-height: 1.25rem;
      }

      .start {
        width: 1rem;
        height: 1rem;
        font-size: 0.75rem
      }

      .start_small {
        width: 0.75rem;
        height: 0.75rem;

      }

      svg {
        fill: #0070c9;
      }

    }
  }
}
</style>
