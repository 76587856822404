import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/store/app'
import { payCreateApi } from '@/api/assets'
import { computed, ref, reactive } from 'vue';
import { useTenantStore } from '@/store/tenant'
import { showLoading } from '@/utils/loading';
import { PayCreateParams } from '@/api/assets/model'
import { getCustomerActivityId } from '@/utils/custom'
import { moneyConvertToClient } from '@/utils/custom'
import { rechargePromotionApi, activityApplyApi } from '@/api/activity';
import { ZTActivityTypes, ZActivityRuleType } from '@/enums/types/activity.type'
import { generateDefultRules, Language, getCurrentActivityName } from '@/i18n/ruleHelper/activityRule'


export function useRechargeLogic() {
  const { t } = useI18n();
  const appStore = useAppStore();
  const tenantStore = useTenantStore() // 租户store

  const { id } = getCustomerActivityId();

  const activityName = ref<string>('');
  const activityRule = ref<string>('');
  const rechargeList = ref([]);
  const currentWeekName = ref<string>('');

  const overCountDownList = computed(() => {
    return [
      { name: 'date.days', value: '00' },
      { name: 'date.hour', value: '00' },
      { name: 'date.minute', value: '00' },
      { name: 'date.second', value: '00' }
    ];
  })
  const merchantCy = computed(() => tenantStore.tenantInfo?.merchantCy) // 当前商户货币

  // create init初始化
  initSignVue();
  function initSignVue() {
    getRechargePromotionDetail()
  }

  // 充值大酬宾活动需求
  async function getRechargePromotionDetail() {
    showLoading();
    const res = await rechargePromotionApi(Number(id));
    console.log(res,33333333333)

    // 充值列表金额处理
    if (res?.activityConfig.length) {
      rechargeList.value = res.activityConfig.map((item: any) => {
        item.rechargeAmount = moneyConvertToClient(item.rechargeAmount);
        item.rewardAmount = moneyConvertToClient(item.rewardAmount);
        item.totalAmount = item.rechargeAmount + item.rewardAmount;
        // 需要的投注： 赠送部分 = 奖金 * 稽核   本金加赠送 = （奖金 + 充值）* 稽核
        if (res?.rewardAuditType === 'Gift') {
          item.needBetAmount = item.rewardAmount * item.auditMultiple;
        } else {
          item.needBetAmount = item.totalAmount * item.auditMultiple;
        }
        return item
      })
    }
    currentWeekName.value = handleWeekName(res?.activityDay); 

    // 处理充值大酬宾活动名字/规则
    if (res?.multilingual)  {
      const language = (await appStore.getLocale()) as Language;
      const multilingual = res.multilingual as Record<string, string>;
      activityName.value = await getCurrentActivityName(multilingual, language, ZTActivityTypes.enum.RechargePromotion);
      if (ZActivityRuleType.enum.DEFAULT === multilingual.ruleType) {
        const ruleParam = JSON.parse(multilingual.rule);
        activityRule.value = generateDefultRules(language, ZTActivityTypes.enum.RechargePromotion, ruleParam.variablesValue);
      } else {
        activityRule.value = multilingual.rule;
      }
      
    }
  }

  // 处理星期几文案显示
  function handleWeekName (day: number) {
    switch(day) {
      case 1:
        return t('viewsActivity.rechargePromotion01');
      case 2:
        return t('viewsActivity.rechargePromotion02');
      case 3:
        return t('viewsActivity.rechargePromotion03');
      case 4:
        return t('viewsActivity.rechargePromotion04');
      case 5:
        return t('viewsActivity.rechargePromotion05');
      case 6:
        return t('viewsActivity.rechargePromotion06');
      default:
      case 7:
        return t('viewsActivity.rechargePromotion07');
    }
  }

  // 充值按钮 click事件
  async function rechargeBtnClick(item: any) {
    const payCreateParams: PayCreateParams = { 
      extend: {
        activityId: Number(id),
        activityType: ZTActivityTypes.enum.RechargePromotion,
        rewardLevelId: item.uuid
      }
    }
    // const res = await payCreateApi(payCreateParams);
    console.log(payCreateParams,555555555)
  }

  return {
    activityName,
    activityRule,
    overCountDownList,
    rechargeList,
    merchantCy,
    currentWeekName,
    rechargeBtnClick
  }
}
