export const useMatchType = (type: 'google' | 'ios') => {

  const { isAndroid, isIOS, isPC } = toRefs(useSystemStore());
  const { downloadTemplate } = toRefs(useChannelStore());
  const tenantStore = useTenantStore();
  const checkDeviceMatch = (deviceType: boolean, appType: string) => {
    return tenantStore.domainInfo?.jumpDomainType === 'google'
      ? deviceType && downloadTemplate.value?.imitationAppType === appType
      : true;
  }
  const matchMap = new Map([
    ['google', () => checkDeviceMatch(isAndroid.value || isPC.value, 'google')],
    ['ios', () => checkDeviceMatch(isIOS.value, 'ios')],
  ]);

  const matchFn = matchMap.get(type);
  return matchFn ? matchFn() : true;

};
