// 签到奖励

type UserLevelInfo = {
  level: string;
  levelName: string;
}

type LevelConfig = UserLevelInfo & {
  betAmount: number;
  isDone: boolean;
}

type RewardStatus = 'canDo' | 'done' | 'expired' | 'unexpired'

type RewardConfig = {
  day: number;
  reward: number;
  status: RewardStatus;
}


type SvgResource = {
  done: () => Promise<typeof import('*.svg')>;
  undone: () => Promise<typeof import('*.svg')>;
};

type SvgResources = {
  [key: string]: SvgResource;
};

export const svgResources: SvgResources = {
  'blue-default': {
    done: () => import('@/assets/img/activity/levelSignIn/dotDone-blue-default.svg'),
    undone: () => import('@/assets/img/activity/levelSignIn/dotUndone-blue-default.svg'),
  },
  'green-dark': {
    done: () => import('@/assets/img/activity/levelSignIn/dotDone-blue-default.svg'),
    undone: () => import('@/assets/img/activity/levelSignIn/dotUndone-blue-default.svg'),
  },
  'green-default': {
    done: () => import('@/assets/img/activity/levelSignIn/dotDone-green-default.svg'),
    undone: () => import('@/assets/img/activity/levelSignIn/dotUndone-green-default.svg'),
  },
  'yellow-dark': {
    done: () => import('@/assets/img/activity/levelSignIn/dotDone-yellow-dark.svg'),
    undone: () => import('@/assets/img/activity/levelSignIn/dotUndone-yellow-dark.svg'),
  },
  'auroral-yellow': {
    done: () => import('@/assets/img/activity/levelSignIn/dotDone-yellow-dark.svg'),
    undone: () => import('@/assets/img/activity/levelSignIn/dotUndone-yellow-dark.svg'),
  },
  'purple-light': {
    done: () => import('@/assets/img/activity/levelSignIn/dotDone-purple-light.svg'),
    undone: () => import('@/assets/img/activity/levelSignIn/dotUndone-purple-light.svg'),
  },
  'amber-purple': {
    done: () => import('@/assets/img/activity/levelSignIn/dotDone-amber-purple.svg'),
    undone: () => import('@/assets/img/activity/levelSignIn/dotUndone-amber-purple.svg'),
  },
  'forest-green': {
    done: () => import('@/assets/img/activity/levelSignIn/dotDone-forest-green.svg'),
    undone: () => import('@/assets/img/activity/levelSignIn/dotUndone-forest-green.svg'),
  },
};
