export interface ActivityVariables {
  [key: string]: string | number;
}

export interface ActivityRulesMap {
  [key: string]: {
    [subKey: string]: string;
  };
}

export interface ActivityRulesMap_Recharge {
  [language: string]: {
    [category: string]: {
      [type: string]: string;
    };
  };
}

export interface ActivityRulesMap_Rebate {
  [language: string]: {
    [type: string]: string;
  };
}


export type Language = "zh-CN" | "en-US" | "pt-BR" | "id-ID" | "hi-IN" | "en-PH";
type RechargeType = "FIRST" | "SINGLE" | "SUM";
type ResetType = "NONE" | "DAILY" | "WEEKLY" | "WEEKLY_DAY" | "MONTHLY_DAY" | "PERIODIC";
type AgencyTypes = "ALL" | "NEW_REGISTER";
type RebateType = 'RECHARGE' | 'NORECHARGE';
type HighestType = 'OTHER' | 'REBATE';
type GameTypes = 'ELECTRONIC' | 'CHESS' | 'FISHING' | 'VIDEO' | 'LOTTERY' | 'ELECTRONIC';
type AcviticyType = "RedPacket" | "Agency" | "AssistanceCash" | "SignIn" | "Recharge" | "Rebate" | "LuckyWheel" | "Assistance" | "Custom" | "RechargeReward" | "MysteryReward" | "CommissionReward" | "googleDomainReward";
type AssistanceRewardType = "FIXED" | "RANGE";
type IncludeOrExclude = "EXCLUDE_GIFT" | "NORMAL";
type AwardType = "ACTIVITY" | "BALANCE";
type AwardTimeType = "IMMEDIATELY" | "DELAY";
type ExpiredAwardType = "ABANDONED" | "AUTO";
type TJoinType = "ALL" | "NEW_REGISTER";


// 公用第四条
const comTemplates_four: ActivityRulesMap = {
  "zh-CN": {
    ON: `本活动所赠送的奖金（不含本金）需{multiplier}倍有效投注才能提现，(投注仅限{limitData});\n`,
    OFF: `本活动所赠送的奖金（不含本金）需{multiplier}倍有效投注才能提现，(投注不限游戏平台);\n`
  },
  "en-US": {
    ON: `The bonus (excluding principal) of this event requires {multiplier} times of valid bets for withdrawal, (betting is limited to {limitData});\n`,
    OFF: `The bonus (excluding principal) of this event requires {multiplier} times of valid bets for withdrawal, (betting is not limited to game platform);\n`
  },
  "pt-BR": {
    ON: `O bônus (excluindo o principal) concedido por esta atividade requer {multiplier} vez o valor em apostas valida para serem retiradas, (A aposta está limitada a {limitData});\n`,
    OFF: `O bônus (excluindo o principal) concedido por esta atividade requer {multiplier} vez o valor em apostas valida para serem retiradas, (A aposta não está limitada a qualquer plataforma ou jogo);\n`
  },
  "id-ID": {
    ON: `Bonus yang diberikan dalam kegiatan ini (tidak termasuk modal) harus dipertaruhkan {multiplier} kali lipat untuk dapat ditarik，(Hanya Taruhan{limitData});\n`,
    OFF: `Bonus yang diberikan dalam kegiatan ini (tidak termasuk modal) harus dipertaruhkan {multiplier} kali lipat untuk dapat ditarik，(Taruhan tidak terbatas pada platform game);\n`
  },
  "hi-IN": {
    ON: `इस प्रमोशन द्वारा दी गई बोनस राशि (मूल राशि को छोड़कर) को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, (दांव केवल {limitData} तक सीमित है);\n`,
    OFF: `इस प्रमोशन द्वारा दी गई बोनस राशि (मूल राशि को छोड़कर) को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, (दांव किसी भी गेमिंग प्लेटफ़ॉर्म पर सीमित नहीं है);\n`
  },
};


//充值第四条
const comTemplates_four_Gift: ActivityRulesMap_Recharge = {
  "zh-CN": {
    ON: {
      Gift: "本活动所赠送的奖金（不含本金）需 {multiplier} 倍有效投注才能提现，(投注仅限{limitData});\n",
      GiftAndRecharge: "本活动所赠送的奖金 需 {multiplier} 倍有效投注才能提现，奖金+本金(投注仅限{limitData});\n"
    },
    OFF: {
      Gift: "本活动所赠送的奖金（不含本金）需 {multiplier} 倍有效投注才能提现，(投注不限游戏平台);\n",
      GiftAndRecharge: "本活动所赠送的奖金 需 {multiplier} 倍有效投注才能提现，奖金+本金(投注不限游戏平台);\n"
    }
  },
  "en-US": {
    ON: {
      Gift: "The bonus (excluding principal) of this event requires {multiplier} times of valid bets for withdrawal, (betting is limited to {limitData});\n",
      GiftAndRecharge: "The bonus of this event requires {multiplier} times of valid bets for withdrawal, bonus + principal(betting is limited to {limitData});\n"
    },
    OFF: {
      Gift: "The bonus (excluding principal) of this event requires {multiplier} times of valid bets for withdrawal, (betting is not limited to game platform);\n",
      GiftAndRecharge: "The bonus of this event requires {multiplier} times of valid bets for withdrawal, bonus + principal(betting is not limited to game platform);\n"
    }
  },
  "pt-BR": {
    ON: {
      Gift: "O bônus (excluindo o principal) concedido por esta atividade requer {multiplier} vez o valor em apostas validas para serem retiradas, (A aposta está limitada a {limitData});\n",
      GiftAndRecharge: "O bônus concedido por esta atividade requer {multiplier} apostas mais eficaz para serem retiradas, bônus + principal(A aposta está limitada a {limitData});\n"
    },
    OFF: {
      Gift: "O bônus (excluindo o principal) concedido por esta atividade requer {multiplier} apostas mais eficaz para serem retiradas, (A aposta não está limitada a qualquer plataforma ou jogo);\n",
      GiftAndRecharge: "O bônus concedido por esta atividade requer {multiplier} apostas mais eficaz para serem retiradas, bônus + principal(A aposta não está limitada a qualquer plataforma ou jogo);\n"
    }
  },
  "id-ID": {
    ON: {
      Gift: "Bonus yang diberikan dalam kegiatan ini (tidak termasuk modal) harus dipertaruhkan {multiplier} kali lipat untuk dapat ditarik，(Hanya Taruhan{limitData});\n",
      GiftAndRecharge: "Bonus yang diberikan dalam kegiatan ini harus dipertaruhkan  {multiplier} kali lipat untuk dapat ditarik，Bonus + Modal (Taruhan Hanya{limitData});\n"
    },
    OFF: {
      Gift: "Bonus yang diberikan dalam kegiatan ini (tidak termasuk modal) harus dipertaruhkan {multiplier} kali lipat untuk dapat ditarik，(Taruhan tidak terbatas pada platform game);\n",
      GiftAndRecharge: "Bonus yang diberikan dalam kegiatan ini harus dipertaruhkan  {multiplier} kali lipat untuk dapat ditarik，Bonus + Modal(Taruhan tidak terbatas pada platform game);\n"
    }
  },
  "hi-IN": {
    ON: {
      Gift: "इस प्रमोशन द्वारा दी गई बोनस राशि (मूल राशि को छोड़कर) को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, (दांव केवल {limitData} तक सीमित है);\n",
      GiftAndRecharge: "इस प्रमोशन द्वारा दी गई बोनस राशि को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, बोनस + मूल राशि (दांव केवल {limitData} तक सीमित है);\n"
    },
    OFF: {
      Gift: "इस प्रमोशन द्वारा दी गई बोनस राशि (मूल राशि को छोड़कर) को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, (दांव किसी भी गेमिंग प्लेटफ़ॉर्म पर सीमित नहीं है);\n",
      GiftAndRecharge: "इस प्रमोशन द्वारा दी गई बोनस राशि को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, बोनस + मूल राशि (दांव किसी भी गेमिंग प्लेटफ़ॉर्म पर सीमित नहीं है);\n"
    }
  }
};
//公用的规则模板
const addFiveSixRules: ActivityRulesMap = {
  "zh-CN": {
    5: `本活动仅限账号本人进行正常的人为操作，禁止租借、使用外挂、机器人、不同账号对赌、互刷、套利、接口、协议、利用漏洞、群控或其他技术手段参与，否则将取消或扣除奖励、冻结、甚至拉入黑名单;\n`,
    6: `为避免文字理解差异，平台将保留本活动最终解释权。\n`
  },
  "en-US": {
    5: `This activity is limited to the account holder for normal human operations. Renting, using cheats, robots, gambling with different accounts, mutual brushing, arbitrage, interfaces, agreements, exploiting vulnerabilities, group control, or other technical means are prohibited. Otherwise, rewards may be canceled or deducted, frozen, or even blacklisted;\n`,
    6: `To avoid differences in understanding of the text, the platform reserves the right to interpret this activity finally.\n`
  },
  "pt-BR": {
    5: `Esta atividade está limitada ao titular da conta para operações humanas normais. Alugar, usar cheats, robôs, jogar com contas diferentes,jogar com varias pessoas, arbitragem, interfaces, acordos, explorar vulnerabilidades, controle de grupo ou outros meios técnicos são proibidos.Caso contrário, as recompensas podem ser canceladas ou deduzidas,congeladas ou até mesmo colocadas em uma lista;\n`,
    6: `Para evitar mal-entendidos de interpretação de texto, a plataforma reserva o direito de interpretação e descisão final para à plataforma.\n`
  },
  "id-ID": {
    5: `Kegiatan ini hanya terbatas pada tindakan manusia normal oleh pemilik akun, dilarang menyewakan, menggunakan cheat, bot, bertaruh dengan akun lain, bertukar-tukar, arbitrase, memanfaatkan antarmuka, protokol, memanfaatkan kerentanan, kontrol kelompok, atau metode teknis lainnya untuk;\n`,
    6: `Untuk menghindari perbedaan pemahaman teks, platform akan mempertahankan hak interpretasi akhir kegiatan ini.\n`
  },
  "hi-IN": {
    5: `यह गतिविधि केवल खाता धारक द्वारा सामान्य मानव क्रियाओं तक सीमित है, किराये पर देना, चीट, बॉट, अन्य खातों के साथ सट्टेबाजी, अदला-बदली, आर्बिट्राज, इंटरफ़ेस का उपयोग, प्रोटोकॉल का उपयोग, कमजोरियों का फायदा उठाना, समूह नियंत्रण, या अन्य तकनीकी तरीकों से भाग लेना मना है। अन्यथा, पुरस्कार रद्द या कम कर दिए जाएंगे, खाता फ्रीज़ कर दिया जाएगा, या यहां तक कि ब्लैकलिस्ट में डाल दिया जाएगा;\n`,
    6: `पाठ की समझ में भिन्नता से बचने के लिए, मंच इस गतिविधि की अंतिम व्याख्या का अधिकार सुरक्षित रखेगा.\n`
  },
};

// 红包雨模板
const redPacketTemplatesFirst: ActivityRulesMap = {
  "zh-CN": {
    RECHARGE: `神秘矿场每日定时开启{times}次,每次开启持续{duration}分钟,矿场蕴藏{rewardCount}颗水晶,充值后可免费开采,开采到的水晶石自动转换到余额,兑换比例1:1;\n`
  },
  "en-US": {
    RECHARGE: `The mysterious mine is opened {times} times a day, and each opening lasts {duration} minutes. The mine contains {rewardCount} crystals, which can be mined for free after recharging. The mined crystals are automatically converted to the balance, and the exchange ratio is 1:1;\n`
  },
  "pt-BR": {
    RECHARGE: `A mina misteriosa é aberta {times} vezes por dia, e cada abertura dura {duration} minutos. A mina contém {rewardCount} cristais, que podem ser minerados gratuitamente após a recarga. Os cristais minerados são automaticamente convertidos para o saldo, e a taxa de troca é de 1:1;\n`
  },
  "id-ID": {
    RECHARGE: `Tambang misterius dibuka {times} kali sehari, dan setiap pembukaan berlangsung {duration} menit. Tambang tersebut berisi {rewardCount} kristal, yang dapat ditambang secara gratis setelah diisi ulang. Kristal yang ditambang secara otomatis dikonversi ke saldo, dan rasio pertukarannya adalah 1:1;\n`
  },
  "hi-IN": {
    RECHARGE: `रहस्यमयी खदान को दिन में {times} बार खोला जाता है, और प्रत्येक बार खुलने में {duration} मिनट लगते हैं। खदान में {rewardCount} क्रिस्टल हैं, जिन्हें रिचार्ज करने के बाद मुफ़्त में खनन किया जा सकता है। खनन किए गए क्रिस्टल स्वचालित रूप से बैलेंस में बदल जाते हैं, और विनिमय अनुपात 1:1 होता है;\n`
  },
};

const redPacketTemplatessScond: ActivityRulesMap = {
  "zh-CN": {
    2: `每个时间段的矿场都可参与,【请务必在以上时间段登录，以免错过奖励，强烈建议使用手机APP】，若未登录，过期作废（即未主动抢视为自愿放弃）;\n`,
    3: `活动奖励需自行手动领取,未领取则视为作废;\n`,
  },
  "en-US": {
    2: `The mine for each time slot can be participated in. [Please be sure to log during the above time slots to avoid missing out on rewards.. It is highly recommended to use the mobile app.] If you are not logged in,  expired (i.e., not actively claimed, it is deemed voluntary abandonment);\n`,
    3: `Activity rewards must be manually claimed; unclaimed rewards are considered void;\n`,
  },
  "pt-BR": {
    2: `a cada nova abertura da mina você pode participar. [Certifique-se de fazer login durante os intervalos de tempo acima para evitar perder recompensas. é altamente recomendável usar o aplicativo móvel.] Se você não estiver logado, expirou (ou seja, não foi reclamado ativamente, éconsiderado abandono voluntário);\n`,
    3: `As recompensas da atividade devem ser reivindicadas manualmente; recompensas não reivindicadas são consideradas inválidas;\n`,
  },
  "id-ID": {
    2: `Setiap periode waktu tambang dapat diikuti, 【Harap pastikan untuk masuk selama periode waktu tersebut, agar tidak melewatkan hadiah, sangat disarankan untuk menggunakan aplikasi ponsel】. Jika tidak masuk, akan hangus (artinya tidak ikut dianggap sebagai pengunduran diri sukarela);\n`,
    3: `Hadiah kegiatan harus diambil secara manual, jika tidak diambil akan dianggap hangus;\n`,
  },
  "hi-IN": {
    2: `प्रत्येक समय स्लॉट के लिए खदान में भाग लिया जा सकता है। [कृपया सुनिश्चित करें कि आप उपरोक्त समय स्लॉट के दौरान लॉग इन करें ताकि इनाम से वंचित न हों। मोबाइल ऐप का उपयोग करने की अत्यधिक अनुशंसा की जाती है।] यदि आप लॉग इन नहीं हैं, तो समाप्त (अर्थात, सक्रिय रूप से दावा नहीं किया गया, इसे स्वैच्छिक परित्याग माना जाता है);\n`,
    3: `गतिविधि के इनाम को मैन्युअल रूप से दावा करना होगा; बिना दावा किए गए इनाम को अमान्य माना जाएगा;\n`,
  },
};

// 红包雨模板
const redPacketTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `神秘矿场每日定时开启{times}次,每次开启持续{duration}分钟,矿场蕴藏{rewardCount}颗水晶,每次开启后玩家们都可免费开采一次,开采到的水晶石自动转换到余额,兑换比例1:1;\n`,
    2: `每个时间段的矿场都可参与,【请务必在以上时间段登录，以免错过奖励，强烈建议使用手机APP】，若未登录，过期作废（即未主动抢视为自愿放弃）;\n`,
    3: `活动奖励需自行手动领取,未领取则视为作废;\n`,
  },
  "en-US": {
    1: `The Mystery Mine opens {times} times at regular intervals every day, with each opening lasting {duration} minutes. The mine contains {rewardCount} crystals, and players can mine once for free after each opening. The mined crystals are automatically converted to balance at a 1:1 exchange rate;\n`,
    2: `The mine for each time slot can be participated in. [Please be sure to log during the above time slots to avoid missing out on rewards.. It is highly recommended to use the mobile app.] If you are not logged in,  expired (i.e., not actively claimed, it is deemed voluntary abandonment);\n`,
    3: `Activity rewards must be manually claimed; unclaimed rewards are considered void;\n`,
  },
  "pt-BR": {
    1: `A Mina Misteriosa abre {times} vezes em intervalos regulares todos os dias, com cada abertura durando {duration} minutos. A mina contém {rewardCount} cristais, e os jogadores podem minerar uma vez de graça após cada abertura. Os cristais minerados são convertidos automaticamente em saldo a uma taxa de câmbio de 1:1;\n`,
    2: `a cada nova abertura da mina você pode participar. [Certifique-se de fazer login durante os intervalos de tempo acima para evitar perder recompensas. é altamente recomendável usar o aplicativo móvel.] Se você não estiver logado, expirou (ou seja, não foi reclamado ativamente, éconsiderado abandono voluntário);\n`,
    3: `As recompensas da atividade devem ser reivindicadas manualmente; recompensas não reivindicadas são consideradas inválidas;\n`,
  },
  "id-ID": {
    1: `Tambang Misterius dibuka {times} kali setiap hari, setiap kali dibuka selama {duration} menit, dengan total {rewardCount} kristal tersimpan. Setiap kali dibuka, para pemain dapat menggali secara gratis, kristal yang digali akan otomatis dikonversi menjadi saldo, dengan rasio konversi 1:1;\n`,
    2: `Setiap periode waktu tambang dapat diikuti, 【Harap pastikan untuk masuk selama periode waktu tersebut, agar tidak melewatkan hadiah, sangat disarankan untuk menggunakan aplikasi ponsel】. Jika tidak masuk, akan hangus (artinya tidak ikut dianggap sebagai pengunduran diri sukarela);\n`,
    3: `Hadiah kegiatan harus diambil secara manual, jika tidak diambil akan dianggap hangus;\n`,
  },
  "hi-IN": {
    1: `मिस्ट्री माइन प्रत्येक दिन नियमित अंतराल पर {times} बार खुलती है, प्रत्येक खुलने का समय {duration} मिनट का होता है। खदान में {rewardCount} क्रिस्टल होते हैं, और प्रत्येक खुलने के बाद खिलाड़ी एक बार मुफ्त में खनन कर सकते हैं। खनन किए गए क्रिस्टल को 1:1 विनिमय दर पर स्वचालित रूप से बैलेंस में परिवर्तित कर दिया जाता है;\n`,
    2: `प्रत्येक समय स्लॉट के लिए खदान में भाग लिया जा सकता है। [कृपया सुनिश्चित करें कि आप उपरोक्त समय स्लॉट के दौरान लॉग इन करें ताकि इनाम से वंचित न हों। मोबाइल ऐप का उपयोग करने की अत्यधिक अनुशंसा की जाती है।] यदि आप लॉग इन नहीं हैं, तो समाप्त (अर्थात, सक्रिय रूप से दावा नहीं किया गया, इसे स्वैच्छिक परित्याग माना जाता है);\n`,
    3: `गतिविधि के इनाम को मैन्युअल रूप से दावा करना होगा; बिना दावा किए गए इनाम को अमान्य माना जाएगा;\n`,
  },
};

//签到
const signInTemplates_other: ActivityRulesMap = {
  "zh-CN": { //CONTINUOUS  CUMULATIVE
    CONTINUOUS: `本次活动为连续签到活动（期间如有中断，则从第一天开始）\n\n3. 必须每天连续签到，才能算入累加天数，若有中断，则重置天数，重新累加`,
    CUMULATIVE: `本次活动为累计签到活动\n\n3. 只要签到成功，就计入累计天数`,
  },
  "en-US": {
    CONTINUOUS: `This activity is a continuous check-in event (if there is a break during this period, it will start again from the first day)\n3.You must check in continuously every day for it to count towards the cumulative days; if there is an interruption, the days will be reset, and you will need to start accumulating again`,
    CUMULATIVE: `This event is a cumulative check-in activity\n\n3.As long as the check-in is successful, it will be counted towards the cumulative days`,
  },
  "pt-BR": {
    CONTINUOUS: `Esta atividade é um evento de check-in contínuo (se houver uma interrupção durante este período, começará novamente a partir do primeiro dia)\n3.É necessário fazer check-in continuamente todos os dias para contar para os dias acumulados; se houver uma interrupção, os dias serão reiniciados e você precisará começar a acumular novamente`,
    CUMULATIVE: `Este evento é uma atividade de check-in cumulativa\n\n3.Contanto que o check-in seja bem-sucedido, ele será contado para os dias cumulativos`,
  },
  "id-ID": {
    CONTINUOUS: `Kegiatan ini adalah acara cek-in terus-menerus (jika ada jeda selama periode ini, maka akan dimulai lagi dari hari pertama)\n3.Anda harus check-in secara berturut-turut setiap hari agar dihitung dalam jumlah hari kumulatif. Jika ada gangguan, jumlah hari akan diatur ulang dan dihitung kembali`,
    CUMULATIVE: `Kegiatan ini adalah kegiatan check-in kumulatif\n\n3.Selama check-in berhasil, itu akan dihitung sebagai jumlah hari kumulatif`,
  },
  "hi-IN": {
    CONTINUOUS: `यह गतिविधि लगातार चेक-इन गतिविधि है (यदि इस अवधि के दौरान कोई रुकावट होती है, तो यह पहले दिन से फिर से शुरू होगी)।\n3.हर दिन लगातार चेक-इन करना आवश्यक है ताकि इसे संचयी दिनों में गिना जा सके। यदि कोई रुकावट होती है, तो दिन को रीसेट किया जाएगा और फिर से जोड़ा जाएगा।`,
    CUMULATIVE: `यह गतिविधि एक संचयी चेक-इन गतिविधि है।\n\n3.जब तक चेक-इन सफल है, इसे संचयी दिनों में शामिल किया जाएगा।`,
  },
};

//签到
const signInTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `每天充值，投注金额成功满足活动条件，您将获得高达{highestReward}的固定奖金或相应神秘奖金\n`,
    2: `{signInTemplates_other}\n`,
    4: `奖励仅限在{platforms}端自动领取，过期未领取奖励作废\n`,
  },
  "en-US": {
    1: `Recharge every day, and if the betting amount successfully meets the activity requirements, you will receive a fixed bonus of up to {highestReward} or a corresponding mystery bonus\n`,
    2: `{signInTemplates_other}\n`,
    4: `Rewards can only be manually claimed on {platforms}. Rewards that are not claimed before expiration will be forfeited\n`,
  },
  "pt-BR": {
    1: `Recarregue todos os dias, e se o valor da aposta atender com sucesso aos requisitos da atividade, você receberá um bônus fixo de até {highestReward} ou um bônus misterioso correspondente\n`,
    2: `{signInTemplates_other}\n`,
    4: `As recompensas podem ser reclamadas manualmente apenas nas plataformas {platforms}. As recompensas que não forem reclamadas antes da expiração serão anuladas\n`,
  },
  "id-ID": {
    1: `Isi ulang setiap hari, dan jika jumlah taruhan memenuhi syarat aktivitas, Anda akan menerima bonus tetap hingga {highestReward} atau bonus misterius yang sesuai\n`,
    2: `{signInTemplates_other}\n`,
    4: `Hadiah hanya dapat diklaim secara manual di platform {platforms}. Hadiah yang tidak diklaim sebelum masa berlaku akan hangus\n`,
  },
  "hi-IN": {
    1: ` हर दिन रिचार्ज करें, और यदि शर्त राशि सफलतापूर्वक गतिविधि की आवश्यकताओं को पूरा करती है, तो आपको {highestReward} तक का निश्चित पुरस्कार या संबंधित रहस्यमय पुरस्कार मिलेगा।\n`,
    2: `{signInTemplates_other}\n`,
    4: `इनाम केवल {platforms} प्लेटफॉर्म पर मैन्युअल रूप से प्राप्त किए जा सकते हैं। जो इनाम समय सीमा से पहले प्राप्त नहीं किए जाते, वे अमान्य हो जाएंगे।\n`,
  },
};

//代理第一条  是否开启有效投注
const agencyTemplates_first: ActivityRulesMap = {
  "zh-CN": {
    ON: `发展好友领彩金，完成不同人数，可获得对应的彩金，金额最高{highestReward}，发展越多，奖励越高；下级只有在以下平台投注才被视为有效投注；{limitData};\n`,
    OFF: `发展好友领彩金，完成不同人数，可获得对应的彩金，金额最高{highestReward}，发展越多，奖励越高;\n`
  },
  "en-US": {
    ON: `Invite friends to receive a bonus. The more friends you invite, the higher the bonus you can earn, with a maximum reward of {highestReward}. The more friends you bring in, the greater the rewards; Bets placed by subordinates are considered valid only on the following platforms；{limitData};\n`,
    OFF: `Invite friends to receive a bonus. The more friends you invite, the higher the bonus you can earn, with a maximum reward of {highestReward}. The more friends you bring in, the greater the rewards;\n`
  },
  "pt-BR": {
    ON: `Convide amigos para receber um bônus. Ao completar diferentes números de convites, você pode ganhar o bônus correspondente, com um valor máximo de {highestReward}. Quanto mais amigos você convidar, maiores serão as recompensas; As apostas feitas pelos subordinados são consideradas válidas apenas nas seguintes plataformas；{limitData};\n`,
    OFF: `Convide amigos para receber um bônus. Ao completar diferentes números de convites, você pode ganhar o bônus correspondente, com um valor máximo de {highestReward}. Quanto mais amigos você convidar, maiores serão as recompensas;\n`
  },
  "id-ID": {
    ON: `Undang teman untuk menerima bonus. Setelah mencapai jumlah teman yang berbeda, Anda bisa mendapatkan bonus yang sesuai, dengan jumlah maksimal {highestReward}. Semakin banyak teman yang diundang, semakin besar hadiahnya; Hanya taruhan yang dipasang oleh bawahan pada platform berikut yang akan dianggap taruhan sah；{limitData};\n`,
    OFF: `Undang teman untuk menerima bonus. Setelah mencapai jumlah teman yang berbeda, Anda bisa mendapatkan bonus yang sesuai, dengan jumlah maksimal {highestReward}. Semakin banyak teman yang diundang, semakin besar hadiahnya;\n`
  },
  "hi-IN": {
    ON: `दोस्तों को आमंत्रित करें और बोनस प्राप्त करें। विभिन्न संख्या में लोगों को पूरा करने पर आपको संबंधित बोनस मिलेगा, जिसकी अधिकतम राशि {highestReward} है। जितने अधिक दोस्तों को आमंत्रित करेंगे, उतना ही अधिक इनाम मिलेगा।; नीचे दिए गए प्लेटफार्मों पर ही की गई सट्टेबाजी को वैध सट्टेबाजी माना जाएगा: {limitData}\n`,
    OFF: `दोस्तों को आमंत्रित करें और बोनस प्राप्त करें। विभिन्न संख्या में लोगों को पूरा करने पर आपको संबंधित बोनस मिलेगा, जिसकी अधिकतम राशि {highestReward} है। जितने अधिक दोस्तों को आमंत्रित करेंगे, उतना ही अधिक इनाम मिलेगा।;\n`
  },
};

// 代理
const agencyTemplates: ActivityRulesMap = {
  "zh-CN": {
    2: `此活动属平台额外赠送，同时享有其他代理奖励和佣金，即直接享受多倍快乐;\n`,
    3: `奖励仅限在iOS、Android、H5端手动领取，过期自动派发;\n`,
  },
  "en-US": {
    2: `This activity is an additional gift from the platform. You can also enjoy other agent rewards and commissions, which means you can enjoy multiple times the happiness directly;\n`,
    3: `Rewards can only be claimed manually on iOS, Android, and H5, and will be automatically distributed if expired;\n`,
  },
  "pt-BR": {
    2: `Esta atividade é um presente adicional da plataforma.Você também pode desfrutar de outras recompensas e comissões de agentes, o que significa que você pode aproveitar a a chance de ganhar bônus varias vezes;\n`,
    3: `As recompensas só podem ser reivindicadas manualmente no iOS, Android e H5, e serão distribuídas automaticamente se expiradas;\n`,
  },
  "id-ID": {
    2: `Kegiatan ini merupakan bonus tambahan dari platform, sambil menikmati bonus agen dan komisi lainnya, sehingga langsung menikmati kesenangan ganda;\n`,
    3: `Hadiah hanya dapat diambil secara manual di platform iOS, Android, dan H5, akan otomatis disebar jika kadaluwarsa;\n`,
  },
  "hi-IN": {
    2: `यह गतिविधि प्लेटफार्म का एक अतिरिक्त उपहार है। आप अन्य एजेंट इनाम और कमीशन का भी आनंद ले सकते हैं, जिसका मतलब है कि आप सीधे कई गुना खुशियाँ प्राप्त कर सकते हैं;\n`,
    3: `इनाम केवल iOS, Android, और H5 पर मैन्युअल रूप से दावा किए जा सकते हैं, और समाप्त होने पर स्वचालित रूप से वितरित कर दिए जाएंगे;\n`,
  },
};

//助力领现金  
const assistanceCashTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `每天登录可获得{freeDrawCount}次转盘免费抽奖机会，邀请好友免费获得奖励，好友帮助越多，奖励越多，最高可获得 {highestReward} 奖励;\n`,
    2: `活动期间内，累计总金额达到 {totalAmount}，即可开始提款\n`,
    3: `每个用户的活动有效期为 {cycleDaily} 天，可多次参与;\n`,
  },
  "en-US": {
    1: `You can get {freeDrawCount} free spins on the wheel for logging in every day, and you can get rewards for inviting friends for free. The more friends help, the more rewards you get, up to a maximum of {highestReward} reward;\n`,
    2: `During the event period, once the total accumulated amount reaches {totalAmount}, you can start withdrawing;\n`,
    3: `The validity period of each user's activity is {cycleDaily} days, and you can participate multiple times;\n`,
  },
  "pt-BR": {
    1: `Você pode obter {freeDrawCount} giros grátis na roda ao fazer login todos os dias, e você pode obter recompensas por convidar amigos gratuitamente. Quanto mais amigos ajudarem, mais recompensas você recebe, até um MÁXIMO {highestReward};\n`,
    2: `Durante o período do evento, uma vez que o valor total acumulado atinja {totalAmount}, você pode começar a sacar;\n`,
    3: `O período de validade da atividade de cada usuário é de {cycleDaily} dias, e você pode participar várias vezes;\n`,
  },
  "id-ID": {
    1: `Setiap hari masuk, Anda akan mendapatkan {freeDrawCount} kesempatan putaran gratis di roda keberuntungan, undang teman untuk mendapatkan hadiah gratis, semakin banyak teman yang membantu, semakin besar hadiahnya, dengan hadiah maksimum {highestReward};\n`,
    2: `Selama periode kegiatan, jika total akumulasi mencapai {totalAmount}, Anda dapat mulai melakukan penarikan;\n`,
    3: `Masa berlaku kegiatan untuk setiap pengguna adalah {cycleDaily} hari, dan dapat berpartisipasi beberapa kali;\n`,
  },
  "hi-IN": {
    1: `हर दिन लॉगिन करने पर आपको {freeDrawCount} बार रूले का मुफ्त ड्रा मौका मिलेगा। दोस्तों को आमंत्रित करके मुफ्त में इनाम प्राप्त करें। जितने अधिक दोस्त मदद करेंगे, उतने अधिक इनाम मिलेंगे, अधिकतम {highestReward} इनाम प्राप्त कर सकते हैं;\n`,
    2: `गतिविधि की अवधि के दौरान, कुल राशि {totalAmount} तक पहुंचने पर आप निकासी शुरू कर सकते हैं;\n`,
    3: `प्रत्येक उपयोगकर्ता के लिए गतिविधि की वैधता अवधि {cycleDaily} दिनों की है, और आप कई बार भाग ले सकते हैं;\n`,
  },
};

// 返水活动 第一条数据
const rebateTemplates_First: ActivityRulesMap_Rebate = {
  "zh-CN": {
    NORECHARGE: '有效投注{lowestRebateBet}或以上,将获得对应比例的打码流水返现金额奖金,投注越多奖励越多,最高可得投注的{highestReward}奖励;\n',
    RECHARGE: '充值{rechargeLimit}且有效投注{lowestRebateBet}以上,将获得对应比例的打码流水返现奖金,投注越多奖励越多,最高可得投注的{highestReward}奖励;\n',
  },
  "en-US": {
    NORECHARGE: 'Place bets of {lowestRebateBet} or more to receive a corresponding percentage of cashback bonus based on your total bets. The more you bet, the more rewards you can receive, up to {highestReward} of your total bets;\n',
    RECHARGE: 'Recharge {rechargeLimit} and wager over {lowestRebateBet} will earn you a corresponding percentage of your wagering turnover as cashback. The more you bet, the more rewards youll receive, with a maximum reward of {highestReward} of your total wagering amount;\n',
  },
  "pt-BR": {
    NORECHARGE: 'Faça apostas de {lowestRebateBet} ou mais para receber um bônus de cashback correspondente com base em suas apostas totais. Quanto mais você apostar, mais recompensas poderá receber, até {highestReward} do total de suas apostas;\n',
    RECHARGE: 'Recarregar {rechargeLimit} e apostar mais de {lowestRebateBet} lhe renderá um percentual correspondente do seu volume de apostas como cashback. Quanto mais você apostar, mais recompensas receberá, com uma recompensa máxima de {highestReward} do seu total de apostas;\n',
  },
  "id-ID": {
    NORECHARGE: 'Taruhan yang valid sebesar {lowestRebateBet} atau lebih akan menerima bonus uang kembali sesuai dengan persentase yang sesuai, semakin banyak taruhan semakin banyak bonus, dengan maksimum {highestReward} dari total taruhan;\n',
    RECHARGE: 'Dengan mengisi ulang {rechargeLimit} dan taruhan yang valid sebesar {lowestRebateBet} atau lebih, Anda akan menerima bonus uang kembali sesuai dengan persentase yang sesuai, semakin banyak taruhan semakin banyak bonus, dengan maksimum {highestReward} dari total taruhan;\n',
  },
  "hi-IN": {
    NORECHARGE: 'मान्य दांव {lowestRebateBet} या उससे अधिक पर, आपको संबंधित प्रतिशत का कैशबैक बोनस मिलेगा। जितना अधिक दांव, उतना अधिक इनाम। अधिकतम आप {highestReward} का इनाम प्राप्त कर सकते हैं;\n',
    RECHARGE: 'रिचार्ज {rechargeLimit} और मान्य दांव {lowestRebateBet} या उससे अधिक पर, आपको संबंधित प्रतिशत का कैशबैक बोनस मिलेगा। जितना अधिक दांव, उतना अधिक इनाम। अधिकतम आप {highestReward} का इनाम प्राप्त कर सकते हैं;\n',
  },
};

// 返水活动 二 三  条数据
const rebateTemplates_Other: ActivityRulesMap = {
  "zh-CN": {
    2: `活动期间,可重复参与,领取奖励后有效投注累计清零,可继续游戏获得奖励;\n`,
    3: `奖励仅限在iOS、Android、H5端手动领取，过期作废;\n`,
  },
  "en-US": {
    2: `You can participate in the activity multiple times during the promotion period. Your total bets will reset to zero after claiming the reward, and you can continue to play to earn more rewards;\n`,
    3: `Rewards can only be claimed manually on iOS, Android, and H5, and will expire if not claimed in time;\n`,
  },
  "pt-BR": {
    2: `Você pode participar da atividade várias vezes durante o período de promoção. Suas apostas totais serão redefinidas para zero após reivindicar a recompensa, e você pode continuar jogando para ganhar mais recompensas;\n`,
    3: `As recompensas só podem ser reivindicadas manualmente no iOS, Android e H5, e expirarão se não forem reclamadas a tempo;\n`,
  },
  "id-ID": {
    2: `Selama periode kegiatan, Anda dapat berpartisipasi secara berulang, total taruhan yang valid akan direset setelah menerima hadiah, dan Anda dapat melanjutkan permainan untuk mendapatkan hadiah;\n`,
    3: `Hadiah hanya dapat diambil secara manual di platform iOS, Android, dan H5, akan otomatis disebar jika kadaluwarsa;\n`,
  },
  "hi-IN": {
    2: `गतिविधि की अवधि के दौरान, आप बार-बार भाग ले सकते हैं। इनाम प्राप्त करने के बाद, मान्य दांव की कुल राशि शून्य हो जाएगी और आप खेल जारी रख सकते हैं और अधिक इनाम प्राप्त कर सकते हैं;\n`,
    3: `इनाम केवल iOS, Android, H5 पर ही मैन्युअल रूप से प्राप्त किए जा सकते हैं। समाप्ति के बाद अमान्य हो जाएगा;\n`,
  },
};
//充值活动模板  第一条数据
const rechargeTemplates_First: ActivityRulesMap_Recharge = {
  "zh-CN": {
    FIRST: {
      NONE: "账号首次充值专享，只有一次机会，充值越多，奖励越多，最高奖励{highestReward};\n",
      DAILY: "活动期间，每日首次充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward};\n",
      WEEKLY: "活动期间，每周首次充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward};\n",
    },
    SINGLE: {
      NONE: "活动期间，单笔充值将获得不同程度的奖励（此活动可重复参与），充值越多，奖励越多，最高奖励{highestReward};\n",
      DAILY: "活动期间，单笔充值将获得不同程度的奖励（此活动可重复参与），充值越多，奖励越多，最高奖励{highestReward};\n",
      WEEKLY: "活动期间，单笔充值将获得不同程度的奖励（此活动可重复参与），充值越多，奖励越多，最高奖励{highestReward};\n",
    },
    SUM: {
      NONE: "活动期间，累计充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward}，每个阶梯可获得一次奖励;\n",
      DAILY: "活动期间，每日累计充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward}，每个阶梯可获得一次奖励;\n",
      WEEKLY: "活动期间，每周累计充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward};\n",
    },
  },
  // 英语
  "en-US": {
    FIRST: {
      NONE: "Account's first recharge exclusive, only one chance, the more you recharge, the more rewards you get, with the maximum reward being {highestReward};\n",
      DAILY: "During the event, a single recharge will receive rewards of varying degrees (this event can be participated in repeatedly). the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward};\n",
      WEEKLY: "During the event, the first recharge each week will receive rewards of varying degrees, the more rewards you get, with the maximum reward being {highestReward};\n"
    },
    SINGLE: {
      NONE: "During the event, a single recharge will receive rewards of varying degrees (this event can be participated in repeatedly). the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward};\n",
      DAILY: "During the event, a single recharge will receive rewards of varying degrees (this event can be participated in repeatedly). the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward};\n",
      WEEKLY: "During the event period, each single recharge per week will receive rewards of varying degrees  (this event can be participated in repeatedly),the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward};\n"
    },
    SUM: {
      NONE: "During the event period, you will receive rewards based on the total amount of recharge,the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward},each tier can receive a reward once;\n",
      DAILY: "During the event period, you will receive rewards based on the total amount of recharge,the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward},each tier can receive a reward once;\n",
      WEEKLY: "During the event, different rewards will be given based on the cumulative recharge amount each week, the more rewards you will receive, with the maximum reward being {highestReward},each tier can receive a reward once;\n"
    },
  },
  // 葡萄牙语
  "pt-BR": {
    FIRST: {
      NONE: "Recarga exclusiva da conta, apenas uma chance, quanto mais você recarrega, mais recompensas você recebe, com uma recompensa máxima de {highestReward};\n",
      DAILY: "Durante o evento, a cada dia receberá recompensas de vários graus. Quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward};\n",
      WEEKLY: "Durante o evento, a primeira recarga de cada semana receberá recompensas de graus variados, quanto mais recargas você obtiver, com uma recompensa máx {highestReward};\n"
    },
    SINGLE: {
      NONE: "Durante o evento, uma única recarga receberá recompensas de graus variados (este evento pode ser participado repetidamente). quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward};\n",
      DAILY: "Durante o evento, uma única recarga receberá recompensas de graus variados (este evento pode ser participado repetidamente). quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward};\n",
      WEEKLY: "Durante o evento, uma única recarga receberá recompensas de graus variados (este evento pode ser participado repetidamente). quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward};\n"
    },
    SUM: {
      NONE: "Durante o período do evento, você receberá recompensas com base no valor total da recarga, quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward}, cada nível pode receber uma recompensa uma vez;\n",
      DAILY: "Durante o período do evento, você receberá recompensas com base no valor total da recarga, quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward}, cada nível pode receber uma recompensa uma vez;\n",
      WEEKLY: "Durante o evento, serão dados diferentes prêmios com base no valor da recarga acumulada a cada semana, quanto mais você recarregar, mais prêmios receberá, com um prêmio máx {highestReward}, cada nível pode receber um prêmio uma vez;\n"
    },
  },
  //印尼语
  "id-ID": {
    FIRST: {
      NONE: "Eksklusif untuk Pengisian Pertama Akun, hanya satu kesempatan, semakin banyak pengisian, semakin banyak hadiahnya, hadiah tertinggi {highestReward};\n",
      DAILY: "Selama periode promosi, setiap pengisian pertama setiap hari akan mendapatkan hadiah dalam tingkat yang berbeda, semakin banyak pengisian, semakin banyak hadiah, hadiah tertinggi {highestReward};\n",
      WEEKLY: "Selama periode promosi, pengisian pertama setiap minggu akan mendapatkan hadiah dalam tingkat yang berbeda, semakin banyak pengisian, semakin banyak hadiah, hadiah tertinggi {highestReward};\n"
    },
    SINGLE: {
      NONE: "Selama periode promosi, pengisian tunggal akan mendapatkan hadiah dalam tingkat yang berbeda (kegiatan ini dapat diikuti secara berulang), semakin banyak pengisian, semakin banyak hadiah, hadiah tertinggi {highestReward};\n",
      DAILY: "Selama periode aktivitas, setiap pengisian tunggal akan mendapatkan hadiah dengan tingkat yang berbeda (aktivitas ini dapat diikuti secara berulang), semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward};\n",
      WEEKLY: "Selama periode aktivitas, setiap pengisian tunggal akan mendapatkan hadiah dengan tingkat yang berbeda (aktivitas ini dapat diikuti secara berulang), semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward};\n"
    },
    SUM: {
      NONE: "Selama periode aktivitas, pengisian total akan mendapatkan hadiah dengan tingkat yang berbeda, semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward}，Setiap tangga dapat mendapatkan satu kali hadiah;\n",
      DAILY: "Selama periode aktivitas, pengisian harian akan mendapatkan hadiah dengan tingkat yang berbeda, semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward}，Setiap tangga dapat mendapatkan satu kali hadiah;\n",
      WEEKLY: "Selama periode aktivitas, pengisian mingguan akan mendapatkan hadiah dengan tingkat yang berbeda, semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward};\n"
    },
  },
  //印地语
  "hi-IN": {
    FIRST: {
      NONE: "खाता पहली जमा के लिए विशेष, केवल एक बार मौका, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};\n",
      DAILY: "प्रमोशन अवधि के दौरान, प्रतिदिन पहली जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};\n",
      WEEKLY: "Fप्रमोशन अवधि के दौरान, साप्ताहिक पहली जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};\n"
    },
    SINGLE: {
      NONE: "प्रमोशन अवधि के दौरान, एकल लेनदेन पर विभिन्न स्तर के इनाम प्राप्त होंगे (इस गतिविधि में बार-बार भाग लिया जा सकता है), जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};\n",
      DAILY: "प्रमोशन अवधि के दौरान, एकल लेनदेन पर विभिन्न स्तर के इनाम प्राप्त होंगे (इस गतिविधि में बार-बार भाग लिया जा सकता है), जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};\n",
      WEEKLY: "प्रमोशन अवधि के दौरान, एकल लेनदेन पर विभिन्न स्तर के इनाम प्राप्त होंगे (इस गतिविधि में बार-बार भाग लिया जा सकता है), जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};\n"
    },
    SUM: {
      NONE: "प्रमोशन अवधि के दौरान, संचयी जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward}, प्रत्येक स्तर पर एक बार इनाम प्राप्त होगा;\n",
      DAILY: "प्रमोशन अवधि के दौरान, प्रतिदिन संचयी जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward}, प्रत्येक स्तर पर एक बार इनाम प्राप्त होगा;\n",
      WEEKLY: "प्रमोशन अवधि के दौरान, साप्ताहिक संचयी जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};\n"
    },
  },
};

// 充值活动  二   条数据
const rechargeTemplates_Two: ActivityRulesMap = {
  "zh-CN": {
    2: `充值不限制方式，奖励预计10分钟后更新，请等待奖励发放;\n`,
  },
  "en-US": {
    2: `Recharge methods are not limited, rewards are expected to be updated after 10 minutes, please wait for the rewards to be issued;\n`,
  },
  "pt-BR": {
    2: `Não há limitação se você estiver usando um tipo diferente de banco para fazer o depósito, a recompensa pode ser reivindicada em 10 minutos. Aguarde a emissão das recompensas.;\n`,
  },
  "id-ID": {
    2: `Tidak ada batasan cara pengisian, hadiah diperkirakan akan diperbarui dalam 10 menit, harap tunggu hadiahnya disalurkan;\n`,
  },
  "hi-IN": {
    2: `रिचार्ज विधियाँ सीमित नहीं हैं, इनाम अद्यतन होने की उम्मीद है 10 मिनट के बाद, कृपया इनाम जारी होने की प्रतीक्षा करें;\n`,
  },
};

// 充值活动  三   条数据
const rechargeTemplates_Three: ActivityRulesMap = {
  "zh-CN": {
    ACTIVITY: "奖励仅限在iOS、Android、H5端手动领取，过期自动派发;\n",
    BALANCE: "获得的奖励直接派发到账;\n"
  },
  "en-US": {
    ACTIVITY: "Rewards can only be claimed manually on iOS, Android, and H5, and will be automatically distributed if expired;\n",
    BALANCE: "The rewards will be directly credited to your account;\n"
  },
  "pt-BR": {
    ACTIVITY: "As recompensas só podem ser reivindicadas manualmente no iOS, Android e H5, e serão distribuídas automaticamente se expiradas;\n",
    BALANCE: "As recompensas serão creditadas diretamente em sua conta;\n"
  },
  "id-ID": {
    ACTIVITY: "Hadiah hanya dapat diambil secara manual di platform iOS, Android, dan H5, akan otomatis disebar jika kadaluwarsa;\n",
    BALANCE: "Hadiah yang diperoleh langsung didistribusikan ke akun;\n"
  },
  "hi-IN": {
    ACTIVITY: "इनाम केवल iOS, Android, और H5 पर मैन्युअल रूप से दावा किए जा सकते हैं, और समाप्त होने पर स्वचालित रूप से वितरित कर दिए जाएंगे;\n",
    BALANCE: "प्राप्त इनाम सीधे आपके खाते में जमा किया जाएगा;\n"
  }
};

// 幸运转盘 第一 二条数据
const luckyWheelTemplates_firstSecond: ActivityRulesMap = {
  "zh-CN": {
    1: `完成转盘任务,可获得对应的抽奖券,每次抽奖消耗1张抽奖券;\n`,
    2: `收集"H","A","P","P","Y", 每收集一套则可兑换一次{exchangeReward}奖励;\n`,
  },
  "en-US": {
    1: `Complete the roulette task to receive corresponding lottery tickets, with each draw costing 1 ticket;\n`,
    2: `Collect "H", "A", "P", "P", "Y", and exchange each set for a prize of {exchangeReward};\n`,
  },
  "pt-BR": {
    1: `Conclua a tarefa da roleta para receber bilhetes de loteria correspondentes, sendo que cada sorteio custa 1 bilhete;\n`,
    2: `Colete "H", "A", "P", "P", "Y" e troque cada conjunto por um prêmio de {exchangeReward};\n`,
  },
  "id-ID": {
    1: `Menyelesaikan misi Putar Roda akan mendapatkan kupon undian sesuai, setiap kali mengundih akan menghabiskan 1 kupon undian;\n`,
    2: `Kumpulkan 'H','A','P','P','Y', setiap set yang dikumpulkan dapat ditukarkan dengan hadiah {exchangeReward} sekali;\n`,
  },
  "hi-IN": {
    1: `लॉटरी टिकट प्राप्त करने के लिए रूले कार्य को पूरा करें, प्रत्येक ड्रा के लिए 1 टिकट खर्च होता है;\n`,
    2: `"H", "A", "P", "P", "Y" इकट्ठा करें और प्रत्येक सेट को {exchangeReward} के इनाम के लिए बदलें;\n`,
  },
};

// 幸运转盘 第三条数据
const luckyWheelTemplates_third: ActivityRulesMap_Rebate = {
  "zh-CN": {
    NONE: `奖励仅限在iOS、Android、H5手动领取，未领取奖励依然保留，直至活动关闭或者活动结束时，奖励将作废;\n`,
    DAILY: `活动期间,今日未使用的抽奖券与兑换券,则次日00:00会保留;\n`,
    WEEKLY: `活动期间,本周未使用的抽奖券与兑换券,则下周一00:00会保留;\n`,
  },
  "en-US": {
    NONE: `The rewards can only be claimed manually on iOS, Android, and H5 platforms. Unclaimed rewards will remain available until the event is closed or ends, at which point the rewards will be forfeited;\n`,
    DAILY: `During the event, any unused lottery tickets and exchange vouchers from today will be retained at 00:00 the next day;\n`,
    WEEKLY: `During the event, any unused lottery tickets and exchange vouchers from this week will be retained at 00:00 on Monday of the following week;\n`,
  },
  "pt-BR": {
    NONE: `As recompensas só podem ser resgatadas manualmente nas plataformas iOS, Android e H5. As recompensas não resgatadas permanecerão disponíveis até que o evento seja encerrado ou termine; após isso, as recompensas serão anuladas;\n`,
    DAILY: `Durante o evento, qualquer bilhete de loteria e voucher de troca não utilizados hoje serão mantidos às 00:00 do dia seguinte;\n`,
    WEEKLY: `Durante o evento, qualquer bilhete de loteria e voucher de troca não utilizados nesta semana serão mantidos às 00:00 na segunda-feira da próxima semana;\n`,
  },
  "id-ID": {
    NONE: `Hadiah hanya dapat diambil secara manual di platform iOS, Android, dan H5. Hadiah yang tidak diambil akan tetap tersedia sampai acara ditutup atau berakhir, setelah itu hadiah akan hangus;\n`,
    DAILY: `Selama acara berlangsung, kupon undian dan kupon penukaran yang tidak digunakan hari ini akan disimpan pada pukul 00:00 hari berikutnya;\n`,
    WEEKLY: `Selama acara berlangsung, kupon undian dan kupon penukaran yang tidak digunakan minggu ini akan disimpan pada pukul 00:00 hari Senin minggu depan;\n`,
  },
  "hi-IN": {
    NONE: `पुरस्कार केवल iOS, Android, और H5 प्लेटफार्म पर मैन्युअल रूप से प्राप्त किए जा सकते हैं। प्राप्त न किए गए पुरस्कार तब तक उपलब्ध रहेंगे जब तक कि कार्यक्रम बंद नहीं हो जाता या समाप्त नहीं हो जाता; उसके बाद पुरस्कार रद्द कर दिए जाएंगे।;\n`,
    DAILY: `कार्यक्रम के दौरान, आज के अप्रयुक्त लॉटरी टिकट और विनिमय कूपन अगले दिन 00:00 बजे सुरक्षित रहेंगे।;\n`,
    WEEKLY: `कार्यक्रम के दौरान, इस सप्ताह के अप्रयुक्त लॉटरी टिकट और विनिमय कूपन अगले सप्ताह के सोमवार को 00:00 बजे तक सुरक्षित रहेंगे।;\n`,
  },
};

const assistanceTemplates_first: ActivityRulesMap_Recharge = {
  "zh-CN": {
    DAILY: {
      FIXED: `此活动属于"人道救援，江湖救急"，当您昨日在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高 {highestReward};\n`,
      RANGE: `此活动属于"人道救援，江湖救急"，当您昨日在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高 {highestReward}%;\n`
    },
    WEEKLY: {
      FIXED: `此活动属于"人道救援，江湖救急"，当您上周在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高 {highestReward};\n`,
      RANGE: `此活动属于"人道救援，江湖救急"，当您上周在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高 {highestReward}%;\n`
    }
  },
  "en-US": {
    DAILY: {
      FIXED: `This activity belongs to "Humanitarian Rescue, Rivers and Lakes Emergency Rescue". When you Yesterday lose {lowestLoss} or more in the game, you can get the corresponding amount of rescue money. The level of the bonus is determined by the amount of the loss. The more the loss, the higher the rescue amount, up to {highestReward};\n`,
      RANGE: `This activity belongs to "Humanitarian Rescue, Rivers and Lakes Emergency Rescue". When you Yesterday lose {lowestLoss} or more in the game, you can get the corresponding amount of rescue money. The level of the bonus is determined by the amount of the loss. The more the loss, the higher the rescue amount, up to {highestReward}%;\n`
    },
    WEEKLY: {
      FIXED: `This activity belongs to "Humanitarian Rescue, Rivers and Lakes Emergency Rescue". When you last week lose {lowestLoss} or more in the game, you can get the corresponding amount of rescue money. The level of the bonus is determined by the amount of the loss. The more the loss, the higher the rescue amount, up to {highestReward};\n`,
      RANGE: `This activity belongs to "Humanitarian Rescue, Rivers and Lakes Emergency Rescue". When you last week lose {lowestLoss} or more in the game, you can get the corresponding amount of rescue money. The level of the bonus is determined by the amount of the loss. The more the loss, the higher the rescue amount, up to {highestReward}%;\n`
    }
  },
  "pt-BR": {
    DAILY: {
      FIXED: `Este evento pertence a "resgate humanitário, ajuda urgente nos rios e lagos". Quando você perde {lowestLoss} ou mais no jogo ontem, você pode obter o valor correspondente de dinheiro de socorro. A quantidade de bônus depende da quantidade de perda. Quanto mais você perde, maior o valor do dinheiro de socorro, até {highestReward};\n`,
      RANGE: `Este evento pertence a "resgate humanitário, ajuda urgente nos rios e lagos". Quando você perde {lowestLoss} ou mais no jogo ontem, você pode obter o valor correspondente de dinheiro de socorro. A quantidade de bônus depende da quantidade de perda. Quanto mais você perde, maior o valor do dinheiro de socorro, até {highestReward}%;\n`
    },
    WEEKLY: {
      FIXED: `Esta atividade pertence a "Resgate Humanitário, Resgate de Emergência em Rios e Lagos". Quando você perde {lowestLoss} ou mais na semana passada no jogo, você pode obter o valor correspondente de dinheiro de resgate. O nível do bônus é determinado pelo valor da perda. Quanto maior a perda, maior o valor do resgate, até {highestReward};\n`,
      RANGE: `Esta atividade pertence a "Resgate Humanitário, Resgate de Emergência em Rios e Lagos". Quando você perde {lowestLoss} ou mais na semana passada no jogo, você pode obter o valor correspondente de dinheiro de resgate. O nível do bônus é determinado pelo valor da perda. Quanto maior a perda, maior o valor do resgate, até {highestReward}%;\n`
    }
  },
  "id-ID": {
    DAILY: {
      FIXED: `Kegiatan ini merupakan "Bantuan Kemanusiaan, Pertolongan di Dunia Jianghu", ketika Anda mengalami kerugian {lowestLoss} atau lebih dalam permainan kemarin, Anda akan menerima bantuan uang sejumlah yang sesuai. Tingkat hadiah ditentukan oleh jumlah kerugian, semakin besar kerugian, semakin tinggi jumlah bantuan, dengan jumlah bantuan maksimum {highestReward};\n`,
      RANGE: `Kegiatan ini merupakan "Bantuan Kemanusiaan, Pertolongan di Dunia Jianghu", ketika Anda mengalami kerugian {lowestLoss} atau lebih dalam permainan kemarin, Anda akan menerima bantuan uang sejumlah yang sesuai. Tingkat hadiah ditentukan oleh jumlah kerugian, semakin besar kerugian, semakin tinggi jumlah bantuan, dengan jumlah bantuan maksimum {highestReward}%;\n`
    },
    WEEKLY: {
      FIXED: `Kegiatan ini merupakan "Bantuan Kemanusiaan, Pertolongan di Dunia Jianghu", ketika Anda mengalami kerugian {lowestLoss} atau lebih dalam permainan minggu lalu, Anda akan menerima bantuan uang sejumlah yang sesuai. Tingkat hadiah ditentukan oleh jumlah kerugian, semakin besar kerugian, semakin tinggi jumlah bantuan, dengan jumlah bantuan maksimum {highestReward};\n`,
      RANGE: `Kegiatan ini merupakan "Bantuan Kemanusiaan, Pertolongan di Dunia Jianghu", ketika Anda mengalami kerugian {lowestLoss} atau lebih dalam permainan minggu lalu, Anda akan menerima bantuan uang sejumlah yang sesuai. Tingkat hadiah ditentukan oleh jumlah kerugian, semakin besar kerugian, semakin tinggi jumlah bantuan, dengan jumlah bantuan maksimum {highestReward}%;\n`
    }
  },
  "hi-IN": {
    DAILY: {
      FIXED: `यह गतिविधि "मानवीय बचाव, आपातकालीन सहायता" के अंतर्गत आती है। जब आप कल के खेल में {lowestLoss} से अधिक की हानि करते हैं, तो आप संबंधित राशि की सहायता राशि प्राप्त कर सकते हैं। बोनस की मात्रा हानि की मात्रा पर निर्भर करती है। जितनी अधिक हानि होगी, सहायता राशि उतनी ही अधिक होगी, अधिकतम {highestReward}  तक;\n`,
      RANGE: `यह गतिविधि "मानवीय बचाव, आपातकालीन सहायता" के अंतर्गत आती है। जब आप कल के खेल में {lowestLoss} से अधिक की हानि करते हैं, तो आप संबंधित राशि की सहायता राशि प्राप्त कर सकते हैं। बोनस की मात्रा हानि की मात्रा पर निर्भर करती है। जितनी अधिक हानि होगी, सहायता राशि उतनी ही अधिक होगी, अधिकतम {highestReward}%  तक ;\n`
    },
    WEEKLY: {
      FIXED: `यह गतिविधि "मानवीय बचाव, नदियों और झीलों में आपातकालीन बचाव" से संबंधित है। जब आप पिछले सप्ताह खेल में {lowestLoss} या उससे अधिक हारते हैं, तो आप संबंधित राशि का बचाव धन प्राप्त कर सकते हैं। बोनस का स्तर हानि की राशि द्वारा निर्धारित किया जाता है। जितनी अधिक हानि होगी, उतनी ही अधिक बचाव राशि होगी, अधिकतम {highestReward} तक;\n`,
      RANGE: `यह गतिविधि "मानवीय बचाव, नदियों और झीलों में आपातकालीन बचाव" से संबंधित है। जब आप पिछले सप्ताह खेल में {lowestLoss} या उससे अधिक हारते हैं, तो आप संबंधित राशि का बचाव धन प्राप्त कर सकते हैं। बोनस का स्तर हानि की राशि द्वारा निर्धारित किया जाता है। जितनी अधिक हानि होगी, उतनी ही अधिक बचाव राशि होगी, अधिकतम {highestReward}% तक;\n`
    }
  },
};

const assistanceTemplates_firstSpecial: ActivityRulesMap = {
  "zh-CN": {
    CUMULATIVE_LOSS: `此活动属于“人道救援，江湖救急”，当您昨日在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高{highestReward},若昨日并未产生亏损或者亏损金额达不到最低救援要求时，则会不发放救援金，盈利或者亏损的金额自动流入到新的一期中;\n`
  },
  "en-US": {
    CUMULATIVE_LOSS: `This activity belongs to "Humanitarian Rescue, Emergency Rescue". When you lost {lowestLoss} or more in the game yesterday, you can get a corresponding amount of rescue money. The amount of the bonus is determined by the loss amount. The more you lose, the higher the rescue amount, up to {highestReward} If there was no loss yesterday or the loss amount did not meet the minimum rescue requirements, the rescue money will not be issued, and the profit or loss amount will automatically flow into the new period;\n`
  },
  "pt-BR": {
    CUMULATIVE_LOSS: `Esta atividade pertence a "Resgate Humanitário, Resgate de Emergência de Jianghu". Quando você perdeu {lowestLoss} ou mais no jogo ontem, você pode obter a quantia correspondente de dinheiro de resgate. O valor do bônus é determinado pelo valor da perda. Quanto mais você perder, maior será o valor do resgate, até {highestReward} Se não houve perda ontem ou o valor da perda não atendeu aos requisitos mínimos de resgate, o dinheiro de resgate não será emitido, e o valor do lucro ou perda fluirá automaticamente para o novo período;\n`
  },
  "id-ID": {
    CUMULATIVE_LOSS: `Aktivitas ini termasuk dalam "Penyelamatan Kemanusiaan, Penyelamatan Darurat". Bila Anda kalah {lowestLoss} atau lebih dalam permainan kemarin, Anda bisa mendapatkan sejumlah uang penyelamatan yang sesuai. Jumlah bonus ditentukan oleh jumlah kerugian. Semakin banyak Anda kalah, semakin tinggi jumlah penyelamatan, hingga {highestReward} Jika tidak ada kerugian kemarin atau jumlah kerugian tidak memenuhi persyaratan penyelamatan minimum, uang penyelamatan tidak akan dikeluarkan, dan jumlah keuntungan atau kerugian akan secara otomatis mengalir ke periode baru;\n`
  },
  "hi-IN": {
    CUMULATIVE_LOSS: `यह गतिविधि "मानवीय बचाव, आपातकालीन बचाव" से संबंधित है। जब आप कल खेल में {lowestLoss} या उससे अधिक हार गए, तो आप बचाव राशि की एक समान राशि प्राप्त कर सकते हैं। बोनस की राशि नुकसान की राशि से निर्धारित होती है। जितना अधिक आप हारते हैं, बचाव राशि उतनी ही अधिक होती है, जो {highestReward} तक होती है। यदि कल कोई नुकसान नहीं हुआ या नुकसान की राशि न्यूनतम बचाव आवश्यकताओं को पूरा नहीं करती है, तो बचाव राशि जारी नहीं की जाएगी, और लाभ या हानि राशि स्वचालित रूप से नई अवधि में प्रवाहित होगी।;\n`
  },
};

const assistanceTemplates_second: ActivityRulesMap_Recharge = {
  "zh-CN": {
    FIXED: {
      EXCLUDE_GIFT: '计算公式：赠送金额= 周期内亏损金额（扣除领取的优惠）对应阶梯奖金;\n',
      NORMAL: '计算公式：赠送金额= 周期内亏损金额对应阶梯奖金;\n',
      CUMULATIVE_LOSS: '计算公式：赠送金额= 周期内亏损金额（扣除领取的优惠）对应阶梯奖金;\n',
    },
    RANGE: {
      EXCLUDE_GIFT: '计算公式： 奖励金额 = 当期损失金额（扣除领取的优惠）x 返点率;\n',
      NORMAL: '计算公式： 奖励金额 = 当期损失金额 x 返点率;\n',
      CUMULATIVE_LOSS: '计算公式： 奖励金额 = 当期损失金额（扣除领取的优惠）x 返点率;\n',
    },
  },
  "en-US": {
    FIXED: {
      EXCLUDE_GIFT: 'Calculation formula: The bonus amount = Loss amount within the period（Deducting received discounts）Corresponding tier bonus;\n',
      NORMAL: 'Calculation formula: The bonus amount = Loss amount within the period Corresponding tier bonus;\n',
      CUMULATIVE_LOSS: 'Calculation formula: The bonus amount = Loss amount within the period（Deducting received discounts）Corresponding tier bonus;\n',
    },
    RANGE: {
      EXCLUDE_GIFT: 'Calculation formula: Bonus amount = Loss amount within the period（Deducting received discounts）x Rebate rate;\n',
      NORMAL: 'Calculation formula: Bonus amount = Loss amount within the period x Rebate rate;\n',
      CUMULATIVE_LOSS: 'Calculation formula: Bonus amount = Loss amount within the period（Deducting received discounts）x Rebate rate;\n',
    },
  },
  "pt-BR": {
    FIXED: {
      EXCLUDE_GIFT: 'Fórmula de cálculo: O valor do bônus = Valor da perda dentro do período (Dedução dos descontos recebidos) Bônus da escada correspondente;\n',
      NORMAL: 'Fórmula de cálculo: O valor do bônus = Valor da perda dentro do período Bônus da escada correspondente;\n',
      CUMULATIVE_LOSS: 'Fórmula de cálculo: O valor do bônus = Valor da perda dentro do período (Dedução dos descontos recebidos) Bônus da escada correspondente;\n',
    },
    RANGE: {
      EXCLUDE_GIFT: 'Fórmula de cálculo: Valor do bônus = Valor da perda dentro do período (Dedução dos descontos recebidos) x Taxa de rebate;\n',
      NORMAL: 'Fórmula de cálculo: Valor do bônus = Valor da perda dentro do período x Taxa de rebate;\n',
      CUMULATIVE_LOSS: 'Fórmula de cálculo: Valor do bônus = Valor da perda dentro do período (Dedução dos descontos recebidos) x Taxa de rebate;\n',
    },
  },
  "id-ID": {
    FIXED: {
      EXCLUDE_GIFT: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian dalam Periode (Setelah Dikurangi Diskon yang Diterima) x Jumlah Hadiah pada Tingkat yang Sama;\n',
      NORMAL: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian dalam Periode x Jumlah Hadiah pada Tingkat yang Sama;\n',
      CUMULATIVE_LOSS: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian dalam Periode (Setelah Dikurangi Diskon yang Diterima) x Jumlah Hadiah pada Tingkat yang Sama;\n',
    },
    RANGE: {
      EXCLUDE_GIFT: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian Periode Ini (Setelah Dikurangi Diskon yang Diterima) x Tingkat Cashback;\n',
      NORMAL: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian Periode Ini x Tingkat Cashback;\n',
      CUMULATIVE_LOSS: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian Periode Ini (Setelah Dikurangi Diskon yang Diterima) x Tingkat Cashback;\n',
    },
  },
  "hi-IN": {
    FIXED: {
      EXCLUDE_GIFT: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि (प्राप्त छूटों की कटौती) संबंधित स्तर का बोनस;\n',
      NORMAL: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि संबंधित स्तर का बोनस;\n',
      CUMULATIVE_LOSS: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि (प्राप्त छूटों की कटौती) संबंधित स्तर का बोनस;\n',
    },
    RANGE: {
      EXCLUDE_GIFT: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि (प्राप्त छूटों की कटौती) x रिबेट दर;\n',
      NORMAL: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि x रिबेट दर;\n',
      CUMULATIVE_LOSS: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि (प्राप्त छूटों की कटौती) x रिबेट दर;\n',
    },
  },
};

// 救援金第三条
const rewardDistributionMapWithDelay = {
  "zh-CN": {
    // 每日
    DAILY: {
      ACTIVITY: {   //手动领取
        IMMEDIATELY: "获得的奖励次日 00:00:00 以后才可领取;\n",     //立即到账
        DELAY: "获得的奖励次日 {time} 以后才可领取;\n",              //延迟到账
      },
      BALANCE: {     //自动领取
        IMMEDIATELY: "获得的奖励次日 00:00:00 以后才可领取;\n",
        DELAY: "获得的奖励次日 {time} 以后才可领取;\n",
      },
    },
    // 每周
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "获得的奖励下周一 00:00:00 以后才可领取;\n",
        DELAY: "获得的奖励下周一 {time} 以后才可领取;\n",
      },
      BALANCE: {
        IMMEDIATELY: "获得的奖励下周一 00:00:00 以后才可领取;\n",
        DELAY: "获得的奖励下周一 {time} 以后才可领取;\n",
      },
    },
  },
  "en-US": {
    DAILY: {
      ACTIVITY: {
        IMMEDIATELY: "The rewards can be claimed starting from Next day 00:00:00 later;\n",
        DELAY: "The rewards can be claimed starting from Next day {time} later;\n",
      },
      BALANCE: {
        IMMEDIATELY: "The rewards can be claimed starting from Next day 00:00:00 later;\n",
        DELAY: "The rewards can be claimed starting from Next day {time} later;\n",
      },
    },
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "The rewards can be claimed starting from Next Monday 00:00:00 later;\n",
        DELAY: "The rewards can be claimed starting from Next Monday {time} later;\n",
      },
      BALANCE: {
        IMMEDIATELY: "The rewards can be claimed starting from Next Monday 00:00:00 later;\n",
        DELAY: "The rewards can be claimed starting from Next Monday {time} later;\n",
      },
    },
  },
  "pt-BR": {
    DAILY: {
      ACTIVITY: {
        IMMEDIATELY: "As recompensas podem ser reivindicadas a partir do próximo dia às 00:00:00;\n",
        DELAY: "As recompensas podem ser reivindicadas a partir do próximo dia às {time};\n",
      },
      BALANCE: {
        IMMEDIATELY: "As recompensas podem ser reivindicadas a partir do próximo dia às 00:00:00;\n",
        DELAY: "As recompensas podem ser reivindicadas a partir do próximo dia às {time};\n",
      },
    },
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "As recompensa pode ser reclamada a partir da próxima segunda-feira às 00:00:00;\n",
        DELAY: "As recompensa pode ser reclamada a partir da próxima segunda-feira às {time};\n",
      },
      BALANCE: {
        IMMEDIATELY: "As recompensa pode ser reclamada a partir da próxima segunda-feira às 00:00:00;\n",
        DELAY: "As recompensa pode ser reclamada a partir da próxima segunda-feira às {time};\n",
      },
    },
  },
  "id-ID": {
    DAILY: {
      ACTIVITY: {
        IMMEDIATELY: "Hadiah yang diperoleh baru bisa diambil setelah pukul 00:00:00 hari berikutnya;\n",
        DELAY: "Hadiah yang diperoleh baru bisa diambil setelah {time} hari berikutnya;\n",
      },
      BALANCE: {
        IMMEDIATELY: "Hadiah yang diperoleh baru bisa diambil setelah pukul 00:00:00 hari berikutnya;\n",
        DELAY: "Hadiah yang diperoleh baru bisa diambil setelah {time} hari berikutnya;\n",
      },
    },
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "Hadiah yang diperoleh baru bisa diambil setelah pukul 00:00:00 hari Senin depan;\n",
        DELAY: "Hadiah yang diperoleh baru bisa diambil setelah {time} hari Senin depan;\n",
      },
      BALANCE: {
        IMMEDIATELY: "Hadiah yang diperoleh baru bisa diambil setelah pukul 00:00:00 hari Senin depan;\n",
        DELAY: "Hadiah yang diperoleh baru bisa diambil setelah {time} hari Senin depan;\n",
      },
    },
  },
  "hi-IN": {
    DAILY: {
      ACTIVITY: {
        IMMEDIATELY: "इनाम अगले दिन 00:00:00 बजे से दावा किए जा सकते हैं;\n",
        DELAY: "इनाम अगले दिन {time} बजे से दावा किए जा सकते हैं;\n",
      },
      BALANCE: {
        IMMEDIATELY: "इनाम अगले दिन 00:00:00 बजे से दावा किए जा सकते हैं;\n",
        DELAY: "इनाम अगले दिन {time} बजे से दावा किए जा सकते हैं;\n",
      },
    },
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "इनाम अगले सोमवार 00:00:00 बजे से दावा किए जा सकते हैं;\n",
        DELAY: "इनाम अगले सोमवार {time} बजे से दावा किए जा सकते हैं;\n",
      },
      BALANCE: {
        IMMEDIATELY: "इनाम अगले सोमवार 00:00:00 बजे से दावा किए जा सकते हैं;\n",
        DELAY: "इनाम अगले सोमवार {time} बजे से दावा किए जा सकते हैं;\n",
      },
    },
  },
};

const assistanceTemplates_four: ActivityRulesMap_Recharge = {
  "zh-CN": {
    DAILY: {
      ABANDONED: `每个周期结束1天后，获得的奖励过期直接作废;\n`,
      AUTO: `每个周期结束1天后，获得的奖励过期自动派发到账;\n`
    },
    WEEKLY: {
      ABANDONED: `每个周期结束7天后，获得的奖励过期直接作废;\n`,
      AUTO: `每个周期结束7天后，获得的奖励过期自动派发到账;\n`
    },
  },
  "en-US": {
    DAILY: {
      ABANDONED: `The rewards obtained will expire and be invalidated directly after 1 day at the end of each cycle;\n`,
      AUTO: `The rewards obtained will expire and be automatically distributed to the account after 1 day at the end of each cycle;\n`
    },
    WEEKLY: {
      ABANDONED: `The rewards obtained will expire and be invalidated directly after 7 day at the end of each cycle;\n`,
      AUTO: `The rewards obtained will expire and be automatically distributed to the account after 7 day at the end of each cycle;\n`
    },
  },
  "pt-BR": {
    DAILY: {
      ABANDONED: `As recompensas recebidas expirarão 1 dias após o término de cada dia. As recompensas expiradas serão canceladas automaticamente;\n`,
      AUTO: `As recompensas recebidas expirarão 1 dias após o término de cada ciclo.As recebidas serão automaticamente distribuídas para a sua conta após a expiração;\n`
    },
    WEEKLY: {
      ABANDONED: `As recompensas recebidas expirarão 7 dias após o término de cada ciclo. As recompensas expiradas serão canceladas automaticamente;\n`,
      AUTO: `As recompensas recebidas expirarão 7 dias após o término de cada ciclo.As recebidas serão automaticamente distribuídas para a sua conta após a expiração;\n`
    },
  },
  "id-ID": {
    DAILY: {
      ABANDONED: `Setelah 1 hari berakhirnya setiap siklus, hadiah yang diperoleh akan hangus secara langsung;\n`,
      AUTO: `Setiap siklus berakhir 1 hari kemudian, hadiah yang diperoleh akan secara otomatis disalurkan ke akun;\n`
    },
    WEEKLY: {
      ABANDONED: `Setelah 7 hari berakhirnya setiap siklus, hadiah yang diperoleh akan hangus secara langsung;\n`,
      AUTO: `Setiap siklus berakhir 7 hari kemudian, hadiah yang diperoleh akan secara otomatis disalurkan ke akun;\n`
    },
  },
  "hi-IN": {
    DAILY: {
      ABANDONED: `प्रत्येक चक्र समाप्त होने के 1 दिन बाद, प्राप्त इनाम की अवधि समाप्त हो जाएगी और वह अमान्य हो जाएगा;\n`,
      AUTO: `प्रत्येक चक्र समाप्त होने के 1 दिन बाद, प्राप्त इनाम की अवधि समाप्त होने पर, वह स्वतः ही खाते में जमा हो जाएगा;\n`
    },
    WEEKLY: {
      ABANDONED: `प्रत्येक चक्र समाप्त होने के 7 दिन बाद, प्राप्त इनाम की अवधि समाप्त हो जाएगी और वह अमान्य हो जाएगा;\n`,
      AUTO: `प्रत्येक चक्र समाप्त होने के 7 दिन बाद, प्राप्त इनाम की अवधि समाप्त होने पर, वह स्वतः ही खाते में जमा हो जाएगा;\n`
    },
  },
};

// 会员答谢
const memberRewardTemplates_firstSecond: ActivityRulesMap = {
  "zh-CN": {
    1: `为答谢会员对平台的大力支持，特此推出“会员答谢”活动;\n`,
    2: `在活动期间内，会员充值/盈亏/有效投注，到达一定值，便可获得一定奖励，充值/盈亏/有效投注越高，奖励越丰富;\n`,
  },
  "en-US": {
    1: `To show our appreciation for the tremendous support from our members, we are launching the "Member Appreciation" event;\n`,
    2: `During the event period, members who reach a certain amount in deposits/losses/valid bets will receive a certain reward. The higher the deposits/losses/valid bets, the richer the rewards;\n`,
  },
  "pt-BR": {
    1: `Como agradecimento pelo grande apoio dos membros à plataforma, estamos lançando o evento "Apreciação aos Membros;\n`,
    2: `Durante o período do evento, os membros que atingirem um determinado valor em depósitos/perdas/apostas válidas receberão uma determinada recompensa. Quanto maiores os depósitos/perdas/apostas válidas, mais ricas serão as recompensas;\n`,
  },
  "id-ID": {
    1: `Sebagai apresiasi atas dukungan besar para anggota terhadap platform ini, kami meluncurkan acara "Apresiasi Anggota";\n`,
    2: `Selama periode acara, anggota yang mencapai jumlah tertentu dalam deposit/kerugian/taruhan yang valid akan menerima hadiah tertentu. Semakin tinggi deposit/kerugian/taruhan yang valid, semakin besar hadiahnya;\n`,
  },
  "hi-IN": {
    1: `अपने सदस्यों के जबरदस्त समर्थन के प्रति अपनी प्रशंसा व्यक्त करने के लिए, हम "सदस्य प्रशंसा" कार्यक्रम शुरू कर रहे हैं;\n`,
    2: `कार्यक्रम की अवधि के दौरान, जो सदस्य जमा/हानि/वैध शर्तों में एक निश्चित राशि तक पहुँचते हैं, उन्हें एक निश्चित इनाम मिलेगा। जितनी अधिक जमा/हानि/वैध शर्तें होंगी, इनाम उतना ही अधिक होगा;\n`,
  },
};

const memberRewardTemplates_Three: ActivityRulesMap = {
  "zh-CN": {
    AUTO: `获得的奖励需要手动领取才可到账，若忘记领取，过期时奖励将自动发放到您的账户;\n`,
    ABANDONED: "获得的奖励需要手动领取才可到账，若忘记领取，过期时奖励将作废;\n"
  },
  "en-US": {
    AUTO: `Rewards need to be claimed manually to be credited to your account. If you forget to claim them, the rewards will be automatically credited to your account upon expiration;\n`,
    ABANDONED: `The rewards must be claimed manually to be credited. If forgotten, the rewards will expire and become invalid;\n`,
  },
  "pt-BR": {
    AUTO: `As recompensas precisam ser resgatadas manualmente para serem creditadas na sua conta. Se você esquecer de resgatá-las, as recompensas serão automaticamente creditadas na sua conta após o vencimento;\n`,
    ABANDONED: `As recompensas devem ser reivindicadas manualmente para serem creditadas. Caso se esqueça, as recompensas expirarão e se tornarão inválidas;\n`,
  },
  "id-ID": {
    AUTO: `Hadiah yang diperoleh perlu diambil secara manual agar bisa masuk ke akun Anda. Jika lupa mengambilnya, hadiah akan otomatis dikreditkan ke akun Anda saat kadaluarsa;\n`,
    ABANDONED: `Hadiah harus diklaim secara manual agar dapat diterima. Jika lupa, hadiah akan kedaluwarsa dan menjadi tidak berlaku;\n`,
  },
  "hi-IN": {
    AUTO: `इनाम को आपके खाते में जमा करने के लिए मैन्युअल रूप से दावा करना होगा। यदि आप उन्हें दावा करना भूल जाते हैं, तो समाप्ति पर इनाम स्वचालित रूप से आपके खाते में जमा कर दिए जाएंगे;\n`,
    ABANDONED: `प्राप्त पुरस्कार को खाते में जोड़ने के लिए मैन्युअल रूप से दावा करना आवश्यक है। यदि भूल गए, तो पुरस्कार की समय सीमा समाप्त हो जाएगी और वह अमान्य हो जाएगा।;\n`,
  }
};

// 神秘彩金 第一条数据
const mysteryRewardTemplates_first: ActivityRulesMap_Rebate = {
  "zh-CN": {
    NEW_REGISTER: `新用户注册后自动报名参与活动，从报名当天开始，第2、3、7、15、30天可获得神秘奖品;\n`,
    ALL: `活动开启后，自动报名参与活动，从报名当天开始，第2、3、7、15、30天可获得神秘奖品;\n`,
  },
  "en-US": {
    NEW_REGISTER: `New users are automatically registered to participate in the activity upon registration. Starting from the day of registration, you can receive mystery prizes on the 2nd, 3rd, 7th, 15th, and 30th days;\n`,
    ALL: `Once the activity starts, you will be automatically registered to participate. Starting from the day of registration, you can receive mystery prizes on the 2nd, 3rd, 7th, 15th, and 30th days;\n`,
  },
  "pt-BR": {
    NEW_REGISTER: `Os novos usuários são automaticamente inscritos para participar da atividade após o registro. A partir do dia da inscrição, você pode receber prêmios misteriosos no 2º, 3º, 7º, 15º e 30º dias;\n`,
    ALL: `Após o início da atividade, você será automaticamente inscrito para participar. A partir do dia da inscrição, você poderá receber prêmios misteriosos no 2º, 3º, 7º, 15º e 30º dias;\n`,
  },
  "id-ID": {
    NEW_REGISTER: `Pengguna baru secara otomatis terdaftar untuk berpartisipasi dalam kegiatan setelah pendaftaran. Mulai dari hari pendaftaran, Anda dapat menerima hadiah misterius pada hari ke-2, ke-3, ke-7, ke-15, dan ke-30;\n`,
    ALL: `Setelah aktivitas dimulai, Anda akan secara otomatis terdaftar untuk berpartisipasi. Mulai dari hari pendaftaran, Anda dapat menerima hadiah misterius pada hari ke-2, ke-3, ke-7, ke-15, dan ke-30;\n`,
  },
  "hi-IN": {
    NEW_REGISTER: `नए उपयोगकर्ता पंजीकरण के बाद स्वचालित रूप से गतिविधि में शामिल हो जाते हैं। पंजीकरण के दिन से शुरू होकर, आपको 2, 3, 7, 15, और 30 दिनों पर रहस्यमय पुरस्कार मिल सकते हैं।;\n`,
    ALL: `गतिविधि शुरू होने के बाद, आपको स्वचालित रूप से भाग लेने के लिए पंजीकृत किया जाएगा। पंजीकरण के दिन से शुरू होकर, आप 2, 3, 7, 15 और 30 दिनों पर रहस्यमय पुरस्कार प्राप्त कर सकते हैं।;\n`,
  },
};

// 神秘彩金 第二条数据
const mysteryRewardTemplates_Two: ActivityRulesMap = {
  "zh-CN": {
    2: `充值越多，在线时间越长，活动奖励越大;\n`,
  },
  "en-US": {
    2: `The more you top up and the longer you stay online, the greater the activity rewards;\n`,
  },
  "pt-BR": {
    2: `Quanto mais você recarregar e quanto mais tempo você ficar online, maiores serão as recompensas da atividade;\n`,
  },
  "id-ID": {
    2: `Semakin banyak Anda melakukan pengisian dan semakin lama Anda online, semakin besar hadiah aktivitasnya;\n`,
  },
  "hi-IN": {
    2: `जितना अधिक आप रिचार्ज करेंगे और जितना अधिक आप ऑनलाइन रहेंगे, गतिविधि पुरस्कार उतना बड़ा होगा।;\n`,
  },
};

// 神秘彩金 第三条数据
const mysteryRewardTemplates_second = {
  "zh-CN": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: '活动于注册后第{awardKeepDays} 天23:59:59消失，未领取奖金视为放弃;',
        AUTO: '活动于注册后第{awardKeepDays} 天23:59:59消失，未领取奖金将自动派发;',
      },
      PERIODIC: {
        ABANDONED: '活动于注册后第{awardKeepDays} 天23:59:59重置，未领取奖金视为放弃;',
        AUTO: '活动于注册后第{awardKeepDays} 天23:59:59重置，未领取奖金将自动派发;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: '活动于报名后第{awardKeepDays} 天23:59:59消失，未领取奖金视为放弃;',
        AUTO: '活动于报名后第{awardKeepDays} 天23:59:59消失，未领取奖金将自动派发;',
      },
      PERIODIC: {
        ABANDONED: '活动于报名后第{awardKeepDays} 天23:59:59重置，未领取奖金视为放弃;',
        AUTO: '活动于报名后第{awardKeepDays} 天23:59:59重置，未领取奖金将自动派发;',
      },
    },
  },
  "en-US": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: 'The activity will expire at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed rewards will be considered forfeited;',
        AUTO: 'The activity will disappear at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed bonuses will be automatically distributed;',
      },
      PERIODIC: {
        ABANDONED: 'The event resets at 23:59:59 on the {awardKeepDays} day after registration. Bonuses not claimed will be considered forfeited;',
        AUTO: 'The event resets at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed bonuses will be automatically distributed;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: 'The activity will expire at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed rewards will be considered forfeited;',
        AUTO: 'The activity will expire at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed bonuses will be automatically distributed;',
      },
      PERIODIC: {
        ABANDONED: 'The event resets at 23:59:59 on the {awardKeepDays} day after registration. Unclaimed bonuses will be considered forfeited;',
        AUTO: 'The event resets at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed bonuses will be automatically distributed;',
      },
    },
  },
  "pt-BR": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: 'A atividade expirará às 23:59:59 do {awardKeepDays} dia após o registro. Recompensas não reivindicadas serão consideradas como perdidas;',
        AUTO: 'A atividade desaparecerá às 23:59:59 do {awardKeepDays}º dia após o registro. Os bônus não reclamados serão distribuídos automaticamente;',
      },
      PERIODIC: {
        ABANDONED: 'O evento é redefinido às 23:59:59 do {awardKeepDays}º dia após o registro. Bônus não resgatados serão considerados como desistidos;',
        AUTO: 'O evento é redefinido às 23:59:59 do {awardKeepDays}º dia após o registro. Bônus não resgatados serão automaticamente distribuídos;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: 'A atividade expirará às 23:59:59 do {awardKeepDays} dia após a inscrição. Recompensas não reivindicadas serão consideradas como perdidas;',
        AUTO: 'A atividade expirará às 23:59:59 no {awardKeepDays}º dia após a inscrição. Os bônus não reivindicados serão automaticamente distribuídos;',
      },
      PERIODIC: {
        ABANDONED: 'O evento é redefinido às 23:59:59 do {awardKeepDays}º dia após o registro. Bônus não resgatados serão considerados como desistência;',
        AUTO: 'O evento é redefinido às 23:59:59 do {awardKeepDays}º dia após a inscrição. Bônus não resgatados serão automaticamente distribuídos;',
      },
    },
  },
  "id-ID": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: 'Kegiatan akan berakhir pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Hadiah yang tidak diklaim akan dianggap hangus;',
        AUTO: 'Kegiatan akan menghilang pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang belum diklaim akan didistribusikan secara otomatis;',
      },
      PERIODIC: {
        ABANDONED: 'Acara direset pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diambil akan dianggap hangus;',
        AUTO: 'Acara direset pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diambil akan secara otomatis didistribusikan;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: 'Kegiatan akan berakhir pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Hadiah yang tidak diklaim akan dianggap hangus;',
        AUTO: 'Kegiatan akan berakhir pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diklaim akan secara otomatis didistribusikan;',
      },
      PERIODIC: {
        ABANDONED: 'Acara direset pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diambil dianggap hangus;',
        AUTO: 'Acara direset pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diambil akan secara otomatis didistribusikan;',
      },
    },
  },
  "hi-IN": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: 'गतिविधि पंजीकरण के {awardKeepDays} दिन के बाद 23:59:59 पर समाप्त हो जाएगी। अवार्ड जो प्राप्त नहीं किए गए हैं, उन्हें छोडा हुआ माना जाएगा।;',
        AUTO: 'पंजीकरण के {awardKeepDays} दिन बाद गतिविधि 23:59:59 पर समाप्त हो जाएगी। जो बोनस प्राप्त नहीं किए गए हैं, वे स्वचालित रूप से वितरित कर दिए जाएंगे।;',
      },
      PERIODIC: {
        ABANDONED: 'कार्यक्रम पंजीकरण के {awardKeepDays} दिन बाद 23:59:59 पर रीसेट हो जाएगा। जो बोनस प्राप्त नहीं किए गए हैं, उन्हें त्यागा हुआ माना जाएगा।;',
        AUTO: 'कार्यक्रम पंजीकरण के {awardKeepDays} दिन बाद 23:59:59 पर रीसेट होगा। जो बोनस प्राप्त नहीं किए गए हैं, वे स्वचालित रूप से वितरित कर दिए जाएंगे।;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: 'गतिविधि पंजीकरण के {awardKeepDays} दिन के बाद 23:59:59 पर समाप्त हो जाएगी। जो पुरस्कार प्राप्त नहीं किए गए हैं, उन्हें त्यागा हुआ माना जाएगा।;',
        AUTO: 'गतिविधि पंजीकरण के {awardKeepDays} दिन बाद 23:59:59 पर समाप्त होगी। जो बोनस प्राप्त नहीं किए गए हैं, उन्हें स्वचालित रूप से वितरित कर दिया जाएगा।;',
      },
      PERIODIC: {
        ABANDONED: 'कार्यक्रम पंजीकरण के {awardKeepDays} दिन के बाद 23:59:59 पर रीसेट हो जाएगा। जो बोनस प्राप्त नहीं किए गए हैं, उन्हें त्यागा हुआ माना जाएगा।;',
        AUTO: 'कार्यक्रम पंजीकरण के {awardKeepDays} दिन के बाद 23:59:59 पर रीसेट होगा। जो बोनस प्राप्त नहीं किए गए हैं, वे स्वचालित रूप से वितरित कर दिए जाएंगे।;',
      },
    },
  },
};

const commissonSpecialFirst: ActivityRulesMap = {
  "zh-CN": {
    DAILY_RECEIVE: "每日代理所领取的佣金满足活动要求，按对应阶梯奖励返奖;\n",
    WEEKLY_DAY_RECEIVE: "每周代理所领取的佣金满足活动要求，按对应阶梯奖励返奖\n",
    MONTHLY_DAY_RECEIVE: "每月代理所领取的佣金满足活动要求，按对应阶梯奖励返奖\n",

    DAILY_RECHARGE: "每日代理直属会员累计充值满足活动要求，按对应阶梯奖励返奖\n",
    WEEKLY_DAY_RECHARGE: "每周代理直属会员累计充值满足活动要求，按对应阶梯奖励返奖\n",
    MONTHLY_DAY_RECHARGE: "每月代理直属会员累计充值满足活动要求，按对应阶梯奖励返奖\n",

    DAILY_SETTLE: "每日代理所结算的佣金满足活动要求，按对应阶梯奖励返奖\n",
    WEEKLY_DAY_SETTLE: "每周代理所结算的佣金满足活动要求，按对应阶梯奖励返奖\n",
    MONTHLY_DAY_SETTLE: "每月代理所结算的佣金满足活动要求，按对应阶梯奖励返奖\n",
  },
  "en-US": {
    DAILY_RECEIVE: "The commissions received by agents daily meet the activity requirements and are rewarded according to the corresponding tier rewards",
    WEEKLY_DAY_RECEIVE: "The commissions received by agents weekly meet the activity requirements and are rewarded according to the corresponding tier rewards",
    MONTHLY_DAY_RECEIVE: "The commission received by the agent each month meets the activity requirements, and the corresponding tiered rewards will be returned as bonuses",

    DAILY_RECHARGE: "The daily cumulative recharge by the agent's direct members meets the activity requirements, and corresponding tiered rewards will be returned as bonuses",
    WEEKLY_DAY_RECHARGE: "The weekly cumulative recharge by the agent's direct members meets the activity requirements, and corresponding tiered rewards will be returned as bonuses",
    MONTHLY_DAY_RECHARGE: "The monthly cumulative recharge by the agent's direct members meets the activity requirements, and corresponding tiered rewards will be returned as bonuses",

    DAILY_SETTLE: "The commission settled by the agent each day meets the activity requirements, and corresponding tiered rewards will be returned as bonuses",
    WEEKLY_DAY_SETTLE: "The commission settled by the agent each week meets the activity requirements, and corresponding tiered rewards will be returned as bonuses",
    MONTHLY_DAY_SETTLE: "The commission settled by the agent each month meets the activity requirements, and corresponding tiered rewards will be returned as bonuses",
  },
  "pt-BR": {
    DAILY_RECEIVE: "As comissões recebidas pelos agentes diariamente atendem aos requisitos da atividade e são recompensadas de acordo com as recompensas do nível correspondente",
    WEEKLY_DAY_RECEIVE: "As comissões recebidas pelos agentes semanalmente atendem aos requisitos da atividade e são recompensadas de acordo com as recompensas do nível correspondente",
    MONTHLY_DAY_RECEIVE: "A comissão recebida pelo agente a cada mês atende aos requisitos da atividade, e as recompensas correspondentes serão retornadas como bônus",

    DAILY_RECHARGE: "O recarregamento acumulado diário dos membros diretos do agente atende aos requisitos da atividade, e as recompensas correspondentes em níveis serão retornadas como bônus",
    WEEKLY_DAY_RECHARGE: "O recarregamento acumulado semanal dos membros diretos do agente atende aos requisitos da atividade, e as recompensas correspondentes em níveis serão retornadas como bônus",
    MONTHLY_DAY_RECHARGE: "O recarregamento acumulado mensal dos membros diretos do agente atende aos requisitos da atividade, e as recompensas correspondentes em níveis serão retornadas como bônus",

    DAILY_SETTLE: "A comissão liquidada pelo agente a cada dia atende aos requisitos da atividade, e as recompensas correspondentes em níveis serão retornadas como bônus",
    WEEKLY_DAY_SETTLE: "A comissão liquidada pelo agente a cada semana atende aos requisitos da atividade, e as recompensas correspondentes em níveis serão retornadas como bônus",
    MONTHLY_DAY_SETTLE: "A comissão liquidada pelo agente a cada mês atende aos requisitos da atividade, e as recompensas correspondentes em níveis serão retornadas como bônus",
  },
  "id-ID": {
    DAILY_RECEIVE: "Komisi yang diterima oleh agen setiap hari memenuhi syarat kegiatan dan akan diberikan kembali sesuai dengan tingkat hadiah yang sesuai",
    WEEKLY_DAY_RECEIVE: "Komisi yang diterima oleh agen setiap minggu memenuhi syarat kegiatan dan akan diberikan kembali sesuai dengan tingkat hadiah yang sesuai",
    MONTHLY_DAY_RECEIVE: "Komisi yang diterima oleh agen setiap bulan memenuhi syarat aktivitas, dan hadiah bertingkat yang sesuai akan diberikan sebagai bonus",

    DAILY_RECHARGE: "Rekapitulasi pengisian ulang harian oleh anggota langsung agen memenuhi syarat aktivitas, dan hadiah bertingkat yang sesuai akan diberikan sebagai bonus",
    WEEKLY_DAY_RECHARGE: "Rekapitulasi pengisian ulang mingguan oleh anggota langsung agen memenuhi syarat aktivitas, dan hadiah bertingkat yang sesuai akan diberikan sebagai bonus",
    MONTHLY_DAY_RECHARGE: "Rekapitulasi pengisian ulang bulanan oleh anggota langsung agen memenuhi syarat aktivitas, dan hadiah bertingkat yang sesuai akan diberikan sebagai bonus",

    DAILY_SETTLE: "Komisi yang diselesaikan oleh agen setiap hari memenuhi syarat aktivitas, dan hadiah bertingkat yang sesuai akan diberikan sebagai bonus",
    WEEKLY_DAY_SETTLE: "Komisi yang diselesaikan oleh agen setiap minggu memenuhi syarat aktivitas, dan hadiah bertingkat yang sesuai akan diberikan sebagai bonus",
    MONTHLY_DAY_SETTLE: "Komisi yang diselesaikan oleh agen setiap bulan memenuhi syarat aktivitas, dan hadiah bertingkat yang sesuai akan diberikan sebagai bonus",
  },
  "hi-IN": {
    DAILY_RECEIVE: "प्रति दिन एजेंट द्वारा प्राप्त कमीशन गतिविधि आवश्यकताओं को पूरा करता है और संबंधित स्तर के पुरस्कार के अनुसार पुरस्कार दिया जाता है।",
    WEEKLY_DAY_RECEIVE: "प्रति सप्ताह एजेंट द्वारा प्राप्त कमीशन गतिविधि आवश्यकताओं को पूरा करता है और संबंधित स्तर के पुरस्कार के अनुसार पुरस्कार दिया जाता है।",
    MONTHLY_DAY_RECEIVE: "प्रति माह एजेंट द्वारा प्राप्त कमीशन गतिविधि की आवश्यकताओं को पूरा करता है, और संबंधित स्तर के पुरस्कारों के अनुसार बोनस लौटाए जाएंगे।",

    DAILY_RECHARGE: "प्रति दिन एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज गतिविधि की आवश्यकताओं को पूरा करते हैं, और संबंधित स्तर के पुरस्कारों के अनुसार बोनस लौटाए जाएंगे।",
    WEEKLY_DAY_RECHARGE: "प्रति सप्ताह एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज गतिविधि की आवश्यकताओं को पूरा करते हैं, और संबंधित स्तर के पुरस्कारों के अनुसार बोनस लौटाए जाएंगे।",
    MONTHLY_DAY_RECHARGE: "प्रति माह एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज गतिविधि की आवश्यकताओं को पूरा करते हैं, और संबंधित स्तर के पुरस्कारों के अनुसार बोनस लौटाए जाएंगे।",

    DAILY_SETTLE: "प्रति दिन एजेंट द्वारा निपटाई गई कमीशन गतिविधि की आवश्यकताओं को पूरा करती है, और संबंधित स्तर के पुरस्कारों के अनुसार बोनस लौटाए जाएंगे।",
    WEEKLY_DAY_SETTLE: "प्रति सप्ताह एजेंट द्वारा निपटाई गई कमीशन गतिविधि की आवश्यकताओं को पूरा करती है, और संबंधित स्तर के पुरस्कारों के अनुसार बोनस लौटाए जाएंगे।",
    MONTHLY_DAY_SETTLE: "प्रति माह एजेंट द्वारा निपटाई गई कमीशन गतिविधि की आवश्यकताओं को पूरा करती है, और संबंधित स्तर के पुरस्कारों के अनुसार बोनस लौटाए जाएंगे।",
  },
};

export const commissonSpecialSecond: ActivityRulesMap = {
  "zh-CN": {
    DAILY_RECEIVE_BALANCE: "返奖金额=昨日所领取的全部代理佣金，对应的奖励",
    DAILY_RECEIVE_RATE: "返奖金额=昨日所领取的全部代理佣金x佣金比例",
    WEEKLY_DAY_RECEIVE_BALANCE: "返奖金额=上周所领取的全部代理佣金，对应的奖励",
    WEEKLY_DAY_RECEIVE_RATE: "返奖金额=上周所领取的全部代理佣金x佣金比例",
    MONTHLY_DAY_RECEIVE_BALANCE: "返奖金额=上月所领取的全部代理佣金，对应的奖励",
    MONTHLY_DAY_RECEIVE_RATE: "返奖金额=上月所领取的全部代理佣金x佣金比例",

    DAILY_RECHARGE_BALANCE: "返奖金额=昨日代理直属会员累计充值，对应的奖励",
    DAILY_RECHARGE_RATE: "返奖金额=昨日代理直属会员累计充值x佣金比例",
    WEEKLY_DAY_RECHARGE_BALANCE: "返奖金额=上周代理直属会员累计充值，对应的奖励",
    WEEKLY_DAY_RECHARGE_RATE: "返奖金额=上周代理直属会员累计充值x佣金比例",
    MONTHLY_DAY_RECHARGE_BALANCE: "返奖金额=上月代理直属会员累计充值，对应的奖励",
    MONTHLY_DAY_RECHARGE_RATE: "返奖金额=上月代理直属会员累计充值x佣金比例",

    DAILY_SETTLE_BALANCE: "返奖金额=昨日所结算的全部代理佣金，对应的奖励",
    DAILY_SETTLE_RATE: "返奖金额=昨日所结算的全部代理佣金x佣金比例",
    WEEKLY_DAY_SETTLE_BALANCE: "返奖金额=上周所结算的全部代理佣金，对应的奖励",
    WEEKLY_DAY_SETTLE_RATE: "返奖金额=上周所结算的全部代理佣金x佣金比例",
    MONTHLY_DAY_SETTLE_BALANCE: "返奖金额=上月所结算的全部代理佣金，对应的奖励",
    MONTHLY_DAY_SETTLE_RATE: "返奖金额=上月所结算的全部代理佣金x佣金比例",
  },
  "en-US": {
    DAILY_RECEIVE_BALANCE: "Refund Amount = Total Commission Received by Agents Yesterday, Corresponding Rewards",
    DAILY_RECEIVE_RATE: "Refund Amount = Total Commission Received by Agents Yesterday × Commission Rate",
    WEEKLY_DAY_RECEIVE_BALANCE: "Refund Amount = Total Commission Received by Agents Last Week, Corresponding Rewards",
    WEEKLY_DAY_RECEIVE_RATE: "Refund Amount = Total Commission Received by Agents Last Week × Commission Rate",
    MONTHLY_DAY_RECEIVE_BALANCE: "Bonus amount = Total commission received by the agent last month, corresponding to the rewards",
    MONTHLY_DAY_RECEIVE_RATE: "Bonus amount = Total commission received by the agent last month × Commission rate",

    DAILY_RECHARGE_BALANCE: "Bonus amount = Cumulative recharge of direct members by the agent yesterday, corresponding to the rewards",
    DAILY_RECHARGE_RATE: "Bonus amount = Cumulative recharge of direct members by the agent yesterday × Commission rate",
    WEEKLY_DAY_RECHARGE_BALANCE: "Bonus amount = Cumulative recharge of direct members by the agent last week, corresponding to the rewards",
    WEEKLY_DAY_RECHARGE_RATE: "Bonus amount = Cumulative recharge of direct members by the agent last week × Commission rate",
    MONTHLY_DAY_RECHARGE_BALANCE: "Bonus amount = Cumulative recharge of direct members by the agent last month, corresponding to the rewards",
    MONTHLY_DAY_RECHARGE_RATE: "Bonus amount = Cumulative recharge of direct members by the agent last month × Commission rate",

    DAILY_SETTLE_BALANCE: "Bonus amount = Total commission settled yesterday by the agent, corresponding to the rewards",
    DAILY_SETTLE_RATE: "Bonus amount = Total commission settled by the agent yesterday × Commission rate",
    WEEKLY_DAY_SETTLE_BALANCE: "Reward amount = Total agent commissions settled last week, corresponding rewards",
    WEEKLY_DAY_SETTLE_RATE: "Reward amount = Total agent commissions settled last week × Commission rate",
    MONTHLY_DAY_SETTLE_BALANCE: "Reward amount = Total agent commissions settled last month, corresponding rewards",
    MONTHLY_DAY_SETTLE_RATE: "Reward amount = Total agent commissions settled last month × Commission rate",
  },
  "pt-BR": {
    DAILY_RECEIVE_BALANCE: "Valor do Reembolso = Total de Comissões Recebidas pelos Agentes Ontem, Recompensas Correspondentes",
    DAILY_RECEIVE_RATE: "Valor do Reembolso = Total de Comissões Recebidas pelos Agentes Ontem × Taxa de Comissão",
    WEEKLY_DAY_RECEIVE_BALANCE: "Valor do Reembolso = Total de Comissões Recebidas pelos Agentes na Última Semana, Recompensas Correspondentes",
    WEEKLY_DAY_RECEIVE_RATE: "Valor do Reembolso = Total de Comissões Recebidas pelos Agentes na Última Semana × Taxa de Comissão",
    MONTHLY_DAY_RECEIVE_BALANCE: "Valor do bônus = Total da comissão recebida pelo agente no mês passado, correspondente às recompensas",
    MONTHLY_DAY_RECEIVE_RATE: "Valor do bônus = Total da comissão recebida pelo agente no mês passado × Taxa de comissão",

    DAILY_RECHARGE_BALANCE: "Valor do bônus = Recarregamento acumulado dos membros diretos do agente ontem, correspondente às recompensas",
    DAILY_RECHARGE_RATE: "Valor do bônus = Recarregamento acumulado dos membros diretos do agente ontem × Taxa de comissão",
    WEEKLY_DAY_RECHARGE_BALANCE: "Valor do bônus = Recarregamento acumulado dos membros diretos do agente na semana passada, correspondente às recompensas",
    WEEKLY_DAY_RECHARGE_RATE: "Valor do bônus = Recarregamento acumulado dos membros diretos do agente na semana passada × Taxa de comissão",
    MONTHLY_DAY_RECHARGE_BALANCE: "Valor do bônus = Recarregamento acumulado dos membros diretos do agente no mês passado, correspondente às recompensas",
    MONTHLY_DAY_RECHARGE_RATE: "Valor do bônus = Recarregamento acumulado dos membros diretos do agente no mês passado × Taxa de comissão",

    DAILY_SETTLE_BALANCE: "Valor do bônus = Total da comissão liquidada pelo agente ontem, correspondente às recompensas",
    DAILY_SETTLE_RATE: "Valor do bônus = Total da comissão liquidada pelo agente ontem × Taxa de comissão",
    WEEKLY_DAY_SETTLE_BALANCE: "Valor da recompensa = Total das comissões de agentes liquidadas na semana passada, recompensas correspondentes",
    WEEKLY_DAY_SETTLE_RATE: "Valor da recompensa = Total das comissões de agentes liquidadas na semana passada × Taxa de comissão",
    MONTHLY_DAY_SETTLE_BALANCE: "Valor da recompensa = Total das comissões de agentes liquidadas no mês passado, recompensas correspondentes",
    MONTHLY_DAY_SETTLE_RATE: "Valor da recompensa = Total das comissões de agentes liquidadas no mês passado × Taxa de comissão",
  },
  "id-ID": {
    DAILY_RECEIVE_BALANCE: "Jumlah Pengembalian = Total Komisi yang Diterima oleh Agen Kemarin, Hadiah yang Sesuai",
    DAILY_RECEIVE_RATE: "Jumlah Pengembalian = Total Komisi yang Diterima oleh Agen Kemarin × Persentase Komisi",
    WEEKLY_DAY_RECEIVE_BALANCE: "Jumlah Pengembalian = Total Komisi yang Diterima oleh Agen Minggu Lalu, Hadiah yang Sesuai",
    WEEKLY_DAY_RECEIVE_RATE: "Jumlah Pengembalian = Total Komisi yang Diterima oleh Agen Minggu Lalu × Persentase Komisi",
    MONTHLY_DAY_RECEIVE_BALANCE: "Jumlah bonus = Total komisi yang diterima oleh agen bulan lalu, sesuai dengan hadiah",
    MONTHLY_DAY_RECEIVE_RATE: "Jumlah bonus = Total komisi yang diterima oleh agen bulan lalu × Persentase komisi",

    DAILY_RECHARGE_BALANCE: "Jumlah bonus = Pengisian ulang kumulatif anggota langsung agen kemarin, sesuai dengan hadiah",
    DAILY_RECHARGE_RATE: "Jumlah bonus = Pengisian ulang kumulatif anggota langsung agen kemarin × Persentase komisi",
    WEEKLY_DAY_RECHARGE_BALANCE: "Jumlah bonus = Pengisian ulang kumulatif anggota langsung agen minggu lalu, sesuai dengan hadiah",
    WEEKLY_DAY_RECHARGE_RATE: "Jumlah bonus = Pengisian ulang kumulatif anggota langsung agen minggu lalu × Persentase komisi",
    MONTHLY_DAY_RECHARGE_BALANCE: "Jumlah bonus = Pengisian ulang kumulatif anggota langsung agen bulan lalu, sesuai dengan hadiah",
    MONTHLY_DAY_RECHARGE_RATE: "Jumlah bonus = Pengisian ulang kumulatif anggota langsung agen bulan lalu × Persentase komisi",

    DAILY_SETTLE_BALANCE: "Jumlah bonus = Total komisi yang diselesaikan oleh agen kemarin, sesuai dengan hadiah",
    DAILY_SETTLE_RATE: "Jumlah bonus = Total komisi yang diselesaikan oleh agen kemarin × Persentase komisi",
    WEEKLY_DAY_SETTLE_BALANCE: "Jumlah hadiah = Total komisi agen yang diselesaikan minggu lalu, hadiah yang sesuai",
    WEEKLY_DAY_SETTLE_RATE: "Jumlah hadiah = Total komisi agen yang diselesaikan minggu lalu × Persentase komisi",
    MONTHLY_DAY_SETTLE_BALANCE: "Jumlah hadiah = Total komisi agen yang diselesaikan bulan lalu, hadiah yang sesuai",
    MONTHLY_DAY_SETTLE_RATE: "Jumlah hadiah = Total komisi agen yang diselesaikan bulan lalu × Persentase komisi",
  },
  "hi-IN": {
    DAILY_RECEIVE_BALANCE: "रिफंड राशि = कल एजेंटों द्वारा प्राप्त कुल कमीशन, संबंधित पुरस्कार।",
    DAILY_RECEIVE_RATE: "रिफंड राशि = कल एजेंटों द्वारा प्राप्त कुल कमीशन × कमीशन दर।",
    WEEKLY_DAY_RECEIVE_BALANCE: "रिफंड राशि = पिछले सप्ताह एजेंटों द्वारा प्राप्त कुल कमीशन, संबंधित पुरस्कार।",
    WEEKLY_DAY_RECEIVE_RATE: "रिफंड राशि = पिछले सप्ताह एजेंटों द्वारा प्राप्त कुल कमीशन × कमीशन दर।",
    MONTHLY_DAY_RECEIVE_BALANCE: "बोनस राशि = पिछले महीने एजेंट द्वारा प्राप्त कुल कमीशन, संबंधित पुरस्कारों के अनुसार।",
    MONTHLY_DAY_RECEIVE_RATE: "बोनस राशि = पिछले महीने एजेंट द्वारा प्राप्त कुल कमीशन × कमीशन दर।",

    DAILY_RECHARGE_BALANCE: "बोनस राशि = कल एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज, संबंधित पुरस्कारों के अनुसार।",
    DAILY_RECHARGE_RATE: "बोनस राशि = कल एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज × कमीशन दर।",
    WEEKLY_DAY_RECHARGE_BALANCE: "बोनस राशि = पिछले सप्ताह एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज, संबंधित पुरस्कारों के अनुसार।",
    WEEKLY_DAY_RECHARGE_RATE: "बोनस राशि = पिछले सप्ताह एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज × कमीशन दर।",
    MONTHLY_DAY_RECHARGE_BALANCE: "बोनस राशि = पिछले महीने एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज, संबंधित पुरस्कारों के अनुसार।",
    MONTHLY_DAY_RECHARGE_RATE: "बोनस राशि = पिछले महीने एजेंट के प्रत्यक्ष सदस्यों द्वारा किए गए कुल रिचार्ज × कमीशन दर।",

    DAILY_SETTLE_BALANCE: "बोनस राशि = कल एजेंट द्वारा निपटाई गई कुल कमीशन, संबंधित पुरस्कारों के अनुसार।",
    DAILY_SETTLE_RATE: "बोनस राशि = कल एजेंट द्वारा प्राप्त कुल कमीशन × कमीशन दर।",
    WEEKLY_DAY_SETTLE_BALANCE: "इनाम राशि = पिछले सप्ताह में निपटाए गए सभी एजेंट कमीशन, संबंधित इनाम।",
    WEEKLY_DAY_SETTLE_RATE: "इनाम राशि = पिछले सप्ताह में निपटाए गए सभी एजेंट कमीशन × कमीशन दर।",
    MONTHLY_DAY_SETTLE_BALANCE: "इनाम राशि = पिछले महीने निपटाए गए सभी एजेंट कमीशन, संबंधित इनाम।",
    MONTHLY_DAY_SETTLE_RATE: "इनाम राशि = पिछले महीने निपटाए गए सभी एजेंट कमीशन × कमीशन दर।",
  },
};

// 佣金
const commissionRewardTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `{commissonSpecialFirst}\n`,
    2: `计算公式\n{commissonSpecialSecond}\n`,
    3: `奖励仅限在{platforms}端手动领取，过期未领取奖励作废;\n`,
  },
  "en-US": {
    1: `{commissonSpecialFirst}\n`,
    2: `Calculation formula\n{commissonSpecialSecond}\n`,
    3: `Rewards can only be claimed manually on {platforms} platforms. Unclaimed rewards will be forfeited upon expiration;\n`,
  },
  "pt-BR": {
    1: `{commissonSpecialFirst}\n`,
    2: `Fórmula de cálculo\n{commissonSpecialSecond}\n`,
    3: `As recompensas só podem ser reivindicadas manualmente nas plataformas {platforms}. Recompensas não reivindicadas serão perdidas após o vencimento;\n`,
  },
  "id-ID": {
    1: `{commissonSpecialFirst}`,
    2: `Rumus perhitungan\n{commissonSpecialSecond}\n`,
    3: `Hadiah hanya dapat diklaim secara manual di platform {platforms}. Hadiah yang tidak diklaim akan hangus setelah kadaluarsa;\n`,
  },
  "hi-IN": {
    1: `{commissonSpecialFirst}\n`,
    2: `गणना सूत्र\n{commissonSpecialSecond}\n`,
    3: `पुरस्कार केवल {platforms} प्लेटफॉर्म पर मैन्युअल रूप से दावा किए जा सकते हैं। समय सीमा समाप्त होने पर दावा न किए गए पुरस्कार जब्त कर लिए जाएंगे;\n`,
  },
};

const cpfInviteTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `邀请好友进入游戏，且被邀请人在平台上完成注册、充值、投注、打码等，即可获得:{childRule}\n`,
    2: `奖励仅限在{platforms}端手动领取，过期未领取奖励作废;\n`,
  },
  "en-US": {
    1: `Invite friends to join the game, and if the invited friend completes registration, deposits, bets, or wagering on the platform, you will receive:{childRule}\n`,
    2: `Rewards can only be claimed manually on {platforms} platforms. Unclaimed rewards will be forfeited upon expiration;\n`,
  },
  "pt-BR": {
    1: `Convide amigos para participar do jogo, e se o amigo convidado completar registro, depósitos, apostas ou codificação na plataforma, você receberá:{childRule}\n`,
    2: `As recompensas só podem ser reivindicadas manualmente nas plataformas {platforms}. Recompensas não reivindicadas serão perdidas após o vencimento;\n`,
  },
  "id-ID": {
    1: `Undang teman untuk bergabung dalam permainan, dan jika teman yang diundang menyelesaikan pendaftaran, deposit, taruhan, atau pemenuhan syarat pada platform, Anda akan menerima:{childRule}\n`,
    2: `Hadiah hanya dapat diklaim secara manual di platform {platforms}. Hadiah yang tidak diklaim akan hangus setelah kadaluarsa;\n`,
  },
  "hi-IN": {
    1: `मित्रों को खेल में आमंत्रित करें, और यदि आमंत्रित मित्र प्लेटफ़ॉर्म पर पंजीकरण, जमा, शर्त लगाना, या कोडिंग पूरा करते हैं, तो आपको प्राप्त होगा:{childRule}\n`,
    2: `पुरस्कार केवल {platforms} प्लेटफॉर्म पर मैन्युअल रूप से दावा किए जा सकते हैं। समय सीमा समाप्त होने पर दावा न किए गए पुरस्कार जब्त कर लिए जाएंगे;\n`,
  },
};

const cpfInviteChildTemplate: ActivityRulesMap = {
  "zh-CN": {
    DIRECT_RECHARGE_TOTAL_FIXED: `被邀请人累计存款<span style="color: #00BB48">{target}</span>, 获得<span style="color: #FFAA00">{maxAmount}</span>返佣奖金`,
    DIRECT_BET_TOTAL_FIXED: `被邀请人累计有效投注<span style="color: #00BB48">{target}</span>, 获得<span style="color: #FFAA00">{maxAmount}</span>返佣奖金`,
    EACH_RECHARGE_FIXED_RATIO: `被邀请人每次充值<span style="color: #00BB48">≥{target}</span>, 获得<span style="color: #FFAA00">{maxAmount}</span>返佣奖金`,
    REGISTRATION_BONUS_RANDOM: `被邀请人注册成功即可获得<span style="color: #FFAA00">{maxAmount}</span>返佣奖金`,
    REGISTRATION_BONUS_FIXED: `被邀请人注册成功即可获得<span style="color: #FFAA00">{maxAmount}</span>返佣奖金`,
  },
  "en-US": {
    DIRECT_RECHARGE_TOTAL_FIXED: `When the invited friend’s total deposits reach <span style="color: #00BB48">{target}</span>, you will receive a commission bonus of <span style="color: #FFAA00">{maxAmount}</span>.`,
    DIRECT_BET_TOTAL_FIXED: `When the invited friend’s total valid bets reach <span style="color: #00BB48">{target}</span>, you will receive a commission bonus of <span style="color: #FFAA00">{maxAmount}</span>.`,
    EACH_RECHARGE_FIXED_RATIO: `For each deposit by the invited friend <span style="color: #00BB48">≥{target}</span>, you will receive a commission bonus of <span style="color: #FFAA00">{maxAmount}</span>.`,
    REGISTRATION_BONUS_RANDOM: `Upon successful registration by the invited friend, you will receive a commission bonus of <span style="color: #FFAA00">{maxAmount}</span>.`,
    REGISTRATION_BONUS_FIXED: `Upon successful registration by the invited friend, you will receive a commission bonus of <span style="color: #FFAA00">{maxAmount}</span>.`,
  },
  "pt-BR": {
    DIRECT_RECHARGE_TOTAL_FIXED: `Bônus por Depósito Acumulado: Quando os depósitos totais do amigo convidado atingirem <span style="color: #00BB48">{target}</span>, você receberá um bônus de comissão de <span style="color: #FFAA00">{maxAmount}</span>.`,
    DIRECT_BET_TOTAL_FIXED: `Bônus por Apostas Válidas Acumuladas: Quando as apostas válidas totais do amigo convidado atingirem <span style="color: #00BB48">{target}</span>, você receberá um bônus de comissão de <span style="color: #FFAA00">{maxAmount}</span>.`,
    EACH_RECHARGE_FIXED_RATIO: `Bônus por Cada Depósito: Para cada depósito do amigo convidado <span style="color: #00BB48">≥{target}</span>, você receberá um bônus de comissão de <span style="color: #FFAA00">{maxAmount}</span>.`,
    REGISTRATION_BONUS_RANDOM: `Bônus por Registro Bem-Sucedido: Após o registro bem-sucedido do amigo convidado, você receberá um bônus de comissão de <span style="color: #FFAA00">{maxAmount}</span>.`,
    REGISTRATION_BONUS_FIXED: `Bônus por Registro Bem-Sucedido: Após o registro bem-sucedido do amigo convidado, você receberá um bônus de comissão de <span style="color: #FFAA00">{maxAmount}</span>.`,
  },
  "id-ID": {
    DIRECT_RECHARGE_TOTAL_FIXED: `Ketika total deposit teman yang diundang mencapai <span style="color: #00BB48">{target}</span>, Anda akan menerima bonus komisi sebesar <span style="color: #FFAA00">{maxAmount}</span>.`,
    DIRECT_BET_TOTAL_FIXED: `Ketika total taruhan valid teman yang diundang mencapai <span style="color: #00BB48">{target}</span>, Anda akan menerima bonus komisi sebesar <span style="color: #FFAA00">{maxAmount}</span>.`,
    EACH_RECHARGE_FIXED_RATIO: `Untuk setiap deposit teman yang diundang <span style="color: #00BB48">≥{target}</span>, Anda akan menerima bonus komisi sebesar <span style="color: #FFAA00">{maxAmount}</span>.`,
    REGISTRATION_BONUS_RANDOM: `Setelah teman yang diundang berhasil mendaftar, Anda akan menerima bonus komisi sebesar <span style="color: #FFAA00">{maxAmount}</span>.`,
    REGISTRATION_BONUS_FIXED: `Setelah teman yang diundang berhasil mendaftar, Anda akan menerima bonus komisi sebesar <span style="color: #FFAA00">{maxAmount}</span>.`,
  },
  "hi-IN": {
    DIRECT_RECHARGE_TOTAL_FIXED: `कुल जमा बोनस: जब आमंत्रित मित्र की कुल जमा राशि <span style="color: #00BB48">{target}</span> तक पहुँचती है, तो आपको <span style="color: #FFAA00">{maxAmount}</span> का कमीशन बोनस मिलेगा।`,
    DIRECT_BET_TOTAL_FIXED: `कुल वैध दांव बोनस: जब आमंत्रित मित्र की कुल वैध दांव राशि <span style="color: #00BB48">{target}</span>तक पहुँचती है, तो आपको <span style="color: #FFAA00">{maxAmount}</span> का कमीशन बोनस मिलेगा।`,
    EACH_RECHARGE_FIXED_RATIO: `प्रत्येक जमा पर बोनस: आमंत्रित मित्र के प्रत्येक जमा<span style="color: #00BB48">≥{target}</span> के लिए, आपको <span style="color: #FFAA00">{maxAmount}</span> का कमीशन बोनस मिलेगा।`,
    REGISTRATION_BONUS_RANDOM: `पंजीकरण बोनस: आमंत्रित मित्र के सफल पंजीकरण के बाद, आपको <span style="color: #FFAA00">{maxAmount}</span> का कमीशन बोनस मिलेगा।`,
    REGISTRATION_BONUS_FIXED: `पंजीकरण बोनस: आमंत्रित मित्र के सफल पंजीकरण के बाद, आपको <span style="color: #FFAA00">{maxAmount}</span> का कमीशन बोनस मिलेगा।`,
  },
}

// 新人任务第一、二条
const NewbieTaskFirstSecondTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `每个新注册的账号都可以完成以上任务，完成任务后可获得一定金额奖金，难度越高，奖励越多;`,
    2: `满足条件即可直接领取，可在{applyAppType}任意一端直接领取，过期作废（即未主动领取视为自愿放弃);`,
  },
  "en-US": {
    1: `Each newly registered account can complete the above tasks and earn a certain amount of bonus upon completion. The higher the difficulty, the greater the reward;`,
    2: `You can claim the reward directly once the conditions are met, and it can be claimed on either side of {applyAppType}. It will expire if not claimed (i.e., if not claimed proactively, it is considered voluntarily forfeited);`,
  },
  "pt-BR": {
    1: `Cada nova conta registrada pode concluir as tarefas acima, e ao completar as tarefas, o usuário receberá uma recompensa em dinheiro. Quanto maior a dificuldade, maior a recompensa;`,
    2: `Você pode resgatar diretamente assim que atender aos requisitos, e pode fazer isso em qualquer plataforma de {applyAppType}. O prêmio expira se não for resgatado (ou seja, não resgatar proativamente será considerado como desistência voluntária);`,
  },
  "id-ID": {
    1: `Setiap akun yang baru terdaftar dapat menyelesaikan tugas-tugas di atas, dan setelah menyelesaikan tugas, pengguna akan mendapatkan sejumlah bonus uang. Semakin tinggi tingkat kesulitan, semakin besar hadiahnya;`,
    2: `Anda dapat langsung mengklaim hadiah begitu memenuhi syarat, dan dapat mengklaimnya di mana saja di {applyAppType}. Hadiah akan kedaluwarsa jika tidak diklaim (yaitu, jika tidak diklaim secara proaktif, dianggap sebagai pengunduran diri sukarela);`,
  },
  "hi-IN": {
    1: `हर नया पंजीकृत खाता ऊपर दिए गए कार्यों को पूरा कर सकता है, और कार्य पूरा करने के बाद उसे एक निश्चित राशि का बोनस मिलेगा। जितनी अधिक कठिनाई होगी, पुरस्कार उतना ही अधिक होगा।;`,
    2: `आप शर्तें पूरी करते ही सीधे पुरस्कार प्राप्त कर सकते हैं, और इसे {applyAppType} के किसी भी प्लेटफॉर्म पर सीधे प्राप्त किया जा सकता है। यदि समय सीमा समाप्त हो जाती है तो पुरस्कार अमान्य हो जाएगा (यानी यदि पुरस्कार को सक्रिय रूप से प्राप्त नहीं किया जाता है, तो इसे स्वेच्छा से त्यागना माना जाएगा)।;`,
  },
};

// 充值大酬宾规则
const RechargePromotionTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `活动当天，存入指定金额，即可获得大额奖金;`,
    2: `所有玩家每天只能参加一次，不可重复参加;`,
    3: `促销活动每{activityDay}开始;`,
    4: `请进入“大额存款奖励”板块，选择您想参与的奖励等级下的“存款”，进行存款即可获得大额奖金;`,
  },
  "en-US": {
    1: `On the day of the event, deposit the specified amount to get a large bonus;`,
    2: `All players can only participate once a day and cannot participate repeatedly;`,
    3: `Promotion starts every {activityDay};`,
    4: `Please go to the "Big Deposit Bonus" section, select "Deposit" under the bonus level you want to participate in, and make a deposit to get a big bonus;`,
  },
  "pt-BR": {
    1: `No dia do evento, deposite o valor especificado para obter um grande bônus;`,
    2: `Todos os jogadores só podem participar uma vez por dia e não podem participar repetidamente;`,
    3: `A promoção começa toda {activityDay};`,
    4: `Vá para a seção "Bônus de Grande Depósito", selecione "Depósito" no nível de bônus em que deseja participar e faça um depósito para obter um grande bônus;`,
  },
  "id-ID": {
    1: `Pada hari acara, setorkan jumlah yang ditentukan untuk mendapatkan bonus besar;`,
    2: `Semua pemain hanya dapat berpartisipasi sekali sehari dan tidak dapat berpartisipasi berulang kali;`,
    3: `Promosi dimulai setiap hari {activityDay};`,
    4: `Silakan masuk ke bagian "Bonus Deposit Besar", pilih "Deposit" di bawah level bonus yang ingin Anda ikuti, dan lakukan deposit untuk mendapatkan bonus besar;`,
  },
  "hi-IN": {
    1: `इवेंट के दिन, बड़ा बोनस पाने के लिए निर्दिष्ट राशि जमा करें;`,
    2: `सभी खिलाड़ी दिन में केवल एक बार भाग ले सकते हैं और बार-बार भाग नहीं ले सकते;`,
    3: `प्रमोशन हर {activityDay} से शुरू होता है;`,
    4: `कृपया "बड़ा जमा बोनस" अनुभाग पर जाएं, उस बोनस स्तर के अंतर्गत "जमा" चुनें जिसमें आप भाग लेना चाहते हैं, और बड़ा बोनस प्राप्त करने के लिए जमा करें;`,
  },
};

// 充值大酬宾活动日
const RechargePromotionActivityDayTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `星期一`,
    2: `星期二`,
    3: `星期三`,
    4: `星期四`,
    5: `星期五`,
    6: `星期六`,
    7: `星期日`,
  },
  "en-US": {
    1: `Monday`,
    2: `Tuesday`,
    3: `Wednesday`,
    4: `Thursday`,
    5: `Friday`,
    6: `Saturday`,
    7: `Sunday`,
  },
  "pt-BR": {
    1: `Segunda-feira`,
    2: `Terça-feira `,
    3: `Quarta-feira`,
    4: `Quinta-feira`,
    5: `Sexta-feira`,
    6: `Sábado`,
    7: `Domingo`,
  },
  "id-ID": {
    1: `Senin`,
    2: `Selasa`,
    3: `Rabu`,
    4: `Kamis`,
    5: `Jumat`,
    6: `Sabtu`,
    7: `Minggu`,
  },
  "hi-IN": {
    1: `सोमवार`,
    2: `मंगलवार`,
    3: `बुधवार`,
    4: `गुरुवार`,
    5: `शुक्रवार`,
    6: `शनिवार`,
    7: `रविवार`,
  },
};

//幸运注单 第一条
const LuckyBetTemplates_first: ActivityRulesMap = {
  "zh-CN": {
    TAIL_NUMBER: `会员每日游戏单笔注单金额大于等于{validBetAmount}，且该笔注单的后8位数字中，尾号包含活动规定号码，即可获得对应奖金；\n`,
    CONSECUTIVE_NUMBER: `会员每日游戏单笔注单金额大于等于{validBetAmount}，且该笔注单的后8位数字中，任意位置包含活动规定号码，即可获得对应奖金；\n`,
    CONTAINS_ANY_POSITION: `会员每日游戏单笔注单金额大于等于{validBetAmount}，且该笔注单的后8位数字中，任意位置包含活动规定号码，即可获得对应奖金；\n`,
  },
  "en-US": {
    TAIL_NUMBER: `If the member's daily game single bet amount is greater than or equal to {validBetAmount}, and the last 8 digits of the bet are the numbers specified in the event, the member can get the corresponding bonus;\n`,
    CONSECUTIVE_NUMBER: `Members can get the corresponding bonus if the amount of a single bet on the daily game is greater than or equal to {validBetAmount}, and any position in the last 8 digits of the bet contains the number specified in the event;\n`,
    CONTAINS_ANY_POSITION: `Members can get the corresponding bonus if the amount of a single bet on the daily game is greater than or equal to {validBetAmount}, and any position in the last 8 digits of the bet contains the number specified in the event;\n`,
  },
  "pt-BR": {
    TAIL_NUMBER: `Se o valor da aposta única diária do membro for maior ou igual a {validBetAmount}, e os últimos 8 dígitos da aposta forem os números especificados no evento, o membro poderá obter o bônus correspondente;\n`,
    CONSECUTIVE_NUMBER: `Os membros que fizerem uma aposta única de {validBetAmount} ou mais em seus jogos diários e cujos últimos 8 dígitos contenham o número especificado na atividade em qualquer posição podem receber o bônus correspondente;\n`,
    CONTAINS_ANY_POSITION: `Os membros que fizerem uma aposta única de {validBetAmount} ou mais em seus jogos diários e cujos últimos 8 dígitos contenham o número especificado na atividade em qualquer posição podem receber o bônus correspondente;\n`,
  },
  "id-ID": {
    TAIL_NUMBER: `Jika jumlah taruhan tunggal harian anggota lebih besar dari atau sama dengan {validBetAmount}, dan 8 digit terakhir taruhan adalah angka yang ditentukan dalam acara tersebut, anggota bisa mendapatkan bonus yang sesuai;\n`,
    CONSECUTIVE_NUMBER: `Anggota yang memasang jumlah taruhan tunggal sebesar {validBetAmount} atau lebih pada permainan hariannya dan 8 digit terakhirnya berisi nomor yang ditentukan aktivitas pada posisi mana pun dapat menerima bonus yang sesuai;\n`,
    CONTAINS_ANY_POSITION: `Anggota yang memasang jumlah taruhan tunggal sebesar {validBetAmount} atau lebih pada permainan hariannya dan 8 digit terakhirnya berisi nomor yang ditentukan aktivitas pada posisi mana pun dapat menerima bonus yang sesuai;\n`,
  },
  "hi-IN": {
    TAIL_NUMBER: `यदि सदस्य की दैनिक एकल शर्त राशि {validBetAmount} से अधिक या उसके बराबर है, और शर्त के अंतिम 8 अंक घटना में निर्दिष्ट संख्याएं हैं, तो सदस्य संबंधित बोनस प्राप्त कर सकता है;\n`,
    CONSECUTIVE_NUMBER: `जो सदस्य अपने दैनिक खेलों पर {validBetAmount} या उससे अधिक की एकल शर्त राशि लगाते हैं और जिनके अंतिम 8 अंकों में किसी भी स्थिति में गतिविधि-निर्दिष्ट संख्या होती है, वे संबंधित बोनस प्राप्त कर सकते हैं;\n`,
    CONTAINS_ANY_POSITION: `जो सदस्य अपने दैनिक खेलों पर {validBetAmount} या उससे अधिक की एकल शर्त राशि लगाते हैं और जिनके अंतिम 8 अंकों में किसी भी स्थिति में गतिविधि-निर्दिष्ट संख्या होती है, वे संबंधित बोनस प्राप्त कर सकते हैं;\n`,
  },
};

//幸运注单 第二条
const LuckyBetTemplates_second: ActivityRulesMap = {
  "zh-CN": {
    ALL: `所有平台投注均有效，奖励预计10分钟后更新，请等待奖励发放;\n`,
    SELECT: `以下平台投注才算有效，包含：{limitLuckyBetData}，奖励预计10分钟后更新，请等待奖励发放;\n`
  },
  "en-US": {
    ALL: `All platform bets are valid, and the reward is expected to be updated in 10 minutes, please wait for the reward to be issued;\n`,
    SELECT: `The following platforms are considered valid for bets, including: {limitLuckyBetData}, and the reward is expected to be updated in 10 minutes, please wait for the reward to be issued;\n`
  },
  "pt-BR": { 
    ALL: `Todas as apostas da plataforma são válidas, e as recompensas devem ser atualizadas em 10 minutos. Aguarde a emissão das recompensas;\n`,
    SELECT: `As seguintes plataformas são consideradas válidas para apostas, incluindo: {limitLuckyBetData}, a recompensa deve ser atualizada em 10 minutos, aguarde a emissão da recompensa;\n`
  },
  "id-ID": {
    ALL: `Semua taruhan platform berlaku, dan hadiah diharapkan akan diperbarui dalam 10 menit. Harap tunggu hingga hadiah diberikan;\n`,
    SELECT: `Platform berikut dianggap valid untuk taruhan, termasuk: {limitLuckyBetData}, hadiah diharapkan diperbarui dalam 10 menit, harap tunggu hadiah dikeluarkan;\n`
  },
  "hi-IN": {
    ALL: `सभी प्लेटफ़ॉर्म बेट्स वैध हैं, और पुरस्कार 10 मिनट में अपडेट होने की उम्मीद है। कृपया पुरस्कार जारी होने तक प्रतीक्षा करें;\n`,
    SELECT: `निम्नलिखित प्लेटफार्मों को सट्टेबाजी के लिए वैध माना जाता है, जिनमें शामिल हैं: {limitLuckyBetData}, इनाम 10 मिनट में अपडेट होने की उम्मीद है, कृपया इनाम जारी होने की प्रतीक्षा करें;\n`
  },
};

//幸运注单 第三条
const LuckyBetTemplates_Three: ActivityRulesMap_Rebate = {
  "zh-CN": {
    FIXED_COUNT: '每日领取次数固定{dayLimit}次，活动期间总领取次数{receiveLimit}次；',
    RECHARGE: '每日领取次数根据当日充值获得，充值越多领取次数越多，活动期间总领取次数{receiveLimit}次；',
    BET: '每日领取次数根据当日投注获得，投注越多领取次数越多，活动期间总领取次数{receiveLimit}次；',
  },
  "en-US": {
    FIXED_COUNT: 'The number of daily collections is fixed at {dayLimit} times, and the total number of collections during the event is {receiveLimit} times;',
    RECHARGE: 'The number of daily collections is obtained based on the recharge of the day. The more recharges, the more collections, and the total number of collections during the event is {receiveLimit} times;',
    BET: 'The number of daily collections is obtained based on the bets of the day. The more bets, the more collections, and the total number of collections during the event is {receiveLimit} times;',
  },
  "pt-BR": {
    FIXED_COUNT: 'O número de vezes de resgate por dia é fixado em {dayLimit} vezes, e o número total de vezes de resgate durante o evento é {receiveLimit} vezes;',
    RECHARGE: 'O número de resgates por dia é baseado na recarga naquele dia. Quanto mais você recarrega, mais você pode receber. O número total de resgates durante o evento é {receiveLimit} vezes;',
    BET: 'O número de resgates por dia é baseado nas apostas feitas naquele dia. Quanto mais apostas feitas, mais resgates você pode receber. O número total de resgates durante o evento é {receiveLimit} vezes;',
  },
  "id-ID": {
    FIXED_COUNT: 'Jumlah kali penukaran tiap harinya ditetapkan sebanyak {dayLimit} kali, dan jumlah total kali penukaran selama event berlangsung adalah {receiveLimit} kali;',
    RECHARGE: 'Jumlah penukaran per hari didasarkan pada isi ulang pada hari itu. Semakin banyak Anda mengisi ulang, semakin banyak yang dapat Anda terima. Jumlah total penukaran selama acara adalah {receiveLimit} kali;',
    BET: 'Jumlah penukaran per hari didasarkan pada taruhan yang dipasang pada hari itu. Semakin banyak taruhan yang dipasang, semakin banyak penukaran yang dapat Anda terima. Jumlah total penukaran selama acara adalah {receiveLimit} kali;',
  },
  "hi-IN": {
    FIXED_COUNT: 'प्रति दिन मोचन समय की संख्या {dayLimit} बार तय की गई है, और घटना के दौरान मोचन समय की कुल संख्या {receiveLimit} बार है;',
    RECHARGE: 'प्रतिदिन रिडेम्प्शन की संख्या उस दिन के रिचार्ज पर आधारित होती है। आप जितना अधिक रिचार्ज करेंगे, उतना ही अधिक प्राप्त कर सकेंगे। इवेंट के दौरान रिडेम्प्शन की कुल संख्या {receiveLimit} बार है।;',
    BET: 'प्रतिदिन रिडेम्प्शन की संख्या उस दिन लगाए गए दांवों पर आधारित होती है। जितने ज़्यादा दांव लगाए जाएँगे, उतने ज़्यादा रिडेम्प्शन आपको मिल सकते हैं। इवेंट के दौरान रिडेम्प्शन की कुल संख्या {receiveLimit} गुना है।;',
  },
};

// 签到Volume 
const SignInVolumeTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `在规定时间内，投注金额成功满足活动条件，您将获得高达{highestReward}的固定奖金;\n`,
    2: `本次活动为7天连续签到活动;\n`,
    3: `只要签到成功，就计入连续签到天数;\n`,
    4: `奖励仅限在{platforms}端自动领取，过期未领取奖励作废;\n`,
  },
  "en-US": {
    1: `If the betting amount successfully meets the activity conditions within the specified time, you will receive a fixed bonus of up to {highestReward};\n`,
    2: `This event is a 7-day continuous sign-in event;\n`,
    3: `As long as the sign-in is successful, it will be counted as consecutive sign-in days;\n`,
    4: `Rewards can only be manually claimed on {platforms}. Rewards that are not claimed before expiration will be forfeited;\n`,
  },
  "pt-BR": {
    1: `Se o valor da aposta atender às condições da atividade dentro do tempo especificado, você receberá um bônus fixo de até {highestReward};\n`,
    2: `Este evento é um evento de login contínuo de 7 dias;\n`,
    3: `Desde que o login seja bem-sucedido, ele será contado como dias consecutivos de login;\n`,
    4: `As recompensas podem ser reclamadas manualmente apenas nas plataformas {platforms}. As recompensas que não forem reclamadas antes da expiração serão anuladas;\n`,
  },
  "id-ID": {
    1: `Jika jumlah taruhan berhasil memenuhi ketentuan aktivitas dalam waktu yang ditentukan, Anda akan menerima bonus tetap hingga {highestReward};\n`,
    2: `Acara ini merupakan acara pendaftaran berkelanjutan selama 7 hari;\n`,
    3: `Selama proses masuk berhasil, maka akan dihitung sebagai hari masuk berturut-turut;\n`,
    4: `Hadiah hanya dapat diklaim secara manual di platform {platforms}. Hadiah yang tidak diklaim sebelum masa berlaku akan hangus;\n`,
  },
  "hi-IN": {
    1: `यदि सट्टेबाजी की राशि निर्दिष्ट समय के भीतर गतिविधि की शर्तों को सफलतापूर्वक पूरा करती है, तो आपको {highestReward} तक का एक निश्चित बोनस प्राप्त होगा।;\n`,
    2: `यह इवेंट 7-दिन तक चलने वाला निरंतर साइन-इन इवेंट है।;\n`,
    3: `जब तक साइन-इन सफल रहेगा, इसे लगातार साइन-इन दिनों के रूप में गिना जाएगा।;\n`,
    4: `इनाम केवल {platforms} प्लेटफॉर्म पर मैन्युअल रूप से प्राप्त किए जा सकते हैं। जो इनाम समय सीमा से पहले प्राप्त नहीं किए जाते, वे अमान्य हो जाएंगे।;\n`,
  },
};

//幸运注单 第三条 活动领取上限次数为0 
const LuckyBetTemplates_Three_receiveLimit: ActivityRulesMap_Rebate = {
  "zh-CN": {
    FIXED_COUNT: '每日领取次数固定{dayLimit}次；',
    RECHARGE: '每日领取次数根据当日充值获得，充值越多领取次数越多；',
    BET: '每日领取次数根据当日投注获得，投注越多领取次数越多；',
  },
  "en-US": {
    FIXED_COUNT: 'The number of daily collections is fixed at {dayLimit} times;',
    RECHARGE: 'The number of daily collections is obtained based on the recharge of the day. The more recharges, the more collections;',
    BET: 'The number of daily collections is obtained based on the bets of the day. The more bets, the more collections;',
  },
  "pt-BR": {
    FIXED_COUNT: 'O número de vezes de resgate por dia é fixado em {dayLimit} vezes;',
    RECHARGE: 'O número de resgates por dia é baseado na recarga naquele dia. Quanto mais você recarrega;',
    BET: 'O número de resgates por dia é baseado nas apostas feitas naquele dia. Quanto mais apostas feitas;',
  },
  "id-ID": {
    FIXED_COUNT: 'Jumlah kali penukaran tiap harinya ditetapkan sebanyak {dayLimit} kali;',
    RECHARGE: 'Jumlah penukaran per hari didasarkan pada isi ulang pada hari itu. Semakin banyak Anda mengisi ulang;',
    BET: 'Jumlah penukaran per hari didasarkan pada taruhan yang dipasang pada hari itu. Semakin banyak taruhan yang dipasang;',
  },
  "hi-IN": {
    FIXED_COUNT: 'प्रति दिन मोचन समय की संख्या {dayLimit} बार तय की गई है;',
    RECHARGE: 'प्रतिदिन रिडेम्प्शन की संख्या उस दिन के रिचार्ज पर आधारित होती है। आप जितना अधिक रिचार्ज करेंगे;',
    BET: 'प्रतिदिन रिडेम्प्शन की संख्या उस दिन लगाए गए दांवों पर आधारित होती है। जितने ज़्यादा दांव लगाए जाएँगे;',
  },
};

//全部平台
function generateFirstAgencyRule(language: Language, variables: ActivityVariables, ruleNumber: number): string {
  const agencyTemplate = agencyTemplates_first[language];
  if (!agencyTemplate) {
    return "Unsupported language";
  }

  const condition = variables?.validBetStatus || "OFF";
  const firstTemplate = agencyTemplate[condition];
  if (!firstTemplate) {
    return "Unsupported condition";
  }

  // 替换模板中的变量
  let finalTemplate = firstTemplate.replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);
  if (condition === "ON") {
    if (variables?.limitAgencyType === 'ALL') {
      const platformTranslation = {
        "zh-CN": "全部平台",
        "en-US": "All Platforms",
        "pt-BR": "Todas as Plataformas",
        "id-ID": "Semua Platform",
        "hi-IN": "सभी प्लेटफॉर्म",
      };
      finalTemplate = finalTemplate.replace("{limitData}", platformTranslation[language]);
    } else {
      finalTemplate = finalTemplate.replace("{limitData}", `${variables?.limitAgencyData || ""}`);
    }
  }

  const ruleNumberValue = `${ruleNumber}. `;
  return ruleNumberValue + finalTemplate;
}

// 公用
const allActivityRules: { [activityType: string]: ActivityRulesMap } = {
  RedPacket: redPacketTemplates,
  Agency: agencyTemplates,
  AssistanceCash: assistanceCashTemplates,
  SignIn: signInTemplates,
  CommissionReward: commissionRewardTemplates,
  CPFActivity: cpfInviteTemplates,
  WalletUserActivity: cpfInviteTemplates,
  SignInVolume: SignInVolumeTemplates,
};

// 生成默认规则
export function generateDefultRules(language: Language, activityType: string, variables: ActivityVariables) {
  let result = "";
  language = getLanguageCode(language);
  //活动内容
  const activityDetails = `${generateEventDetails(language)}\n`;

  if (activityType === "RedPacket") {
    if (variables?.joinType && variables?.joinType === 'RECHARGE') {
      result += activityDetails + generateRedPacketRules(language as Language, variables);
    } else {
      result += activityDetails + generateComRules(language as Language, activityType, variables);
    }
    return result;
  }
  //带条件的活动
  if (activityType === "AssistanceCash") {
    result += activityDetails + generateComRules(language as Language, activityType, variables);
  } else if (activityType === "Recharge") {
    result += activityDetails + generateRechargeRules(language as Language, variables);
  } else if (activityType === "Rebate") {
    result += activityDetails + generateRebateRules(language as Language, variables);
  } else if (activityType === "Agency") {
    result += activityDetails + generateFirstAgencyRule(language as Language, variables, 1) + "\n";
    result += generateComRules(language as Language, activityType, variables);
  } else if (activityType === "LuckyWheel") {
    result += activityDetails + generateLuckyWheelRules(language as Language, variables);
  } else if (activityType === "Assistance") {
    result += activityDetails + generateAssistanceRules(language as Language, variables);
  } else if (activityType === "AssistanceFollow") {
    result += generateAssistanceRules(language as Language, variables);
  } else if (activityType === "MemberReward") {
    result += activityDetails + generateMemberRewardRules(language as Language, variables);
  } else if (activityType === "MemberRewardFollow") {
    result += generateMemberRewardFollowRules(language as Language, variables);
  } else if (activityType === "MysteryReward") {
    result += activityDetails + generateMysteryRewardRules(language as Language, variables);
  } else if (activityType === 'SignIn') {
    result += activityDetails
    result += generateComRules(language as Language, activityType, variables, 3);
    result = result.replace(new RegExp(`{signInTemplates_other}`, 'g'), signInTemplates_other[language][variables.signInType]);
  } else if (activityType === "CommissionReward") {
    result += activityDetails + generateComRules(language as Language, activityType, variables);
    const firstRule = commissonSpecialFirst[language][`${variables.resetType}_${variables.rewardType}`];
    const secondRule = commissonSpecialSecond[language][`${variables.resetType}_${variables.rewardType}_${variables.awardType}`];
    result = result.replace(new RegExp(`{commissonSpecialFirst}`, 'g'), firstRule);
    result = result.replace(new RegExp(`{commissonSpecialSecond}`, 'g'), secondRule);
  } else if (activityType === 'CPFActivity') {
    result += activityDetails + generateComRules(language as Language, activityType, variables, 2);
    try {
      let childRule = '';
      if (variables && variables.cpfRule) {
        const cpfRuleInfo = JSON.parse(variables.cpfRule);
        const ruleTitle = ['a.', 'b.', 'c.', 'd.'];
        let index = 0;
        if (cpfRuleInfo && cpfRuleInfo.length > 0) {
          const activeRules = cpfRuleInfo
            .filter((item: any) => item && item.status === 'ON')
            .map((item: any) => {
              let ruleText = ruleTitle[index] + cpfInviteChildTemplate[language][item.rewardType + '_' + item.amountType];
              ruleText = ruleText.replace(new RegExp(`{target}`, 'g'), item.target);

              if (item.amountType === 'FIXED_RATIO') {
                const ratioAmount = item.maxAmount + "%";
                ruleText = ruleText.replace(new RegExp(`{maxAmount}`, 'g'), ratioAmount);
              } else if (item.amountType === 'RANDOM') {
                const randomAmount = formatAmount(Number(item.minAmount), language) + '~' + formatAmount(Number(item.maxAmount), language);
                ruleText = ruleText.replace(new RegExp(`{maxAmount}`, 'g'), randomAmount);
              } else {
                ruleText = ruleText.replace(new RegExp(`{maxAmount}`, 'g'), item.maxAmount);
              }
              index++;
              return ruleText;
            });

          childRule = activeRules.join('\n');
        }
      }
      if (childRule !== '') {
        childRule = '\n' + childRule;
      }
      result = result.replace(new RegExp(`{childRule}`, 'g'), childRule);
    } catch (error) {
      result = result.replace(new RegExp(`{childRule}`, 'g'), '');
      console.log(error)
    }
  } else if (activityType === "NewbieTask") {
    result += activityDetails + generateDefultNewTaskRules(language as Language, variables);
  } else if (activityType === "LuckyBet") {
    result += activityDetails + generateLuckyBetRules(language as Language, variables);
  } else if (activityType === "SignInVolume") {
    result += activityDetails + generateComRules(language as Language, activityType, variables, 3);
  } else if (activityType === "MemberRewardMultiDay") {
    result += activityDetails + generateMemberRewardRules(language as Language, variables);
  } else if (activityType === "RechargePromotion") {
    result += activityDetails + generateRechargePromotionRules(language as Language, variables);
  }


  return result
}


// 生成默认新人任务规则
export function generateDefultNewTaskRules(language: Language, variables: ActivityVariables) {
  let result = "";
  language = getLanguageCode(language);

  // 合并第一和第二条规则
  const firstSecondTemplate = NewbieTaskFirstSecondTemplates[language];
  result = Object.entries(firstSecondTemplate).map(([key, value]) => {
    return `${key}. ${value.replace("{applyAppType}", variables.applyAppType.toString())}`;
  }).join("\n");

  const comTemplate = comTemplates_four[language];
  if (!comTemplate) {
    return "Unsupported language";
  }

  const condition = variables.limitType;
  const fourthTemplate = comTemplate[condition];
  if (!fourthTemplate) {
    return "Unsupported condition";
  }

  // 替换模板中的变量
  let thirdTemplate = fourthTemplate.replace("{multiplier}", `${variables.multiplier}`);
  if (condition === "ON") {
    // 如果条件是ON，还需要替换{limitData}
    // ON: `本活动所赠送的奖金（不含本金）需{multiplier}倍有效投注才能提现，{投注仅限limitData};`,
    thirdTemplate = thirdTemplate.replace("{limitData}", `${variables.limitData}`);
  }
  // result += finalTemplate
  result += `\n${3}. ` + thirdTemplate;
  // 添加规则4和5
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    result += `\n4. ${additionalRules[5]}\n`;
    result += `5. ${additionalRules[6]}`;
  }
  return result;
}

//  生成通用规则
export function generateComRules(language: Language, activityType: string, variables: ActivityVariables, isMore: number = 1): string {
  // 根据活动类型选择正确的规则集
  const activityRules = allActivityRules[activityType];
  if (!activityRules) {
    return "Unsupported activity type";
  }

  const templateEntries = activityRules[language];
  if (!templateEntries) {
    return "Unsupported language";
  }

  let result = "";
  Object.entries(templateEntries).forEach(([key, value], index, array) => {
    let template = value;
    Object.keys(variables).forEach(variableKey => {
      const regex = new RegExp(`{${variableKey}}`, 'g');
      const value = variableKey === 'highestReward' ? formatAmount(Number(variables[variableKey]), language) : variables[variableKey];
      template = template.replace(regex, value?.toString());
    });
    result += `${key}. ${template}`;
    if (index < array.length - 1) {
      result += "\n";
    }
  });

  // 添加规则5和6
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    if (isMore == 1) {
      result += generateFourthRule(language, variables, 4);
      result += `\n5. ${additionalRules[5]}\n`;
      result += `6. ${additionalRules[6]}`;
    } else if (isMore == 2) {
      result += generateFourthRule(language, variables, 3);
      result += `\n4. ${additionalRules[5]}\n`;
      result += `5. ${additionalRules[6]}`;
    } else if (isMore == 3) {
      result += generateFourthRule(language, variables, 5);
      result += `\n6. ${additionalRules[5]}\n`;
      result += `7. ${additionalRules[6]}`;
    }
  }

  return result.trim();
}

//会员答谢活动规则 
export function generateMemberRewardRules(language: Language, variables: ActivityVariables): string {
  let result = "";

  // 第一条规则
  const firstTemplate = memberRewardTemplates_firstSecond[language][1];
  if (firstTemplate) {
    result += `1. ${firstTemplate}\n`;
  }

  // 第二条规则
  const secondTemplate = memberRewardTemplates_firstSecond[language][2];
  if (secondTemplate) {
    result += `2. ${secondTemplate}\n`;
  }

  // 第三条规则 - 使用 memberRewardTemplates_Three
  const thirdTemplate = memberRewardTemplates_Three[language][variables.expiredAwardType];
  if (thirdTemplate) {
    result += `3. ${thirdTemplate}\n`;
  }

  // 添加第四条规则（使用公共模板）
  result += generateFourthRule(language, variables, 4);

  // 添加第五和第六条规则（使用公共模板）
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    result += `\n5. ${additionalRules[5]}\n`;
    result += `6. ${additionalRules[6]}`;
  }
  return result.trim();
}

// 充值大酬宾规则
export function generateRechargePromotionRules(language: Language, variables: ActivityVariables) {
  let result = "";
  language = getLanguageCode(language);

  // 合并规则
  const rechargePromotionTemplate = RechargePromotionTemplates[language];
  result = Object.entries(rechargePromotionTemplate).map(([key, value]) => {
    return `${key}. ${value.replace("{activityDay}", RechargePromotionActivityDayTemplates[language][variables.activityDay])}`;
  }).join("\n");
  return result;
}

// 神秘彩金活动
export function generateMysteryRewardRules(language: Language, variables: ActivityVariables): string {
  let result = "";

  // 第一条规则
  const firstTemplate = mysteryRewardTemplates_first[language][variables.joinType];
  if (firstTemplate) {
    result += `1. ${firstTemplate}\n`;
  }

  // 第二条规则
  const secondTemplate = mysteryRewardTemplates_Two[language];
  if (secondTemplate && secondTemplate[2]) {
    result += `2. ${secondTemplate[2]}\n`;
  }

  // 第三条规则 - 修改为使用 
  const thirdTemplate = mysteryRewardTemplates_second[language][variables.joinType];
  if (thirdTemplate) {
    const resetTypeTemplate = thirdTemplate[variables.resetType];
    if (resetTypeTemplate) {
      const expiredTypeTemplate = resetTypeTemplate[variables.expiredAwardType];
      if (expiredTypeTemplate) {
        result += `3. ${expiredTypeTemplate.replace("{awardKeepDays}", variables.awardKeepDays.toString())}\n`;
      }
    }
  }

  // 添加第四条规则（使用公共模板）
  result += generateFourthRule(language, variables, 4);

  // 添加第五和第六条规则（使用公共模板）
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    result += `\n5. ${additionalRules[5]}\n`;
    result += `6. ${additionalRules[6]}`;
  }

  return result.trim();
}

//会员答谢活动跟随页面规则 
export function generateMemberRewardFollowRules(language: Language, variables: ActivityVariables): string {
  let result = "";

  // 第一条规则
  const firstTemplate = memberRewardTemplates_firstSecond[language][1];
  if (firstTemplate) {
    result += ` ${firstTemplate}\n`;
  }

  // 第二条规则
  const secondTemplate = memberRewardTemplates_firstSecond[language][2];
  if (secondTemplate) {
    result += ` ${secondTemplate}\n`;
  }

  // 第三条规则 - 使用 memberRewardTemplates_Three
  const thirdTemplate = memberRewardTemplates_Three[language][variables.expiredAwardType];
  if (thirdTemplate) {
    result += ` ${thirdTemplate}\n`;
  }

  // 添加第四条规则（使用公共模板）
  result += generateFourthRule(language, variables, 4, 'MemberRewardFollow');

  // 添加第五和第六条规则（使用公共模板）
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    result += `\n ${additionalRules[5]}\n`;
    result += ` ${additionalRules[6]}`;
  }
  return result.trim();
}
// 充值活动
export function generateRechargeRules(language: Language, variables: ActivityVariables): string {
  // 首先，根据语言和充值类型选择正确的模板
  const template = rechargeTemplates_First[language][variables.rechargeType][variables.resetType];
  if (!template) {
    return "Unsupported recharge type or reset type";
  }

  // 替换模板中的变量
  let result = "1. " + template.replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);

  // 充值活动的2条数据
  const otherTemplates = rechargeTemplates_Two[language];
  if (!otherTemplates) {
    return "Unsupported language";
  }
  // 直接添加充值活动的2条数据
  Object.entries(otherTemplates).forEach(([key, value]) => {
    result += `\n${key}. ${value}`;
  });

  // 充值活动的3条数据
  const thirdTemplate = rechargeTemplates_Three[language][variables.awardType];
  if (!thirdTemplate) {
    return "Unsupported recharge type";
  }
  result += `\n3. ${thirdTemplate}`;

  result += generateGiftRule(language, variables, 4);

  // 添加规则5和6
  const rulesFiveSix = addFiveSixRules[language];
  if (rulesFiveSix) {
    result += `\n5. ${rulesFiveSix[5]}\n6. ${rulesFiveSix[6]}`;
  }

  return result;
}

// 红包雨新规则 第一条
export function generateRedPacketRules(language: Language, variables: ActivityVariables): string {
  // 首先，根据语言和充值类型选择正确的模板
  const template = redPacketTemplatesFirst[language][variables.joinType];
  if (!template) {
    return "Unsupported RedPacket joinType ";
  }

  // 替换模板中的变量
  let result = "1. " + template
    .replace("{times}", `${variables.times}`)
    .replace("{duration}", `${variables.duration}`)
    .replace("{rewardCount}", `${variables.rewardCount}`);

  // 红包雨 2 3 
  const otherTemplates = redPacketTemplatessScond[language];
  if (!otherTemplates) {
    return "Unsupported language";
  }
  Object.entries(otherTemplates).forEach(([key, value]) => {
    result += `\n${key}. ${value}`;
  });

  result += generateFourthRule(language, variables, 4);

  // 添加规则5和6
  const rulesFiveSix = addFiveSixRules[language];
  if (rulesFiveSix) {
    result += `\n5. ${rulesFiveSix[5]}\n6. ${rulesFiveSix[6]}`;
  }

  return result;
}


function generateFourthRule(language: Language, variables: ActivityVariables, ruleNumber: number, type?: String): string {
  const comTemplate = comTemplates_four[language];
  if (!comTemplate) {
    return "Unsupported language";
  }

  const condition = variables.limitType;
  const fourthTemplate = comTemplate[condition];
  if (!fourthTemplate) {
    return "Unsupported condition";
  }

  // 替换模板中的变量
  let finalTemplate = fourthTemplate.replace("{multiplier}", `${variables.multiplier}`);
  if (condition === "ON") {
    // 如果条件是ON，还需要替换{limitData}
    // ON: `本活动所赠送的奖金（不含本金）需{multiplier}倍有效投注才能提现，{投注仅限limitData};`,
    finalTemplate = finalTemplate.replace("{limitData}", `${variables.limitData}`);
  }

  const ruleNumberValue = `\n${ruleNumber}. `;
  if (type == 'MemberRewardFollow') {
    const ruleNumberFollowValue = `\n `;
    return ruleNumberFollowValue + finalTemplate;
  }
  return ruleNumberValue + finalTemplate;
}


function generateGiftRule(language: Language, variables: ActivityVariables, ruleNumber: number): string {
  const comTemplateGift = comTemplates_four_Gift[language];
  if (!comTemplateGift) {
    return "Unsupported language";
  }

  const giftTemplate = comTemplateGift[variables.limitType];
  if (!giftTemplate) {
    return "Unsupported condition";
  }

  // 根据奖金类型选择模板
  const auditType = variables.rewardAuditType; // 假设 variables 中包含奖金类型（Gift 或 GiftAndRecharge）
  const selectedTemplate = giftTemplate[auditType];
  if (!selectedTemplate) {
    return "Unsupported bonus type";
  }

  // 替换模板中的变量
  let finalTemplate = selectedTemplate.replace("{multiplier}", `${variables.multiplier}`);
  if (variables.limitType === "ON") {
    // 如果条件是ON，还需要替换{limitData}
    finalTemplate = finalTemplate.replace("{limitData}", `${variables.limitData}`);
  } else {
    // 如果条件是OFF，替换文本以适应“不限游戏平台”的情况
    // 注意：这里假设OFF条件下不需要特别替换，因为模板已经是通用的描述
  }

  const ruleNumberValue = `\n${ruleNumber}. `;
  return ruleNumberValue + finalTemplate;
}
// 返水活动
export function generateRebateRules(language: Language, variables: ActivityVariables): string {
  // 选择返水活动的第一条数据模板
  const firstTemplate = rebateTemplates_First[language];
  if (!firstTemplate) {
    return "Unsupported language";
  }

  // 根据充值类型（RECHARGE 或 NORECHARGE）选择正确的模板
  const rebateTypeTemplate = firstTemplate[variables.rebateType as keyof typeof firstTemplate];
  if (!rebateTypeTemplate) {
    return "Unsupported recharge type";
  }

  // 替换模板中的变量
  let result = "1. " + rebateTypeTemplate
    .replace("{lowestRebateBet}", `${formatAmount(Number(variables.lowestRebateBet), language)}`)
    .replace("{rechargeLimit}", `${formatAmount(Number(variables.rechargeLimit), language)}`)
    .replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);

  // 对于返水活动的2, 3条数据，我们需要额外处理
  const otherTemplates = rebateTemplates_Other[language];
  if (!otherTemplates) {
    return "Unsupported language";
  }

  // 添加返水活动的2, 3条数据
  Object.entries(otherTemplates).forEach(([key, value]) => {
    result += `\n${key}. ${value}`;
  });

  result += generateFourthRule(language, variables, 4);

  // 添加规则5和6
  const rulesFiveSix = addFiveSixRules[language];
  if (rulesFiveSix) {
    result += `\n5. ${rulesFiveSix[5]}\n6. ${rulesFiveSix[6]}`;
  }

  return result.trim();
}

// 幸运转盘
export function generateLuckyWheelRules(language: Language, variables: ActivityVariables): string {
  // 合并第一和第二条规则
  const firstSecondTemplate = luckyWheelTemplates_firstSecond[language];
  let result = Object.entries(firstSecondTemplate).map(([key, value]) => {
    return `${key}. ${value.replace("{exchangeReward}", formatAmount(Number(variables.exchangeReward), language))}`;
  }).join("\n");

  // 添加第三条规则，根据重置类型选择
  const thirdTemplate = luckyWheelTemplates_third[language][variables.resetType];
  result += `\n3. ${thirdTemplate}`;

  // 添加第四条规则
  result += generateFourthRule(language, variables, 4);

  // 添加公共的第5和第6条规则
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    result += `\n5. ${additionalRules[5]}\n6. ${additionalRules[6]}`;
  }

  return result;
}

// 救援金 第三条规则
function getRewardDistributionMessage(language: Language, frequency: ResetType, type: AwardType, delay: AwardTimeType, time?: string): string {
  // 检查frequency是否为 中的有效键
  if (!['DAILY', 'WEEKLY'].includes(frequency)) {
    throw new Error(`Unsupported frequency: ${frequency}`);
  }

  const messageTemplate = rewardDistributionMapWithDelay[language][frequency][type][delay];

  // 如果是延迟到账，替换模板中的{time}占位符
  if (delay === 'DELAY' && time) {
    return messageTemplate.replace('{time}', time);
  }

  return messageTemplate;
}
// 救援金规则生成，包括第六条使用幸运转盘的规则，以及第七、八条使用公共规则
export function generateAssistanceRules(language: Language, variables: ActivityVariables): string {

  let result = ''
  if (variables.includeOrExclude === "CUMULATIVE_LOSS") {
    const specialTemplate = assistanceTemplates_firstSpecial[language];
    if (!specialTemplate) {
      return "Unsupported language";
    }

    const specialRewardTemplate = specialTemplate.CUMULATIVE_LOSS;
    // 替换模板中的变量
    result = "1. " + specialRewardTemplate
      .replace("{lowestLoss}", `${formatAmount(Number(variables.lowestLoss), language)}`)
      .replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);
  } else {
    // 救援金第一条数据
    const firstTemplate = assistanceTemplates_first[language];
    if (!firstTemplate) {
      return "Unsupported language";
    }

    // 根据 resetType 和 rewardType 选择模板
    const resetTypeTemplate = firstTemplate[variables.resetType as keyof typeof firstTemplate];
    if (!resetTypeTemplate) {
      return "Unsupported reset type";
    }

    const rewardTypeTemplate = resetTypeTemplate[variables.rewardType as keyof typeof resetTypeTemplate];
    if (!rewardTypeTemplate) {
      return "Unsupported reward type";
    }

    // 替换模板中的变量
    result = "1. " + rewardTypeTemplate
      .replace("{lowestLoss}", `${formatAmount(Number(variables.lowestLoss), language)}`)
      .replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);
  }

  // 救援金第二条数据   固定 / 比例  excludeGift 有优惠 / normal 无优惠
  const secondTemplate = assistanceTemplates_second[language][variables.rewardType][variables.includeOrExclude];
  if (secondTemplate) {
    result += `\n2. ${secondTemplate}`;
  }

  const message = getRewardDistributionMessage(language, variables.resetType as ResetType, variables.awardType as AwardType, variables.awardTimeType as AwardTimeType, variables.time as string);
  result += `\n3. ${message}`;

  // 救援金第四条数据 abandoned 过期作废 / auto 过期自动派发
  const fifthTemplate = assistanceTemplates_four[language][variables.resetType as ResetType][variables.expiredAwardType as ExpiredAwardType];
  if (fifthTemplate) {
    result += `\n4. ${fifthTemplate}`;
  }

  result += generateFourthRule(language, variables, 5);

  // 第七、八条数据，使用公共规则模板 c
  const commonRulesTemplate = addFiveSixRules[language];
  if (commonRulesTemplate) {
    result += `\n6. ${commonRulesTemplate[5]}`;
    result += `\n7. ${commonRulesTemplate[6]}`;
  }

  return result.trim();
}

// 生成幸运注单默认规则
export function generateLuckyBetRules(language: Language, variables: ActivityVariables) {

  const firstTemplate = LuckyBetTemplates_first[language];
  if (!firstTemplate) {
    return "Unsupported language";
  }

  // 根据 resetType 和 rewardType 选择模板
  const winTypeTemplate = firstTemplate[variables.winType as keyof typeof firstTemplate];
  if (!winTypeTemplate) {
    return "Unsupported reset type";
  }

  let result = "1. " + winTypeTemplate.replace("{validBetAmount}", `${formatAmount(Number(variables.validBetAmount), language)}`) + "\n"; 

  // 第二条规则
   const secondTemplate = LuckyBetTemplates_second[language][variables.limitLuckyBetType];
   if (secondTemplate) {
      if (variables.limitLuckyBetType === "SELECT") {
        result += `2. ${secondTemplate.replace("{limitLuckyBetData}", variables.limitLuckyBetData.toString())}\n`;
      } else {
        result += `2. ${secondTemplate}\n`;
    }
   }
  // 第三条规则
  const thirdTemplate =
    Number(variables.receiveLimit) === 0
      ? LuckyBetTemplates_Three_receiveLimit[language]
      : LuckyBetTemplates_Three[language];

  if (thirdTemplate) {
    if (variables.receiveCountType === "FIXED_COUNT") {
      result += `3. ${thirdTemplate.FIXED_COUNT.replace("{dayLimit}", variables.dayLimit.toString())}`;
      if (Number(variables.receiveLimit) !== 0) {
        result = result.replace("{receiveLimit}", formatAmount(Number(variables.receiveLimit), language));
      }
      result += "\n";
    } else {
      result += `3. ${thirdTemplate[variables.receiveCountType]}`;
      if (Number(variables.receiveLimit) !== 0) {
        result = result.replace("{receiveLimit}", formatAmount(Number(variables.receiveLimit), language));
      }
      result += "\n";
    }
  }

  // 添加第四条规则（使用公共模板）
  result += generateFourthRule(language, variables, 4);

  // 添加第五和第六条规则（使用公共模板）
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    result += `\n5. ${additionalRules[5]}\n`;
    result += `6. ${additionalRules[6]}`;
  }
  return result.trim();
}

// 活动内容
export function generateEventDetails(language: Language): string {
  const eventDetails = {
    "zh-CN": "活动内容: ",
    "en-US": "Event Details: ",
    "pt-BR": "Detalhes do Evento: ",
    "id-ID": "Detail Acara: ",
    "hi-IN": "गतिविधि सामग्री: ",
  };
  return eventDetails[language];
}


const previewText_First: ActivityRulesMap = {
  "zh-CN": {
    OTHER: "最高奖励{maximum}",
    REBATE: "最高返水比例{maximum}%",
    OTHER_RANGE: "最高奖励{maximum}%",
  },
  "en-US": {
    OTHER: "Maximum bonus {maximum}",
    REBATE: "Maximum rebate rate {maximum}%",
    OTHER_RANGE: "Maximum bonus {maximum}%",
  },
  "pt-BR": {
    OTHER: "Bônus máximo {maximum}",
    REBATE: "Taxa máxima de cashback {maximum}%",
    OTHER_RANGE: "Bônus máximo {maximum}%",
  },
  "id-ID": {
    OTHER: "Hadiah Tertinggi {maximum}",
    REBATE: "Persentase Pengembalian Uang Tertinggi {maximum}%",
    OTHER_RANGE: "Hadiah Tertinggi {maximum}%",
  },
  "hi-IN": {
    OTHER: "अधिकतम बोनस {maximum}",
    REBATE: "अधिकतम रिबेट दर {maximum}%",
  },
};

const previewText_Second: ActivityRulesMap = {
  "zh-CN": {
    NONE: "不重置",
    DAILY: "每日重置",
    WEEKLY: "每周重置",
    WEEKLY_DAY: "每周重置",
    MONTHLY_DAY: "每月重置",
    PERIODIC: "跟随活动",
  },
  "en-US": {
    NONE: "No reset",
    DAILY: "Reset daily",
    WEEKLY: "Reset weekly",
    WEEKLY_DAY: "Reset weekly",
    MONTHLY_DAY: "Reset monthly",
    PERIODIC: "Following activity",
  },
  "pt-BR": {
    NONE: "Sem reinício",
    DAILY: "Reinício diário",
    WEEKLY: "Reinício semanal",
    WEEKLY_DAY: "Reinício semanal",
    MONTHLY_DAY: "Reinício mensal",
    PERIODIC: "Atividade seguinte",
  },
  "id-ID": {
    NONE: "Tidak Mereset",
    DAILY: "Reset Harian",
    WEEKLY: "Reset Mingguan",
    WEEKLY_DAY: "Reset Mingguan",
    MONTHLY_DAY: "Reset Bulanan",
    PERIODIC: "Kegiatan lanjutan",
  },
  "hi-IN": {
    NONE: "रीसेट नहीं",
    DAILY: "दैनिक रीसेट",
    WEEKLY: "साप्ताहिक रीसेट",
    WEEKLY_DAY: "साप्ताहिक रीसेट",
    MONTHLY_DAY: "हर महीने रीसेट",
    PERIODIC: "अनुवर्ती गतिविधि",
  },
};

const previewText_Second_mysteryReward: ActivityRulesMap = {
  "zh-CN": {
    NONE: "一次性",
    PERIODIC: "循环",
  },
  "en-US": {
    NONE: "Disposable",
    PERIODIC: "Cycle",
  },
  "pt-BR": {
    NONE: "Descartável",
    PERIODIC: "Ciclo",
  },
  "id-ID": {
    NONE: "Disposable",
    PERIODIC: "Siklus",
  },
  "hi-IN": {
    NONE: "डिस्पोजेबल",
    PERIODIC: "चक्र",
  },
};

const previewText_Third: ActivityRulesMap = {
  "zh-CN": {
    ABANDONED: "过期作废",
    AUTO: "过期自动发放",
    RESERVE: "跨天，跨周，跨月时，未领取奖励邮件下发",
    ABOLISHMENT: "跨天，跨周，跨月时，未领取奖励系统回收作废",
    RETAIN_DAY_AUTO: "奖励保留天数自动发放",
    RETAIN_DAY_ABANDONED: "奖励保留天数作废",
  },
  "en-US": {
    ABANDONED: "Void if not claimed",
    AUTO: "Auto-payout upon expiration",
    RESERVE: "Unclaimed rewards will be sent via email when crossing day, week, or month boundaries",
    ABOLISHMENT: "Unclaimed rewards will be voided by the system when crossing day, week, or month boundaries",
    RETAIN_DAY_AUTO: "Auto-payout after reward retention period",
    RETAIN_DAY_ABANDONED: "Void after reward retention period",
  },
  "pt-BR": {
    ABANDONED: "Nulo se não reclamado",
    AUTO: "Pagamento automático ao expirar",
    RESERVE: "Recompensas não reclamadas serão enviadas por e-mail ao cruzar limites de dia, semana ou mês",
    ABOLISHMENT: "Recompensas não reclamadas serão anuladas pelo sistema ao cruzar limites de dia, semana ou mês",
    RETAIN_DAY_AUTO: "Pagamento automático após o período de retenção da recompensa",
    RETAIN_DAY_ABANDONED: "Nulo após o período de retenção da recompensa",
  },
  "id-ID": {
    ABANDONED: "Kadaluarsa dan Dinyatakan Tidak Berlaku",
    AUTO: "Otomatis Dikeluarkan saat Kadaluarsa",
    RESERVE: "Hadiah yang belum diklaim akan dikirim melalui email saat melewati batas hari, minggu, atau bulan",
    ABOLISHMENT: "Hadiah yang belum diklaim akan dibatalkan oleh sistem saat melewati batas hari, minggu, atau bulan",
    RETAIN_DAY_AUTO: "Pembayaran otomatis setelah masa penyimpanan hadiah",
    RETAIN_DAY_ABANDONED: "Tidak berlaku setelah masa penyimpanan hadiah",
  },
  "hi-IN": {
    ABANDONED: "यदि दावा नहीं किया गया तो अमान्य",
    AUTO: "समाप्ति पर स्वत: भुगतान",
    RESERVE: "दिन, सप्ताह, या महीने की सीमाएं पार करने पर दावा न किए गए पुरस्कार ईमेल के माध्यम से भेजे जाएंगे",
    ABOLISHMENT: "दिन, सप्ताह, या महीने की सीमाएं पार करने पर दावा न किए गए पुरस्कार सिस्टम द्वारा रद्द कर दिए जाएंगे",
    RETAIN_DAY_AUTO: "पुरस्कार प्रतिधारण अवधि के बाद स्वचालित भुगतान",
    RETAIN_DAY_ABANDONED: "पुरस्कार प्रतिधारण अवधि के बाद अमान्य",
  },
};

// 定义充值活动名字
export function getRechargeActivityName(language: Language, resetType: ResetType, rechargeType: RechargeType): string {
  const names = {
    "zh-CN": {
      NONE: {
        FIRST: "首充额外奖励",
        SINGLE: "单笔充值加赠",
        SUM: "累计充值加赠",
      },
      DAILY: {
        FIRST: "每日首充加赠",
        SINGLE: "每日单笔充值加赠",
        SUM: "每日累计充值加赠",
      },
      WEEKLY: {
        FIRST: "每周首充加赠",
        SINGLE: "每周单笔充值加赠",
        SUM: "每周累计充值加赠",
      },
    },
    "en-US": {
      NONE: {
        FIRST: "First Deposit Extra Reward",
        SINGLE: "Additional bonus for every recharge",
        SUM: "Cumulative recharge additional reward",
      },
      DAILY: {
        FIRST: "Daily First Deposit Extra Reward",
        SINGLE: "Daily Additional bonus for every recharge",
        SUM: "Daily Cumulative recharge additional reward",
      },
      WEEKLY: {
        FIRST: "Weekly First Deposit Extra Reward",
        SINGLE: "Weekly Additional bonus for every recharge",
        SUM: "Weekly Cumulative recharge additional reward",
      },
    },
    "pt-BR": {
      NONE: {
        FIRST: "Recompensa Extra para Primeiro Depósito",
        SINGLE: "Bônus adicional para cada recarga",
        SUM: "Recompensa adicional de recarga acumulativa",
      },
      DAILY: {
        FIRST: "Recompensa Extra de Primeiro Depósito Diário",
        SINGLE: "Bônus adicional diário para cada recarga",
        SUM: "Recompensa adicional diária por recarga acumulada",
      },
      WEEKLY: {
        FIRST: "Recompensa Extra de Primeiro Depósito Semanal",
        SINGLE: "Bônus adicional semanal para cada recarga",
        SUM: "Bônus Semanal de Recarga Cumulativa",
      },
    },
    "id-ID": {
      NONE: {
        FIRST: "Bonus tambahan untuk isi ulang pertama",
        SINGLE: "Bonus tambahan untuk setiap isi ulang",
        SUM: "Bonus tambahan untuk akumulasi isi ulang",
      },
      DAILY: {
        FIRST: "Bonus tambahan untuk isi ulang pertama setiap hari",
        SINGLE: "Bonus tambahan untuk setiap isi ulang tunggal harian",
        SUM: "Bonus tambahan untuk akumulasi isi ulang harian",
      },
      WEEKLY: {
        FIRST: "Bonus tambahan untuk isi ulang pertama setiap minggu",
        SINGLE: "Bonus tambahan untuk setiap isi ulang tunggal mingguan",
        SUM: "Bonus tambahan untuk akumulasi isi ulang mingguan",
      },
    },
    "hi-IN": {
      NONE: {
        FIRST: "पहली जमा पर अतिरिक्त इनाम",
        SINGLE: "प्रत्येक रिचार्ज पर अतिरिक्त बोनस",
        SUM: "संचयी रिचार्ज अतिरिक्त इनाम",
      },
      DAILY: {
        FIRST: "दैनिक पहले जमा पर अतिरिक्त इनाम",
        SINGLE: "प्रत्येक रिचार्ज पर दैनिक अतिरिक्त बोनस",
        SUM: "दैनिक संचयी रिचार्ज अतिरिक्त इनाम",
      },
      WEEKLY: {
        FIRST: "साप्ताहिक पहले जमा पर अतिरिक्त इनाम",
        SINGLE: "प्रत्येक रिचार्ज पर साप्ताहिक अतिरिक्त बोनस",
        SUM: "साप्ताहिक संचयी रिचार्ज अतिरिक्त इनाम",
      },
    },
  };

  return names[language][resetType][rechargeType];
}

// 获取救援金活动名字
function getRescueFundActivityName(language: Language, resetType: ResetType): string {
  const names = {
    "zh-CN": {
      NONE: "",
      DAILY: "每日救援金",
      WEEKLY: "每周救援金",
    },
    "en-US": {
      NONE: "",
      DAILY: "Daily loss rescue fund",
      WEEKLY: "Weekly loss rescue fund",
    },
    "pt-BR": {
      NONE: "",
      DAILY: "Fundo de Resgate de Perdas Diárias",
      WEEKLY: "Fundo de Resgate de Perdas Semanais",
    },
    "id-ID": {
      NONE: "",
      DAILY: "Uang bantuan harian",
      WEEKLY: "Uang bantuan mingguan",
    },
    "hi-IN": {
      NONE: "",
      DAILY: "दैनिक राहत कोष",
      WEEKLY: "साप्ताहिक राहत कोष",
    },
  };

  return names[language][resetType];
}

// 幸运转盘活动名字
function getLuckyWheelActivityName(language: Language, resetType: ResetType): string {
  const names = {
    "zh-CN": {
      NONE: "幸运转盘",
      DAILY: "每日幸运转盘",
      WEEKLY: "每周幸运转盘",
    },
    "en-US": {
      NONE: "Lucky Spin",
      DAILY: "Daily Lucky Spin",
      WEEKLY: "Weekly Lucky Spin",
    },
    "pt-BR": {
      NONE: "Giro da Sorte",
      DAILY: "Giro da Sorte Diário",
      WEEKLY: "Giro da Sorte Semanal",
    },
    "id-ID": {
      NONE: "Roda Keberuntungan",
      DAILY: "Roda Keberuntungan Harian",
      WEEKLY: "Roda Keberuntungan Mingguan",
    },
    "hi-IN": {
      NONE: "लकी रूले",
      DAILY: "दैनिक लकी रूले",
      WEEKLY: "साप्ताहिक लकी रूले",
    },
  };

  return names[language][resetType];
}

// 获取佣金奖励活动名字
function getCommissionRewardActivityName(language: Language, resetType: ResetType): string {
  const names = {
    "zh-CN": {
      NONE: "",
      DAILY: "每日佣金奖励",
      WEEKLY_DAY: "每周佣金奖励",
      MONTHLY_DAY: "每月佣金奖励",
    },
    "en-US": {
      NONE: "",
      DAILY: "Daily commission reward",
      WEEKLY_DAY: "Weekly commission reward",
      MONTHLY_DAY: "Monthly commission reward",
    },
    "pt-BR": {
      NONE: "",
      DAILY: "Recompensa de Comissão Diária",
      WEEKLY_DAY: "Recompensa de Comissão Semanal",
      MONTHLY_DAY: "Recompensa de Comissão Mensal",
    },
    "id-ID": {
      NONE: "",
      DAILY: "Hadiah Acara Komisi Harian",
      WEEKLY_DAY: "Hadiah Acara Komisi Mingguan",
      MONTHLY_DAY: "Hadiah Acara Komisi Bulanan",
    },
    "hi-IN": {
      NONE: "",
      DAILY: "दैनिक कमीशन इवेंट पुरस्कार",
      WEEKLY_DAY: "साप्ताहिक कमीशन इवेंट पुरस्कार",
      MONTHLY_DAY: "महीने का कमीशन इवेंट पुरस्कार",
    },
  };

  return names[language][resetType];
}

// 生成活动名字
export function getActivityDefaultName(language: Language, activityType: AcviticyType, variables: ActivityVariables): string {
  language = getLanguageCode(language);
  if (activityType === 'Recharge') {
    return getRechargeActivityName(language, variables.resetType as ResetType, variables.rechargeType as RechargeType)
  } else if (activityType === 'Assistance') {
    return getRescueFundActivityName(language, variables.resetType as ResetType)
  } else if (activityType === 'LuckyWheel') {
    return getLuckyWheelActivityName(language, variables.resetType as ResetType)
  } else if (activityType === 'CommissionReward') {
    return getCommissionRewardActivityName(language, variables.resetType as ResetType)
  }

  const activityNames = {
    Recharge: {},
    Assistance: {},
    LuckyWheel: {},
    SignIn: {
      "zh-CN": "签到",
      "en-US": "Sign in",
      "pt-BR": "Entrar",
      "id-ID": "Pendaftaran",
      "hi-IN": "साइन इन",
    },
    Custom: {
      "zh-CN": "自定义",
      "en-US": "Custom event",
      "pt-BR": "Evento personalizado",
      "id-ID": "Disesuaikan",
      "hi-IN": "कस्टम इवेंट",
    },
    RechargeReward: {
      "zh-CN": "充值赠送",
      "en-US": "Recharge discount",
      "pt-BR": "Desconto de recarga",
      "id-ID": "Bonus Isi Ulang",
      "hi-IN": "रिचार्ज छूट",
    },
    RedPacket: {
      "zh-CN": "神秘矿场",
      "en-US": "Mysterious Mine",
      "pt-BR": "Mina Misteriosa",
      "id-ID": "Tambang Misterius",
      "hi-IN": "रहस्यमय खदान",
    },
    Agency: {
      "zh-CN": "推荐好友领彩金",
      "en-US": "Refer friends to receive a bonus",
      "pt-BR": "Indique amigos para receber um bônus",
      "id-ID": "Rekomendasikan teman untuk menerima bonus",
      "hi-IN": "दोस्तों को रेफर करें और बोनस प्राप्त करें।",
    },
    Rebate: {
      "zh-CN": "返水活动",
      "en-US": "Wagering Cashback",
      "pt-BR": "Cashback de Apostas",
      "id-ID": "Program Pengembalian Uang",
      "hi-IN": "शर्त लगाने पर कैशबैक",
    },
    AssistanceCash: {
      "zh-CN": "助力领现金",
      "en-US": "Earn cash by inviting friends",
      "pt-BR": "Ganhe dinheiro convidando amigos",
      "id-ID": "Bantu untuk Dapatkan Uang Tunai",
      "hi-IN": "दोस्तों को आमंत्रित करके नकद कमाएं",
    },
    MemberReward: {
      "zh-CN": "会员答谢",
      "en-US": "Member Appreciation",
      "pt-BR": "Apreciação aos Membros",
      "id-ID": "Apresiasi Anggota",
      "hi-IN": "सदस्य प्रशंसा",
    },
    MysteryReward: {
      "zh-CN": "神秘彩金活动",
      "en-US": "Mystery Bonus Event",
      "pt-BR": "Evento de Bônus Misterioso",
      "id-ID": "Acara Bonus Misteri",
      "hi-IN": "रहस्यमय बोनस इवेंट",
    },
    newbieTaskReward: {
      "zh-CN": "新人福利",
      "en-US": "Newbie benefits",
      "pt-BR": "Benefícios para iniciantes",
      "id-ID": "Manfaat bagi pemula",
      "hi-IN": "नौसिखिया लाभ",
    },
    CommissionReward: {
      "zh-CN": "每日佣金奖励",
      "en-US": "Daily Commission Event Reward",
      "pt-BR": "Recompensa do Evento de Comissão Diária",
      "id-ID": "Hadiah Acara Komisi Harian",
      "hi-IN": "दैनिक कमीशन इवेंट पुरस्कार",
    },
    CPFActivity: {
      "zh-CN": "邀请好友",
      "en-US": "Invite Friends",
      "pt-BR": "Convidar Amigos",
      "id-ID": "Undang Teman",
      "hi-IN": "मित्रों को आमंत्रित करें",
    },
    googleDomainReward: {
      "zh-CN": "版本升级奖金",
      "en-US": "Version Upgrade Bonus",
      "pt-BR": "Bônus de atualização de versão",
      "id-ID": "Bonus peningkatan versi",
      "hi-IN": "संस्करण उन्नयन बोनस",
    },
    LuckyBet: {
      "zh-CN": "幸运注单",
      "en-US": "Lucky Bet",
      "pt-BR": "Aposta da Sorte",
      "id-ID": "Taruhan Beruntung",
      "hi-IN": "भाग्यशाली बेट",
    },
    SignInVolume: {
      "zh-CN": "签到奖励",
      "en-US": "Sign in reward",
      "pt-BR": "Recompensa de entrada",
      "id-ID": "Hadiah masuk",
      "hi-IN": "साइन इन पुरस्कार",
    },
    MemberRewardMultiDay: {
      "zh-CN": "会员答谢(多日)",
      "en-US": "Member Appreciation (Multiple Days)",
      "pt-BR": "Apreciação aos Membros (Vários Dias)",
      "id-ID": "Apresiasi Anggota (Multi Hari)",
      "hi-IN": "सदस्य प्रशंसा (कई दिन)",
    },
    RechargePromotion: {
      "zh-CN": "充值大酬宾",
      "en-US": "Deposit Rewards",
      "pt-BR": "Recompensas de depósito",
      "id-ID": "Hadiah Deposit",
      "hi-IN": "जमा पुरस्कार",
    },
    WalletUserActivity: {
      "zh-CN": "邀请好友",
      "en-US": "Invite Friends",
      "pt-BR": "Convidar Amigos",
      "id-ID": "Undang Teman",
      "hi-IN": "मित्रों को आमंत्रित करें",
    },
  };

  return activityNames[activityType][language] || "Unknown Activity";
}

// 获取当前活动名字
export function getCurrentActivityName(multilingual: Record<string, string>, language: Language, activityType: AcviticyType,) {
  if (multilingual.nameType === "CUSTOM") return multilingual.name
  const params = JSON.parse(multilingual?.nameParams)
  return getActivityDefaultName(language, activityType, params.variablesValue)
}

// 根据当前语言格式化金额
export function formatAmount(amount: number, language: Language): string {
  language = getLanguageCode(language);
  return new Intl.NumberFormat(language, { style: 'decimal' }).format(amount);
}

// 生成预览文本
export function generatePreviewText(language: Language, variables: ActivityVariables): string {
  language = getLanguageCode(language);
  // 根据语言和类型选择正确的模板
  const firstTemplate = previewText_First[language][variables.highestType];
  if (!firstTemplate) {
    return "Unsupported type";
  }

  // 替换模板中的变量
  let result = `1. ${firstTemplate.replace("{maximum}", formatAmount(Number(variables.highestReward), language))}`;

  // 设置默认的 activityType 为 Recharge
  const activityType = variables?.activityType || 'Recharge';

  // 添加第二条数据
  let secondTemplate;
  if (activityType === 'MysteryReward') {
    secondTemplate = previewText_Second_mysteryReward[language][variables.resetType];
  } else if (activityType === 'SignInVolume') {
    secondTemplate = previewText_Second_mysteryReward[language]['PERIODIC'];
  } else {
    secondTemplate = previewText_Second[language][variables.resetType];
  }
  if (secondTemplate) {
    result += `\n2. ${secondTemplate}`;
  }

  // 添加第三条数据
  const thirdTemplate = previewText_Third[language][variables.expiredAwardType];
  if (thirdTemplate) {
    result += `\n3. ${thirdTemplate}`;
  }

  return result;
}

export function getLanguageCode(language: Language): Language {
  if (language === 'en-PH') {
    return 'en-US';
  }
  return language; // 返回原语言
}

export function formatMoneyToShow(money: number | string, language: Language, fixed = 2): string {
  // 做个参数兼容处理，如果是字符串就直接返回
  if (typeof money === 'string') {
    return money
  }
  const locale = language
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  }).format(money);
}
